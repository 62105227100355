import React from 'react'

export default function News_Icon() {
   return (
      <>
         <svg xmlns="http://www.w3.org/2000/svg" width="47" height="41" viewBox="0 0 47 41" fill="none">
            <path d="M42.5938 0H13.2188C10.7861 0 8.8125 1.97038 8.8125 4.39286V35.1429C8.8125 36.7591 7.4943 38.0714 5.875 38.0714C4.2557 38.0714 2.9375 36.7591 2.9375 35.1429V7.32143C2.9375 6.51607 2.28023 5.85714 1.46875 5.85714C0.657266 5.85714 0 6.51607 0 7.32143V35.1429C0 38.3716 2.63457 41 5.875 41H39.6562C43.7054 41 47 37.7163 47 33.6786V4.39286C47 1.97038 45.0264 0 42.5938 0ZM44.0625 33.6786C44.0625 36.101 42.0861 38.0714 39.6562 38.0714H10.9605C11.4654 37.2112 11.75 36.2136 11.75 35.1429V4.39286C11.75 3.58658 12.4109 2.92857 13.2188 2.92857H42.5938C43.4016 2.92857 44.0625 3.58658 44.0625 4.39286V33.6786ZM24.9688 24.8929H16.1562C15.3484 24.8929 14.6875 25.5518 14.6875 26.3571C14.6875 27.1625 15.3444 27.8214 16.1562 27.8214H24.9688C25.7806 27.8214 26.4375 27.1665 26.4375 26.3571C26.4375 25.5478 25.7766 24.8929 24.9688 24.8929ZM39.6562 24.8929H30.8438C30.0359 24.8929 29.375 25.5518 29.375 26.3571C29.375 27.1625 30.0319 27.8214 30.8438 27.8214H39.6562C40.4681 27.8214 41.125 27.1665 41.125 26.3571C41.125 25.5478 40.4641 24.8929 39.6562 24.8929ZM24.9688 30.75H16.1562C15.3484 30.75 14.6875 31.4089 14.6875 32.2143C14.6875 33.0196 15.3444 33.6786 16.1562 33.6786H24.9688C25.7806 33.6786 26.4375 33.0237 26.4375 32.2143C26.4375 31.4049 25.7766 30.75 24.9688 30.75ZM39.6562 30.75H30.8438C30.0319 30.75 29.375 31.4049 29.375 32.2143C29.375 33.0237 30.0319 33.6786 30.8438 33.6786H39.6562C40.4681 33.6786 41.125 33.0237 41.125 32.2143C41.125 31.4049 40.4641 30.75 39.6562 30.75ZM38.1875 5.85714H17.625C16.0002 5.85714 14.6875 7.16585 14.6875 8.78571V17.5714C14.6875 19.1885 16.0029 20.5 17.625 20.5H38.1875C39.8096 20.5 41.125 19.1885 41.125 17.5714V8.78571C41.125 7.16585 39.8123 5.85714 38.1875 5.85714ZM38.1875 17.5714H17.625V8.78571H38.1875V17.5714Z" fill="#342354" />
         </svg>
      </>
   )
}
