import React from 'react'
import styles from "./Homepage.module.scss"
import { Box, Button, Container, Grid, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import ArrowRight from '../../components/icons/ArrowRight';
import Envelope_Icon from '../../components/icons/Envelope_Icon';
import Phone_Icon from '../../components/icons/Phone_Icon';
import Menu_Bars_Icon from '../../components/icons/Menu_Bars_Icon';
import News_Icon from '../../components/icons/News_Icon';
import Chat_Message_Icon from '../../components/icons/Chat_Message_Icon';



function CustomTabPanel(props) {
   const { children, value, index, ...other } = props;

   return (
      <Box
         role="tabpanel"
         hidden={value !== index}
         id={`simple-tabpanel-${index}`}
         aria-labelledby={`simple-tab-${index}`}
         {...other}
      >
         {value === index && (
            <Box>
               {children}
            </Box>
         )}
      </Box>
   );
}

CustomTabPanel.propTypes = {
   children: PropTypes.node,
   index: PropTypes.number.isRequired,
   value: PropTypes.number.isRequired,
};

function a11yProps(index) {
   return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
   };
}


export default function HomePage() {

   const [value, setValue] = React.useState(0);

   const handleChange = (event, newValue) => {
      setValue(newValue);
   };

   const tabData = [
      {
         icon: <Envelope_Icon />,
         title: 'Email Preview Tool (Gmail)',
         description: 'Guarantee your emails look great in a Gmail inbox with our real-time Gmail preview tool.',
         link: '/email-preview-gmail',
         linkLabel: 'Check it out',
      },
      {
         icon: <Envelope_Icon />,
         title: 'Email Preview Tool (Outlook)',
         description: 'Perfect your email presentation with our real-time Outlook inbox preview tool.',
         link: '/email-preview-outlook',
         linkLabel: 'Check it out',
      },
      {
         icon: <Phone_Icon />,
         title: 'SMS Preview Tool',
         description: 'Preview your SMS marketing messages and optimize them for clarity and impact.',
         link: '/sms-preview',
         linkLabel: 'Check it out',
      },
      {
         icon: <Menu_Bars_Icon />,
         title: 'Search Ad Preview Tool',
         description: 'Make sure your search ads outshine the competition with our real-time search ad preview tool',
         link: '/search-ad-preview',
         linkLabel: 'Check it out',
      },
      {
         icon: <Chat_Message_Icon />,
         title: 'Social Ad & Post Preview Tool',
         description: 'Elevate the look of your social ads and posts with our real-time social preview tool',
         link: '/social-ad-preview',
         linkLabel: 'Check it out',
      },
   ];

   const coming_Soon_Tools = [
      /* {
         icon: <Chat_Message_Icon />,
         title: 'Social Ad (General)',
         description: 'Ensure your emails look perfect in every inbox with our real-time preview tool.',
         link: '/#',
         linkLabel: 'Check it out',
      }, */
      {
         icon: <News_Icon />,
         title: 'Blog Article Preview Tool',
         description: 'Get a sneak peek of your blog content with our real-time article preview tool.',
         link: '/#',
         linkLabel: 'Check it out',
      },
   ];

   return (
      <Box className="newHomePageMain">
         <Box component="section" className={`${styles.home_page_hero_section}`}>
            <Container>
               <Grid container className={`${styles.inner_row}`}>
                  <Grid item>
                     <Typography fontSize={"72px"} className={`${styles.home_hero_title} fontGruffy`} color={"var(--white)"} fontWeight={"900"} variant='h1'>Why use digital <Typography fontSize={"72px"} className={`${styles.home_hero_title} fontGruffy`} color={"var(--white)"} fontWeight={"900"} variant='h1'>preview tools?</Typography><Typography className={`textCoral`} variant='paragraph2'>Because they help!</Typography></Typography>
                     <Typography mt={"20px"} fontSize={"18px"} variant='body1' lineHeight={1.56} color={"var(--white)"} className={`${styles.home_hero_info}`} maxWidth={"810px"}>
                        See your content just like your audience will across all major platforms — no accounts, no downloads — just effective marketing. {/* <Link className={`${styles.null}`} to="javascrip:void();">Get notified when we launch.</Link> */}
                     </Typography>
                  </Grid>
               </Grid>
            </Container>
         </Box>
         <Box className={`${styles.home_tab_section} home_tab_section`} component="section">
            <Box className={`${styles.tab_title}`}>
               <Container className={`${styles.container_Mid_Width}`}>
                  <Box className={`${styles.inner_tab_title}`}>
                     <Tabs className={`${styles.tab_list}`} value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab className={`${styles.single_tab_item} `} disableRipple label="Active Tools" {...a11yProps(0)} />
                        <Tab className={`${styles.single_tab_item} `} disableRipple label="Coming Soon" {...a11yProps(1)} />
                     </Tabs>
                  </Box>
               </Container>
            </Box>
            <Box className={`${styles.tab_content}`}>
               <Container className={`${styles.container_Mid_Width}`}>
                  <Box className={`${styles.inner_tab_content}`}>
                     <CustomTabPanel value={value} index={0}>
                        {tabData.map((item, index) => (
                           <Box key={index} className={`${styles.tab_Content_Item}`}>
                              <Grid container className={`${styles.tab_Row}`}>
                                 <Grid item className={`${styles.icon_Wrapper}`}>
                                    {item.icon}
                                 </Grid>
                                 <Grid item className={`${styles.content_Wrapper}`}>
                                    <Grid container className={`${styles.content_Wrapper_Row}`}>
                                       <Grid item lg={9} className={`${styles.content_Left}`}>
                                          <Typography variant='h5' className={`fontGruffy`}>{item.title}</Typography>
                                          <Typography className={`${styles.toolDescription}`} variant='body1'>{item.description}</Typography>
                                       </Grid>
                                       <Grid item lg={3} className={`${styles.content_Right}`} style={{textAlign: 'right'}}>
                                          <Link disableRipple to={item.link} className={`fontJBM`}>{item.linkLabel} <ArrowRight /> </Link>
                                       </Grid>
                                    </Grid>
                                 </Grid>
                              </Grid>
                           </Box>
                        ))}
                     </CustomTabPanel>
                     <CustomTabPanel value={value} index={1}>
                        {coming_Soon_Tools.map((item, index) => (
                           <Box key={index} className={`${styles.tab_Content_Item}`}>
                              <Grid container className={`${styles.tab_Row}`}>
                                 <Grid item className={`${styles.icon_Wrapper}`}>
                                    {item.icon}
                                 </Grid>
                                 <Grid item className={`${styles.content_Wrapper}`}>
                                    <Grid container className={`${styles.content_Wrapper_Row}`}>
                                       <Grid item className={`${styles.content_Left}`}>
                                          <Typography variant='h5' className={`fontGruffy`}>{item.title}</Typography>
                                          <Typography variant='body1'>{item.description}</Typography>
                                       </Grid>
                                       <Grid item className={`${styles.content_Right}`}>
                                          <Link disableRipple to={item.link} className={`fontJBM`}>{item.linkLabel} <ArrowRight /> </Link>
                                       </Grid>
                                    </Grid>
                                 </Grid>
                              </Grid>
                           </Box>
                        ))}
                     </CustomTabPanel>
                  </Box>
               </Container>
            </Box>
         </Box>
      </Box>
   )
}
