import React, { useEffect, useState } from "react";
import { Box, FormControl, Typography, TextField, TextareaAutosize, Select, MenuItem } from "@mui/material";
import styles from "../pages/searchAdPreview/SearchAdPreview.module.scss";

export default function SocialAdPreviewTable(props) {

    const [errorMessageHeading, setErrorMessageHeading] = useState("");
    const [errorMessageDescription, setErrorMessageDescription] = useState("");
    const [lengthAdHeading, setLengthAdHeading] = useState(0);
    const [lengthAdDescription, setLengthAdDescription] = useState(0);
    const [minRows, setMinRows] = useState(window.innerWidth < 992 ? 3 : 2);
    const [socialType, setSocialType] = useState({
        socialAd: 'social-ad',
        mediaType: '1x1 image',
        businessName: "",
        postCopy: "",
        adHeading: "",
        adDescription: "",
        webDisplayUrl: "",
        buttonAction: 'Apply Now',
    });
    const [socialTypeTwo, setSocialTypeTwo] = useState({
        socialAd: 'social-ad',
        mediaType: '1x1 image',
        businessName: "",
        postCopy: "",
    })

    useEffect(() => {
        setLengthAdHeading(socialType.adHeading.length);
        if (socialType.adHeading.length >= 30) {
            setErrorMessageHeading('The character limit exceeds 30');
            return;
        }
        else {
            setErrorMessageHeading('');
        }
    }, [socialType.adHeading])

    useEffect(() => {
        setLengthAdDescription(socialType.adDescription.length)

        if (socialType.adDescription.length >= 50) {
            setErrorMessageDescription('The character limit exceeds 50');
            return;
        }
        else {
            setErrorMessageDescription('');
        }
    }, [socialType.adDescription])

    useEffect(() => {
        const handleResize = () => {
            setMinRows(window.innerWidth < 1300 ? 3 : 2);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleSocialTypeChange = (event) => {
        const { name, value } = event.target;
        setSocialType((prevState) => ({
            ...prevState,
            socialAd: value,
        }));
        props.onSocialTypeChange(event.target.value);
    }

    const handleMediaTypeChange = (event) => {
        const { name, value } = event.target;
        setSocialType((prevState) => ({
            ...prevState,
            mediaType: value,
        }));
        props.onMediaTypeChange(event.target.value);
    }

    const handleBusinessNameChange = (event) => {
        const { name, value } = event.target;
        setSocialType((prevState) => ({
            ...prevState,
            businessName: value,
        }));
        props.onBusinessNameChange(event.target.value);
    };

    const handlePostCopyChange = (event) => {
        const { name, value } = event.target;
        setSocialType((prevState) => ({
            ...prevState,
            postCopy: value,
        }));
        props.onPostCopyChange(event.target.value)
    };

    const handleAdHeadingChange = (event) => {
        const { name, value } = event.target;
        setSocialType((prevState) => ({
            ...prevState,
            adHeading: value,
        }));
        props.onAdHeadingChange(event.target.value)
    };

    const handleAdDescriptionChange = (event) => {
        const { name, value } = event.target;
        setSocialType((prevState) => ({
            ...prevState,
            adDescription: value,
        }));
        props.onAdDescriptionChange(event.target.value);
    };

    const handleWebDisplayChange = (event) => {
        const { name, value } = event.target;
        setSocialType((prevState) => ({
            ...prevState,
            webDisplayUrl: value,
        }));
        props.onWebDisplayChange(event.target.value)
    };

    const handleButtonActionChange = (event) => {
        const { name, value } = event.target;
        setSocialType((prevState) => ({
            ...prevState,
            buttonAction: value,
        }));
        props.onButtonActionChange(event.target.value)
    }

    const handleMediaTypeChangeTwo = (event) => {
        const { name, value } = event.target;
        setSocialTypeTwo((prevState) => ({
            ...prevState,
            mediaType: value,
        }));
        props.onMediaTypeChangeTwo(event.target.value)
    }

    const handleBusinessNameChangeTwo = (event) => {
        const { name, value } = event.target;
        setSocialTypeTwo((prevState) => ({
            ...prevState,
            businessName: value,
        }));
        props.onBusinessNameChangeTwo(event.target.value)
    }

    const handlePostCopyChangeTwo = (event) => {
        const { name, value } = event.target;
        setSocialTypeTwo((prevState) => ({
            ...prevState,
            postCopy: value,
        }));
        props.onPostCopyChangeTwo(event.target.value)
    }

    const lengthColorChangeHeading = () => (
        lengthAdHeading >= 30 && '#990000'
    )

    const lengthColorChangeDescription = () => (
        lengthAdHeading >= 50 && '#990000'
    )

    return (
        <FormControl className={`${styles.messageBoxes}`} fullWidth>
            <form>
                {socialType.socialAd === 'social-ad' && (
                    <>
                        <Box className={`${styles.newTextSMS_wrapper}`}>
                            <Typography
                                variant="subtitle1"
                                className={`${styles.newTextSMS} ${styles.textSMS}`}
                                fontWeight={700}
                                lineHeight={3}
                                mb={1}
                            >
                                Social Ad Type
                            </Typography>
                        </Box>
                        <Box className={`${styles.newInputFields} ${styles.inputFields}`}>
                            <label htmlFor="socialAd">Type of Social Ad</label>
                            <Select
                                labelId="socialAd"
                                id="socialAd"
                                name="social-ad"
                                value={socialType.socialAd}
                                onChange={handleSocialTypeChange}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            marginTop: 10,
                                        },
                                    },
                                }}
                            >
                                <MenuItem value="social-ad">Social Ad</MenuItem>
                                <MenuItem value="social-post">Social Post</MenuItem>
                            </Select>
                            <label htmlFor="socialAd">Can work well for Facebook, Instagram, LinkedIn and Twitter</label>
                        </Box>
                        <Box className={`${styles.newTextSMS_wrapper}`}>
                            <Typography
                                variant="subtitle1"
                                className={`${styles.newTextSMS}`}
                                fontWeight={700}
                                lineHeight={3}
                                mb={1}
                            >
                                Media
                            </Typography>

                        </Box>
                        <Box className={`${styles.newInputFields} ${styles.inputFields}`}>
                            <label for="mediaType">Media Type</label>
                            <Select
                                labelId="mediaType"
                                id="mediaType"
                                name="mediaType"
                                value={socialType.mediaType}
                                onChange={handleMediaTypeChange}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            marginTop: 10,
                                        },
                                    },
                                }}
                            >
                                <MenuItem value="1x1 image">1x1 image</MenuItem>
                                <MenuItem value="4x3 image">4x3 image</MenuItem>
                                <MenuItem value="video">Video</MenuItem>
                            </Select>
                        </Box>
                        <Box className={`${styles.newTextSMS_wrapper}`}>
                            <Typography
                                variant="subtitle1"
                                className={`${styles.newTextSMS}`}
                                fontWeight={700}
                                lineHeight={3}
                                mb={1}
                            >
                                Post details
                            </Typography>

                        </Box>

                        <Box className={styles.newInputFields}>
                            <label for="businessName">Business Name</label>
                            <TextField
                                id="businessName"
                                label=""
                                value={socialType.businessName}
                                onChange={handleBusinessNameChange}
                                name="businessName"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true
                                }} inputProps={{
                                    placeholder: 'Your business name',
                                }} />
                        </Box>
                        <Box className={styles.inputFields}>
                            <label for="postCopy">Post copy</label>
                            <TextareaAutosize
                                id="postCopy"
                                value={socialType.postCopy}
                                onChange={handlePostCopyChange}
                                name="postCopy"
                                aria-label="empty textarea"
                                minRows={minRows}
                                placeholder="Add your post copy"
                            />
                        </Box>
                        <Box className={styles.inputFields}>
                            <label for="adHeadline">Ad Headline</label>
                            <TextField
                                id="adHeadline"
                                label=""
                                value={socialType.adHeading}
                                name="adHeadline"
                                onChange={handleAdHeadingChange}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true
                                }} inputProps={{
                                    placeholder: 'Add a headline',
                                    maxLength: 30,
                                }} />
                            <span className={styles.numberFieldText} style={{ color: lengthColorChangeHeading() }}>{lengthAdHeading}/30</span>
                            {errorMessageHeading && <span className={styles.errorMessageHeading} style={{ color: lengthColorChangeHeading() }}>{errorMessageHeading}</span>}
                        </Box>
                        <Box className={styles.inputFields}>
                            <label for="adDescription">Ad Description</label>
                            <TextareaAutosize
                                id="adDescription"
                                value={socialType.adDescription}
                                onChange={handleAdDescriptionChange}
                                name="adDescription"
                                aria-label="empty textarea"
                                minRows={minRows}
                                placeholder="Add description here"
                                maxLength={50}
                            />
                            <span className={styles.numberFieldText} style={{ color: lengthColorChangeDescription() }}>{lengthAdDescription}/50</span>
                            {errorMessageDescription && <span className={styles.errorMessageDescription} style={{ color: lengthColorChangeDescription() }}>{errorMessageDescription}</span>}
                        </Box>
                        <Box className={styles.inputFields}>
                            <label for="webDisplayUrl">Web Display Url</label>
                            <TextField
                                id="webDisplayUrl"
                                label=""
                                variant="outlined"
                                value={socialType.webDisplayUrl}
                                name="webDisplayUrl"
                                onChange={handleWebDisplayChange}
                                InputLabelProps={{
                                    shrink: true
                                }} inputProps={{
                                    placeholder: 'website.com',
                                }} />
                        </Box>
                        <Box className={`${styles.newInputFields} ${styles.inputFields}`}>
                            <label for="buttonAction">Button Call to Action</label>
                            <Select
                                labelId="buttonAction"
                                id="buttonAction"
                                name="buttonAction"
                                value={socialType.buttonAction}
                                onChange={handleButtonActionChange}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            marginTop: 10,
                                        },
                                    },
                                }}
                            >
                                <MenuItem value="Apply Now">Apply Now</MenuItem>
                                <MenuItem value="Book Now">Book Now</MenuItem>
                                <MenuItem value="Buy Tickets">Buy Tickets</MenuItem>
                                <MenuItem value="Contact Us">Contact Us</MenuItem>
                                <MenuItem value="Donate Now">Donate Now</MenuItem>
                                <MenuItem value="Get Offer">Get Offer</MenuItem>
                                <MenuItem value="Get Quote">Get Quote</MenuItem>
                                <MenuItem value="Learn More">Learn More</MenuItem>
                                <MenuItem value="Order Now">Order Now</MenuItem>
                                <MenuItem value="Play Game">Play Game</MenuItem>
                                <MenuItem value="Shop Now">Shop Now</MenuItem>
                                <MenuItem value="Sign Up">Sign Up</MenuItem>
                                <MenuItem value="Subscribe">Subscribe</MenuItem>
                            </Select>
                        </Box>
                    </>
                )}{socialType.socialAd === 'social-post' && (
                    <>
                        <Box className={`${styles.newTextSMS_wrapper}`}>
                            <Typography
                                variant="subtitle1"
                                className={`${styles.newTextSMS} ${styles.textSMS}`}
                                fontWeight={700}
                                lineHeight={3}
                                mb={1}
                            >
                                Social Ad Type
                            </Typography>
                        </Box>
                        <Box className={`${styles.newInputFields} ${styles.inputFields}`}>
                            <label htmlFor="socialpost">Type of Social Ad</label>
                            <Select
                                labelId="socialpost"
                                id="socialpost"
                                name="social-post"
                                value={socialType.socialAd}
                                onChange={handleSocialTypeChange}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            marginTop: 10,
                                        },
                                    },
                                }}
                            >
                                <MenuItem value="social-ad">Social Ad</MenuItem>
                                <MenuItem value="social-post">Social Post</MenuItem>
                            </Select>
                            <label htmlFor="socialAd">Can work well for Facebook, Instagram, LinkedIn and Twitter</label>
                        </Box>
                        <Box className={`${styles.newTextSMS_wrapper} ${styles.textSMS_wrapper}`}>
                            <Typography
                                variant="subtitle1"
                                className={`${styles.newTextSMS} ${styles.textSMS}`}
                                fontWeight={700}
                                lineHeight={3}
                                mb={1}
                            >
                                Media
                            </Typography>

                        </Box>

                        <Box className={`${styles.newInputFields} ${styles.inputFields}`}>
                            <label for="mediaType">Media Type</label>
                            <Select
                                labelId="mediaType"
                                id="mediaType"
                                name="mediaType"
                                value={socialTypeTwo.mediaType}
                                onChange={handleMediaTypeChangeTwo}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            marginTop: 10,
                                        },
                                    },
                                }}
                            >
                                <MenuItem value="1x1 image">1x1 image</MenuItem>
                                <MenuItem value="4x3 image">4x3 image</MenuItem>
                                <MenuItem value="video">Video</MenuItem>
                            </Select>
                        </Box>
                        <Box className={`${styles.newTextSMS_wrapper} ${styles.textSMS_wrapper}`}>
                            <Typography
                                variant="subtitle1"
                                className={`${styles.newTextSMS} ${styles.textSMS}`}
                                fontWeight={700}
                                lineHeight={3}
                                mb={1}
                            >
                                Post details
                            </Typography>

                        </Box>

                        <Box className={styles.inputFields}>
                            <label for="businessName">Business Name</label>
                            <TextField
                                id="businessName"
                                label=""
                                value={socialTypeTwo.businessName}
                                onChange={handleBusinessNameChangeTwo}
                                name="businessName"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true
                                }} inputProps={{
                                    placeholder: 'Your business name',
                                }} />
                        </Box>
                        <Box className={styles.inputFields}>
                            <label for="postCopy">Post copy</label>
                            <TextareaAutosize
                                id="postCopy"
                                value={socialTypeTwo.postCopy}
                                onChange={handlePostCopyChangeTwo}
                                name="postCopy"
                                aria-label="empty textarea"
                                minRows={minRows}
                                placeholder="Add your post copy"
                            />
                        </Box>
                    </>
                )}
            </form>
        </FormControl>
    )
}



