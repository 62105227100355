import React from 'react'

function ArrowRight() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="12" viewBox="0 0 20 12" fill="none">
            <path d="M14.1914 0.176713L19.8164 5.57655C19.9389 5.69279 20 5.84654 20 6.00028C20 6.15403 19.939 6.30732 19.8169 6.42439L14.1919 11.8242C13.9477 12.0586 13.552 12.0586 13.3083 11.8242C13.0645 11.5899 13.0641 11.21 13.3083 10.976L17.866 6.60026L0.625 6.60026C0.28125 6.60026 4.95663e-07 6.33027 5.24512e-07 6.00028C5.53361e-07 5.67029 0.279532 5.4003 0.625001 5.4003L17.866 5.4003L13.3074 1.02419C13.0633 0.789818 13.0633 0.409955 13.3074 0.175963C13.5516 -0.0580297 13.9492 -0.0595289 14.1914 0.176713Z" fill="#F07561" />
        </svg>
    )
}

export default ArrowRight