import React, { useState } from "react";
import { Box, Button, Container, FormControl, Grid, Link, Typography, TextareaAutosize, capitalize } from "@mui/material";
import styles from "./SmsPreview.module.scss";
import ArrowLeft from "../../components/icons/ArrowLeft";
import VideoCam from "./../../components/icons/VideoCam";
import Alert from "../../components/icons/Alert"
import HeroSectionInner from "../../components/Hero Section/HeroSectionInner";

function SmsPreview() {
    const [text, setText] = useState('');
    const [optText, setOptText] = useState('');
    const [hasOptText, setHasOptText] = useState(false);

    const handleInputChange = (event) => {
        setText(event.target.value);
    };
    const handleInputOptChange = (event) => {
        setOptText(event.target.value);
        setHasOptText(event.target.value.trim() !== '');
    };

    const smsPreviewContent = {
        title: 'SMS Preview Tool',
        description: (
            <>
                {/* <strong>Why it matters: </strong> */}
                SMS messages are brief, and every character counts. Previewing your SMS ensures your message is clear, concise, and visually appealing on any device, maximizing its impact
            </>
        )
    };
    return (
        <>
            <Box className="mainContent">
                <HeroSectionInner
                    title={smsPreviewContent.title}
                    description={smsPreviewContent.description}
                />
                <Box className={`${styles.firstSection}`}>
                    <Container className={styles.wrapper}>
                        <Grid>
                            <Grid container className={`${styles.workingArea} csMargin`}>
                                <Grid item sm={12}>
                                    <Box className={`${styles.alertBoxWrapper}`}>
                                        <Box className={styles.alertBox} display="flex" alignItems="center" gap={1.25}>
                                            <Alert />
                                            <Typography variant="subtitle2" lineHeight={1.34}>
                                            This is a preview, not a promise. Hopefully, you will get a good feel for how your messages will appear.
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item className={`${styles.leftArea}`} sm={5}>
                                    <Typography variant="subtitle1" className={`${styles.textSMS}`} fontWeight={700} lineHeight={3} mb={1}>
                                        Text SMS Message
                                    </Typography>
                                    <FormControl className={`${styles.messageBoxes}`} fullWidth>
                                        <label for="text-area">Message</label>
                                        <TextareaAutosize id="text-area" name="text-area" aria-label="empty textarea" value={text} onChange={handleInputChange} placeholder="Your message here" minRows={7} />

                                        <label htmlFor="opt-out-text-area">Opt-out message</label>
                                        <TextareaAutosize className={hasOptText ? 'optOutFieldWithText' : 'optOutField'} id="opt-out-text-area" name="opt-out-text-area" aria-label="empty textarea for opt out" value={optText} onChange={handleInputOptChange} placeholder="Your message here" minRows={3} />
                                    </FormControl>
                                </Grid>
                                <Grid item className={`${styles.rightArea}`} sm={7}>
                                    <Box className={styles.innerRight}>
                                        <Typography className={styles.greyText} variant="subtitle1" fontWeight={400} lineHeight={1.28}>Text Message<br />Today 11:69 AM</Typography>
                                        <Typography variant="h6" fontWeight={400} lineHeight={1.44}>
                                            {text ? (
                                                text
                                            ) : (
                                                <>
                                                    ...waiting...
                                                </>
                                            )}
                                            <span className={hasOptText ? 'mt-20' : ''}>
                                                {optText ? (
                                                    optText
                                                ) : (
                                                    <>
                                                    </>
                                                )}
                                            </span>
                                        </Typography>
                                        <Typography className={`${styles.greyText} ${styles.senderText}`} variant="subtitle1" fontWeight={400} lineHeight={1.28}>The sender is not in your contact list</Typography>
                                        <Typography className={`${styles.greyText} ${styles.cutOffText}`} variant="subtitle1" fontWeight={400} lineHeight={1.28}>Estimated screen cutoff</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>
        </>
    );
}

export default SmsPreview;
