import React, {useEffect} from "react";
import styles from "./Header.module.scss"
import { Box, Container, Grid, Typography } from '@mui/material'
import ArrowRight from '../icons/ArrowRight'
import LogoGreen from '../icons/LogoGreen'
import { spacing } from '@mui/system';
import { Link, useLocation } from 'react-router-dom'

function Header() {
    const { pathname } = useLocation();

    useEffect(()=>{
      window.scrollTo(0, 0);
    },[pathname])
  
    return (
        <>
            <Box id="header">
                <Box className={`${styles.topBar} bgDarkPurple`}>
                    <Container className={styles.container}>
                        <Grid container className={styles.gridContianer} justifyContent="space-between" spacing={2} alignItems="flex-start" flexWrap={'nowrap'}>
                            <Grid item className={styles.logoMainWrap}>
                                <Link className={`${styles.logo}`} to="/"><LogoGreen /></Link>
                            </Grid>
                            <Grid className={`${styles.right_Column}`} item display="flex" alignItems={"center"}>
                                <Grid item className={`${styles.text} ${styles.leftArea}`}>
                                    <Link className={`${styles.new_Upper_URL}`}>
                                        <Typography variant='body1'>
                                            Unlock your marketing potential with expert-crafted marketing copy templates.
                                        </Typography><ArrowRight />
                                    </Link>
                                </Grid>
                                {/*  <Grid item className={`${styles.text} ${styles.rightArea}`} marginLeft={"15px"}>
                                    <Link href="javascript:void(0)" className='fontJBM' fontWeight={700}></Link>
                                </Grid> */}
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
                {/*  <Box className={`${styles.navBar}`}>
                    <Container>
                    </Container>
                </Box> */}
            </Box>
        </>
    )
}

export default Header