import React, { useState } from "react";
import { Box, Button, Container, FormControl, Grid, Link, Typography, TextareaAutosize, TextField } from "@mui/material";
import styles from "./Email.module.scss";
import ArrowLeft from "../../components/icons/ArrowLeft";
import Alert from "../../components/icons/Alert"
import VideoCam from "../../components/icons/VideoCam";
import EmailTool from "../../components/table/EmailTool";
import arrowLeftIcon from "../../images/arrowLeft.svg";
import deleteIcon from "../../images/delete.svg";
import dustbinIcon from "../../images/dustbin.svg";
import HeroSectionInner from "../../components/Hero Section/HeroSectionInner";
import EmailPreviewContent from "../../components/Email Preview Content/EmailPreviewContent";
import User_Icon from "../../components/icons/User_Icon";
import client_1 from "../../images/client_1.png"
import client_2 from "../../images/client_2.png"
import arrow_outlook_preview from "../../images/arrow_outlook_preview_new.svg"
import delete_icon from "../../images/delete.svg"
import dotsIcon from "../../images/dots.svg";
import client_3 from '../../components/images/newCompetitor.png'

function EmailPreviewToolOutlook() {
   const [firstNameText, setFirstName] = useState('');
   const [emailText, setEmail] = useState('');
   const [subjectText, setSubject] = useState('');
   const [preheaderText, setPreheader] = useState('');
   // const [emailBodyText, setEmailBody] = useState('');
   const [text, setText] = useState('');
   const [optText, setOptText] = useState('');
   const [hasOptText, setHasOptText] = useState(false);
   const [emailBodyText, setEmailBodyText] = useState('');
   const [lengthSubjectLine, setlengthSubjectLine] = useState(0);
   const [lengthPreheaderChange, setlengthPreheaderChange] = useState(0);
   const [errorMessageSubjectLine, setErrorMessageSubjectLine] = useState('');
   const [errorMessagePreheader, setErrorMessagePreheader] = useState('');

   const handleFirstNameChange = (event) => {
      setFirstName(event.target.value);
   };

   const handleEmailChange = (event) => {
      setEmail(event.target.value);
   };

   const handleSubjectChange = (event) => {
      var lengthSubjectLine = event.target.value.length;
      setlengthSubjectLine(lengthSubjectLine);
      setSubject(event.target.value);

      if (lengthSubjectLine >= 70) {
         setErrorMessageSubjectLine('The character limit exceeds 70.');
         return;
      } else {
         setErrorMessageSubjectLine('');
      }
   };
   const subjectLinelengthColor = () => (
      lengthSubjectLine >= 70 && '#990000'
   )

   const handlePreheaderChange = (event) => {
      var lengthPreheaderChange = event.target.value.length;
      setlengthPreheaderChange(lengthPreheaderChange);
      setPreheader(event.target.value);

      if (lengthPreheaderChange >= 50) {
         setErrorMessagePreheader('The character limit exceeds 50.');
         return;
      } else {
         setErrorMessagePreheader('');
      }

   };
   const preheaderlengthColor = () => (
      lengthPreheaderChange >= 50 && '#990000'
   )

   const handleEmailBodyChange = (event) => {
      setEmailBodyText(event.target.value);
   };

   const smsPreviewContent = {
      title: 'Email preview (for Outlook)',
      description: (
         <>
            {/* <strong>Why it matters: </strong> */}
            Outlook is known for handling HTML emails differently than other clients. Previewing in Outlook helps you catch and correct potential rendering issues, ensuring a consistent experience for your audience.
         </>
      )
   };
   const outlook_inbox = [
      {
         thumbnail: <User_Icon />,
         name: firstNameText || "<add name>",
         subjectLine: subjectText || "<add subject line>",
         previewText: preheaderText || "<add preview text>",
         time: '4:05 PM',
      },
      {
         thumbnail: <User_Icon />,
         name: 'Your Competitor',
         subjectLine: 'Preview next week’s product',
         previewText: 'Imagine a tool that will revolutionize the way you work...',
         time: '11:21 AM',
      },
      {
         thumbnail: client_1,
         name: 'Regular Person',
         subjectLine: 'A new device has logged in to your Blastify...',
         previewText: 'Hi Bill - I just wanted to quickly bring this to your...',
         time: 'Wed 2:36 PM',
      },
      {
         thumbnail: client_3,
         name: 'Competitor',
         subjectLine: 'Get the most out of your widget with upsell',
         previewText: 'Awesome starts now. Sign up and get 75% off all the...',
         time: '7:44 PM',
      },
      {
         thumbnail: <User_Icon />,
         name: 'Best Product',
         subjectLine: 'Expert tips for being better at your job + more!',
         previewText: 'We got the top notch goods that will make you giggle...',
         time: '8:19 AM',
      },
      {
         thumbnail: client_2,
         name: 'Julie from Company',
         subjectLine: "35% off next week's workshop in your city",
         previewText: 'Hurry up! There are only 8 tickets left for this workshop...',
         time: 'Wed 12:57 PM',
      },
   ]


   return (
      <>
         <Box className={` ${styles.emailpreviewWrap} ${styles.outlookpreviewWrap} mainContent `}>
            <HeroSectionInner
               title={smsPreviewContent.title}
               description={smsPreviewContent.description}
            />
            <Box className={`${styles.emailToolWrap}`}>
               <Container className={styles.wrapper}>
                  <Grid>
                     <Grid container className={`${styles.workingArea} csMargin`} spacing={6}>
                        <Grid item className={`${styles.leftArea}`} sm={12}>
                           <Box className={`${styles.alertBoxWrapper}`}>
                              <Box className={styles.alertBox} display="flex" alignItems="center" gap={1.25} mb={4}>
                                 <Alert />
                                 <Typography variant="subtitle2" lineHeight={1.34}>
                                 This is a preview, not a promise. Hopefully, you will get a good feel for how your messages will appear.
                                 </Typography>
                              </Box>
                           </Box>
                           <Typography variant="subtitle1" fontWeight={700} lineHeight={3} mb={1}>
                              Email Content
                           </Typography>
                        </Grid>
                        <Grid item className={`${styles.leftArea}`} sm={5}>
                           <FormControl className={`${styles.messageBoxes}`} fullWidth>
                              <form>
                                 <Box className={styles.inputFields}>
                                    <label for="text-area">From Name</label>
                                    <TextField id="" onChange={handleFirstNameChange} label="" variant="outlined" InputLabelProps={{
                                       shrink: true
                                    }} inputProps={{
                                       placeholder: 'Name'
                                    }} />
                                 </Box>
                                 <Box className={styles.inputFields}>
                                    <label for="text-area">From email</label>
                                    <TextField id="" onChange={handleEmailChange} label="" variant="outlined" InputLabelProps={{
                                       shrink: true
                                    }} inputProps={{
                                       placeholder: 'email@email.com'

                                    }} />
                                 </Box>
                                 <Box className={styles.inputFields}>
                                    <label for="text-area">Subject line</label>
                                    <TextField id="subjectLine-text-area" onChange={handleSubjectChange} label="" variant="outlined" InputLabelProps={{
                                       shrink: true
                                    }} inputProps={{
                                       placeholder: 'Subject line',
                                       maxLength: 70,
                                    }} />
                                    <span className={styles.numberFieldText} style={{ color: subjectLinelengthColor() }}>{lengthSubjectLine}/70</span>
                                    {errorMessageSubjectLine && <span className={styles.errorMessageSubjectLine} style={{ color: subjectLinelengthColor() }}>{errorMessageSubjectLine}</span>}
                                 </Box>
                                 <Box className={styles.inputFields}>
                                    <label for="text-area">Preview text</label>
                                    <TextField id="preheader-text-area" onChange={handlePreheaderChange} label="" variant="outlined" InputLabelProps={{
                                       shrink: true
                                    }} inputProps={{
                                       placeholder: 'Add some text',
                                       maxLength: 50,
                                    }} />
                                    <span className={styles.numberFieldText} style={{ color: preheaderlengthColor() }}>{lengthPreheaderChange}/50</span>
                                    {errorMessagePreheader && <span className={styles.errorMessagePreheader} style={{ color: preheaderlengthColor() }}>{errorMessagePreheader}</span>}
                                 </Box>
                              </form>
                           </FormControl>
                        </Grid>
                        <Grid item className={`${styles.rightArea}`} sm={7}>
                           <Box className={styles.innerRight}>
                              <label htmlFor="text-area">Email body</label>
                              <TextareaAutosize
                                 id="text-area"
                                 name="text-area"
                                 aria-label="empty textarea"
                                 minRows={3}
                                 value={emailBodyText}
                                 onChange={handleEmailBodyChange}
                                 placeholder="Add your message"
                              />
                           </Box>
                        </Grid>
                     </Grid>
                  </Grid>
               </Container>
            </Box>
            <Box className={`${styles.outlook_realtime_preview}`}>
               <Container className={`${styles.wrapper}`}>
                  <Grid container className={`${styles.inner_row}`}>
                     <Grid item sm={12} md={5} className={`${styles.left}`}>
                        <Box className={`${styles.col_title}`}>
                           <Typography variant="subtitle2" lineHeight={"1.15em"} fontWeight={700}>Inbox Preview</Typography>
                        </Box>
                        <Box className={`${styles.inbox_mailing_list}`}>
                           {outlook_inbox.map((item, index) => (
                              <Box className={`${styles.single_item} ${[2, 5].includes(index) ? styles.read_item : styles.unread_item} `} key={index}>
                                 <Box className={`${styles.single_left}`}>
                                    <Box className={`${styles.thumbnail}`}>
                                       {item.thumbnail ? (
                                          typeof item.thumbnail === 'string' ? (
                                             <img src={item.thumbnail} alt={item.name} />
                                          ) : (
                                             item.thumbnail
                                          )
                                       ) : (
                                          <User_Icon />
                                       )}
                                    </Box>
                                    <Box className={`${styles.mail_content}`}>
                                       <Typography variant="h4" fontWeight={600} className={`${styles.name}`} lineHeight={"17px"}>{item.name}</Typography>
                                       <Typography variant="h5" fontWeight={400} className={`${styles.subject_line}`} lineHeight={"17px"}>{item.subjectLine}</Typography>
                                       <Typography variant="h6" fontWeight={400} color={"#bbbbbb"} className={`${styles.preview_text}`} lineHeight={"17px"}>{item.previewText}</Typography>
                                    </Box>
                                 </Box>
                                 <Box className={`${styles.single_right}`}>
                                    <Box className={`${styles.delete_icon}`}>
                                       <img src={delete_icon} alt="" />
                                    </Box>
                                    <Typography variant="span">
                                       {item.time}
                                    </Typography>
                                 </Box>
                              </Box>
                           ))}
                        </Box>
                     </Grid>
                     <Grid item sm={12} md={7} className={`${styles.right}`}>
                        <Box className={`${styles.inner_right}`}>
                           <Typography variant="h3">{subjectText || "<add subject line>"}</Typography>
                           <Box className={`${styles.box_preview}`}>
                              <Box className={`${styles.box_header}`}>
                                 <Box className={`${styles.box_header_left}`}>
                                    <Box className={`${styles.thumbnail_wrapper}`}>
                                       <User_Icon />
                                    </Box>
                                    <Box className={`${styles.name_n_email}`}>
                                       <Typography variant="h2">
                                          <Typography variant="h5" fontWeight={700}>{firstNameText || "<add name>"}</Typography>
                                          <Typography fontWeight={400} variant="span">to me</Typography>
                                       </Typography>
                                       <Typography variant="h6" fontWeight={400}>
                                          {emailText || "<add email>"} <u>via</u> youremailmarketingsoftware.com
                                       </Typography>
                                       <Typography className={`${styles.hidden_time}`} variant="h6" fontWeight={400}>6:57 AM</Typography>
                                    </Box>
                                 </Box>
                                 <Box className={`${styles.box_header_right}`}>
                                    <Box>
                                       <img className={`${styles.dots_image}`} src={dotsIcon} alt='dots icon' />
                                       <img src={arrow_outlook_preview} alt="Arrow Images" />
                                       <Typography>Thur, April 28, 6:57 AM (3 hours ago)</Typography>
                                    </Box>
                                 </Box>
                              </Box>
                              <Box className={`${styles.box_body}`}>
                                 {emailBodyText || "<add your email>"}
                              </Box>
                           </Box>
                        </Box>
                     </Grid>
                  </Grid>
               </Container>
            </Box>
         </Box>
      </>
   );
}

export default EmailPreviewToolOutlook;
