import React, { useState } from "react";
import { Box, Container, Grid, Typography, Switch, Card, CardContent, CardMedia, Button } from "@mui/material";
import PropTypes from 'prop-types';
import styles from '../searchAdPreview/SearchAdPreview.module.scss'
import Alert from "../../components/icons/Alert"
import HeroSectionInner from "../../components/Hero Section/HeroSectionInner";
import SocialAdPreviewTable from "../../components/SocialAdPreviewTable"
import SearchPreviewMedia from "../../components/icons/SearchPreviewMedia";
import SearchPreviewVideoMedia from "../../components/icons/SearchPreviewVideoMedia";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </Box>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};


function SocialAdPreview() {

    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [isMobileView, setIsMobileView] = useState(false);
    const [socialType, setSocialType] = useState('social-ad');
    const [mediaType, setMediaType] = useState('1x1 image');
    const [businessName, setBusinessName] = useState('');
    const [postDetails, setPostDetails] = useState('');
    const [adHeadline, setAdHeadline] = useState('');
    const [adDescription, setAdDescription] = useState('');
    const [websiteUrl, setWebsiteUrl] = useState('');
    const [buttonAction, setButtonAction] = useState('Apply Now');
    const [postBusinessName, setPostBusinessName] = useState("");
    const [mediaTypeTwo, setMediaTypeTwo] = useState("1x1 image");
    const [postCopyTwo, setPostCopyTwo] = useState("");

    const handleToggleChange = () => {
        setIsMobileView(!isMobileView);
    };

    const handleSocialTypeChange = (event) => {
        setSocialType(event)
    }

    const handleMediaTypeChange = (event) => {
        setMediaType(event)
    }

    const handleBusinessName = (newFirstName) => {
        setBusinessName(newFirstName)
    };

    const handlePostCopy = (newFirstName) => {
        setPostDetails(newFirstName)
    };

    const handleAdHeading = (newFirstName) => {
        setAdHeadline(newFirstName)
    };

    const handleAdDescription = (newFirstName) => {
        setAdDescription(newFirstName)
    };

    const handleButtonAction = (newFirstName) => {
        setButtonAction(newFirstName + "")
    };

    const handleBusinessNameChangeTwo = (event) => {
        setPostBusinessName(event)
    }

    const handleMediaTypeChangeTwo = (event) => {
        setMediaTypeTwo(event)
    }

    const handlePostCopyChangeTwo = (event) => {
        setPostCopyTwo(event)
    }

    const handleWebDisplay = (newFirstName) => {
        setWebsiteUrl(newFirstName)
    };

    const SearchAdPreview = {
        title: 'Social Ad Preview Tool',
        description: (
            <>
                {/* <strong>Why it matters: </strong> */}
                Elevate the look of your social ads and posts with our real-time social preview tool.
            </>
        )
    };
    return (
        <>
            <Box className="mainContent">
                <HeroSectionInner
                    title={SearchAdPreview.title}
                    description={SearchAdPreview.description}
                />
                <Box className={`${styles.firstSection}`}>
                    <Container className={styles.wrapper}>
                        <Grid>
                            <Grid container className={`${styles.workingArea} csMargin`}>
                                <Grid item sm={12}>
                                    <Box className={`${styles.alertBoxWrapper}`}>
                                        <Box className={styles.alertBox} display="flex" alignItems="center" gap={1.25}>
                                            <Alert />
                                            <Typography variant="subtitle2" lineHeight={1.34}>
                                            This is a preview, not a promise. Hopefully, you will get a good feel for how your messages will appear.
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item className={`${styles.leftArea}`} sm={5}>
                                    <CustomTabPanel className={`${styles.tab__content}`} value={value} index={0}>
                                        <SocialAdPreviewTable onSocialTypeChange={handleSocialTypeChange} onMediaTypeChange={handleMediaTypeChange} onBusinessNameChange={handleBusinessName} onPostCopyChange={handlePostCopy} onAdHeadingChange={handleAdHeading} onAdDescriptionChange={handleAdDescription} onWebDisplayChange={handleWebDisplay} onButtonActionChange={handleButtonAction} onBusinessNameChangeTwo={handleBusinessNameChangeTwo} onMediaTypeChangeTwo={handleMediaTypeChangeTwo} onPostCopyChangeTwo={handlePostCopyChangeTwo} />
                                    </CustomTabPanel>

                                </Grid>
                                <Grid item className={`${styles.rightArea}`} sm={7}>
                                    <Box className={`${styles.viewmode}`}>
                                        <span className={`${styles.spandesktop} ${styles.view__btns}`}>Desktop View</span>
                                        <Switch
                                            checked={isMobileView}
                                            onChange={handleToggleChange}
                                            name="viewToggle"
                                            inputProps={{ 'aria-label': 'toggle between desktop and mobile view' }}
                                            className="switch_method"
                                        />
                                        <span className={`${styles.view__btns}`}>Mobile View</span>
                                    </Box>
                                    <Box className={`${styles.demo_previews}`}>
                                        {!isMobileView && (
                                            <>
                                                {socialType == 'social-ad' && (
                                                    <Box className={`${styles.outer_card}`}>
                                                        <Card className={styles.ad_card}>
                                                            <CardContent className={styles.ad_card_header}>
                                                                <Box className={styles.business_info}>
                                                                    <div className={styles.business_avatar} />
                                                                    <Box ml={2}>
                                                                        <Typography style={{ fontWeight: "700",fontSize: "16px" }}>{businessName || "<<Business Name>>"}</Typography>
                                                                        <Typography className={styles.post_details}>{"Sponsored"}</Typography>
                                                                    </Box>
                                                                </Box>
                                                            </CardContent>
                                                            <Box ml={2} my={1}>
                                                                <Typography className={styles.post_details}>
                                                                    {postDetails || "<<postdetails>>"}
                                                                </Typography>
                                                            </Box>
                                                            <CardMedia />
                                                            {mediaType == '1x1 image' && (
                                                                <>
                                                                    <Box className={styles.ad_card_media}>
                                                                        <SearchPreviewMedia />
                                                                    </Box>
                                                                    <Box ml={3} my={2} className={styles.picture_media}>
                                                                        <Box className={styles.left_picture_media}>
                                                                            <Typography style={{ fontSize: "12px" }} className={styles.ad_description}>
                                                                                {websiteUrl || "<<website>>"}
                                                                            </Typography>
                                                                            <Typography variant="h6" className={styles.ad_headlines}>
                                                                                {adHeadline || "<<Ad Headline>>"}
                                                                            </Typography>
                                                                            <Typography style={{ fontSize: "14px" }} className={styles.ad_description}>
                                                                                {adDescription || "<<Ad Description>>"}
                                                                            </Typography>
                                                                        </Box>
                                                                        <Box className={styles.right_picture_media}>
                                                                            <Button className={styles.button_action} variant="contained">
                                                                                {buttonAction}
                                                                            </Button>
                                                                        </Box>
                                                                    </Box>
                                                                </>
                                                            )}
                                                            {mediaType == '4x3 image' && (
                                                                <>
                                                                    <Box className={styles.middle_ad_card_media}>
                                                                        <SearchPreviewMedia />
                                                                    </Box>
                                                                    <Box ml={3} my={2} className={styles.picture_media}>
                                                                        <Box className={styles.left_picture_media}>
                                                                            <Typography style={{ fontSize: "12px" }} className={styles.ad_description}>
                                                                                {websiteUrl || "<<website>>"}
                                                                            </Typography>
                                                                            <Typography variant="h6" className={styles.ad_headlines}>
                                                                                {adHeadline || "<<Ad Headline>>"}
                                                                            </Typography>
                                                                            <Typography style={{ fontSize: "14px" }} className={styles.ad_description}>
                                                                                {adDescription || "<<Ad Description>>"}
                                                                            </Typography>
                                                                        </Box>
                                                                        <Box className={styles.right_picture_media}>
                                                                            <Button className={styles.button_action} variant="contained">
                                                                                {buttonAction}
                                                                            </Button>
                                                                        </Box>
                                                                    </Box>
                                                                </>
                                                            )}
                                                            {mediaType == 'video' && (
                                                                <>
                                                                    <Box className={styles.middle_ad_card_media}>
                                                                        <SearchPreviewVideoMedia />
                                                                    </Box>
                                                                    <Box ml={3} my={2} className={styles.picture_media}>
                                                                        <Box className={styles.left_picture_media}>
                                                                            <Typography style={{ fontSize: "12px" }} className={styles.ad_description}>
                                                                                {websiteUrl || "<<website>>"}
                                                                            </Typography>
                                                                            <Typography variant="h6" className={styles.ad_headlines}>
                                                                                {adHeadline || "<<Ad Headline>>"}
                                                                            </Typography>
                                                                            <Typography style={{ fontSize: "14px" }} className={styles.ad_description}>
                                                                                {adDescription || "<<Ad Description>>"}
                                                                            </Typography>
                                                                        </Box>
                                                                        <Box className={styles.right_picture_media}>
                                                                            <Button className={styles.button_action} variant="contained">
                                                                                {buttonAction}
                                                                            </Button>
                                                                        </Box>
                                                                    </Box>
                                                                </>
                                                            )}
                                                        </Card>
                                                        <Box className={`${styles.notice_no_preview}`}>
                                                            <Typography variant="h2" className={`${styles.title} fontGruffy`} fontWeight={700}>That don’t make sense.</Typography>
                                                            <Typography variant="h6" className={`${styles.sub_content}`} fontWeight={400}>It looks like your attempting desktop feats on a mobile screen resolution? Bold move! But this screen’s too thin for that kind of stretch. So we’re going to play to our strengths and stay mobile.</Typography>
                                                            <Typography variant="subtitle1" className={`${styles.toggle_text}`} fontWeight={700}>Use the toggle above to go back.</Typography>
                                                        </Box>
                                                    </Box>
                                                )}{socialType == 'social-post' && (
                                                    <Box className={`${styles.outer_card}`}>
                                                        <Card className={styles.ad_card}>
                                                            <CardContent className={styles.ad_card_header}>
                                                                <Box className={styles.business_info}>
                                                                    <div className={styles.business_avatar} />
                                                                    <Box ml={2}>
                                                                        <Typography style={{ fontWeight: "700", fontSize:"16px" }}>{postBusinessName || "<<Business Name>>"}</Typography>
                                                                        <Typography className={styles.post_details}>{"Sponsored"}</Typography>
                                                                    </Box>
                                                                </Box>
                                                            </CardContent>
                                                            <Box ml={2} my={1}>
                                                                <Typography className={styles.post_details}>
                                                                    {postCopyTwo || "<<postdetails>>"}
                                                                </Typography>
                                                            </Box>
                                                            <CardMedia />
                                                            {(mediaTypeTwo == '1x1 image') && (
                                                                <>
                                                                    <Box className={styles.ad_card_media}>
                                                                        <SearchPreviewMedia />
                                                                    </Box>
                                                                </>
                                                            )} {(mediaTypeTwo == '4x3 image') && (
                                                                <>
                                                                    <Box className={styles.middle_ad_card_media}>
                                                                        <SearchPreviewMedia />
                                                                    </Box>
                                                                </>
                                                            )}{mediaTypeTwo == 'video' && (
                                                                <>
                                                                    <Box className={styles.middle_ad_card_media}>
                                                                        <SearchPreviewVideoMedia />
                                                                    </Box>
                                                                </>
                                                            )}
                                                        </Card>
                                                    </Box>
                                                )}
                                                <Box className={`${styles.notice_no_preview}`}>
                                                    <Typography variant="h2" className={`${styles.title} fontGruffy`} fontWeight={700}>That don’t make sense.</Typography>
                                                    <Typography variant="h6" className={`${styles.sub_content}`} fontWeight={400}>It looks like your attempting desktop feats on a mobile screen resolution? Bold move! But this screen’s too thin for that kind of stretch. So we’re going to play to our strengths and stay mobile.</Typography>
                                                    <Typography variant="subtitle1" className={`${styles.toggle_text}`} fontWeight={700}>Use the toggle above to go back.</Typography>
                                                </Box>
                                            </>
                                        )}
                                        {isMobileView && (
                                            <>
                                                {socialType == 'social-ad' && (
                                                    <Box className={`${styles.innerRight} ${styles.phone_side}`}>
                                                        <Box className={styles.preview_mobile_view}>
                                                            <Box className={styles.mobileOuterClass}>
                                                                <Box className={styles.business_info}>
                                                                    <div className={styles.business_avatar} />
                                                                    <Box ml={2}>
                                                                        <Typography style={{ fontWeight: "700", fontSize: "16px" }}>{businessName || "<<Business Name>>"}</Typography>
                                                                        <Typography className={styles.post_details}>{"Sponsored"}</Typography>
                                                                    </Box>
                                                                </Box>
                                                                <Typography styles={{ fontSize: "16px" }} pb={1}>{postDetails || "<<postdetails>>"}</Typography>
                                                            </Box>
                                                            {mediaType == '1x1 image' && (
                                                                <>
                                                                    <Box className={styles.ad_card_media}>
                                                                        <SearchPreviewMedia />
                                                                    </Box>
                                                                    <Box ml={3} my={2} className={styles.picture_media}>
                                                                        <Box className={styles.left_picture_media}>
                                                                            <Typography style={{ fontSize: "12px", colors: "666666" }} className={styles.ad_description}>
                                                                                {websiteUrl || "<<website>>"}
                                                                            </Typography>
                                                                            <Typography variant="h6" className={styles.ad_headlines}>
                                                                                {adHeadline || "<<Ad Headline>>"}
                                                                            </Typography>
                                                                            <Typography style={{ fontSize: "14px" }} className={styles.ad_description}>
                                                                                {adDescription || "<<Ad Description>>"}
                                                                            </Typography>
                                                                        </Box>
                                                                        <Box className={styles.right_picture_media}>
                                                                            <Button className={styles.button_action} variant="contained">
                                                                                {buttonAction}
                                                                            </Button>
                                                                        </Box>
                                                                    </Box>
                                                                </>
                                                            )}
                                                            {mediaType == '4x3 image' && (
                                                                <>
                                                                    <Box className={styles.middle_ad_card_media}>
                                                                        <SearchPreviewMedia />
                                                                    </Box>
                                                                    <Box ml={3} my={2} className={styles.picture_media}>
                                                                        <Box className={styles.left_picture_media}>
                                                                            <Typography style={{ fontSize: "12px", colors: "666666" }} className={styles.ad_description}>
                                                                                {websiteUrl || "<<website>>"}
                                                                            </Typography>
                                                                            <Typography variant="h6" className={styles.ad_headlines}>
                                                                                {adHeadline || "<<Ad Headline>>"}
                                                                            </Typography>
                                                                            <Typography style={{ fontSize: "14px" }} className={styles.ad_description}>
                                                                                {adDescription || "<<Ad Description>>"}
                                                                            </Typography>
                                                                        </Box>
                                                                        <Box className={styles.right_picture_media}>
                                                                            <Button className={styles.button_action} variant="contained">
                                                                                {buttonAction}
                                                                            </Button>
                                                                        </Box>
                                                                    </Box>
                                                                </>
                                                            )}
                                                            {mediaType == 'video' && (
                                                                <>
                                                                    <Box className={styles.middle_ad_card_media}>
                                                                        <SearchPreviewVideoMedia />
                                                                    </Box>
                                                                    <Box ml={3} my={2} className={styles.picture_media}>
                                                                        <Box className={styles.left_picture_media}>
                                                                            <Typography style={{ fontSize: "12px", colors: "666666" }} className={styles.ad_description}>
                                                                                {websiteUrl || "<<website>>"}
                                                                            </Typography>
                                                                            <Typography variant="h6" className={styles.ad_headlines}>
                                                                                {adHeadline || "<<Ad Headline>>"}
                                                                            </Typography>
                                                                            <Typography style={{ fontSize: "14px" }} className={styles.ad_description}>
                                                                                {adDescription || "<<Ad Description>>"}
                                                                            </Typography>
                                                                        </Box>
                                                                        <Box className={styles.right_picture_media}>
                                                                            <Button className={styles.button_action} variant="contained">
                                                                                {buttonAction}
                                                                            </Button>
                                                                        </Box>
                                                                    </Box>
                                                                </>
                                                            )}
                                                        </Box>

                                                    </Box>
                                                )}
                                                {socialType == 'social-post' && (
                                                    <Box className={`${styles.innerRight} ${styles.phone_side}`}>
                                                        <Box className={styles.preview_mobile_view}>
                                                            <Box className={styles.mobileOuterClass}>
                                                                <Box className={styles.business_info}>
                                                                    <div className={styles.business_avatar} />
                                                                    <Box ml={2}>
                                                                        <Typography style={{ fontWeight: "700", fontSize: "16px" }}>{postBusinessName || "<<Business Name>>"}</Typography>
                                                                        <Typography className={styles.post_details}>{"Sponsored"}</Typography>
                                                                    </Box>
                                                                </Box>
                                                                <Typography styles={{ fontSize: "16px" }} pb={1}>{postCopyTwo || "<<postdetails>>"}</Typography>
                                                            </Box>
                                                            {mediaTypeTwo == '1x1 image' && (
                                                                <>
                                                                    <Box className={styles.ad_card_media}>
                                                                        <SearchPreviewMedia />
                                                                    </Box>
                                                                </>
                                                            )}
                                                            {mediaTypeTwo == '4x3 image' && (
                                                                <>
                                                                    <Box className={styles.middle_ad_card_media}>
                                                                        <SearchPreviewMedia />
                                                                    </Box>
                                                                </>
                                                            )}
                                                            {mediaTypeTwo == 'video' && (
                                                                <>
                                                                    <Box className={styles.middle_ad_card_media}>
                                                                        <SearchPreviewVideoMedia />
                                                                    </Box>
                                                                </>
                                                            )}
                                                        </Box>

                                                    </Box>
                                                )}
                                            </>
                                        )}
                                    </Box>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>
        </>
    );
}

export default SocialAdPreview;