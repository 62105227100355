import React from 'react';
import { Box, Button, Container, FormControl, Grid, Link, Typography, TextareaAutosize, TextField } from "@mui/material";
import styles from "./../../pages/emailPreviewTool/Email.module.scss"
import starIcon from "../../images/star-black.svg";
import dotsIcon from "../../images/dots.svg";

export default function EmailPreviewContent({ firstNameText, emailText }) {
    return (
        <Box className={styles.shareEmailContent}>
            <Box className={styles.leftSection}>
                <Typography variant="subtitle1" className={styles.titleName}>
                    <strong>{firstNameText || '<add name>'} </strong>
                    <Typography variant="subtitle2" className={`${styles.fs12} blockContent`}>to me</Typography>
                </Typography>
                <Typography variant="subtitle2" className={`${styles.hideMobile} ${styles.emailLayer}`}>
                    <Typography variant="span" lineHeight={2.1}>{emailText ? `<${emailText}>` : ` <add email> `}</Typography>
                    <Typography variant="span" lineHeight={2.1}>
                        <span className="underlined">via</span> youremailmarketingsoftware.com
                    </Typography>
                </Typography>
                <Typography variant="subtitle2" lineHeight={2.1} className={styles.showMobile} marginLeft={1}>6:57 AM</Typography>
            </Box>
            <Box className={styles.rightSection}>
                <Typography variant="subtitle2" lineHeight={2.1} className={`${styles.hideMobile} ${styles.fs12}`}>Thur, April 28, 6:57 AM (3 hours ago)</Typography>
                <Box className={styles.iconWrap} paddingTop={0.4}>
                    <img className={styles.hideMobile} src={starIcon} alt='star icon' />
                    <img src={dotsIcon} alt='dots icon' />
                </Box>
            </Box>
        </Box>
    );
}
