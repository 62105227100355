import React, { useEffect, useState } from "react";
import { Box, Container, FormControl, Grid, Typography, TextField, Switch, TextareaAutosize } from "@mui/material";
import { Link } from 'react-router-dom'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import styles from "./SearchAdPreview.module.scss";
import Alert from "../../components/icons/Alert"
import HeroSectionInner from "../../components/Hero Section/HeroSectionInner";
import BusinessForm from "../../components/BusinessForm";
import BusinessForm2 from "../../components/BusinessForm2"
import BusinessForm3 from "../../components/BusinessForm3"

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </Box>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function SearchAdPreview() {

    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [isMobileView, setIsMobileView] = useState(false);
    const [text, setText] = useState('');
    const [businesstext, setbusinessText] = useState('');
    const [businessemail, setbusinessemail] = useState('');
    const [firstheadline, setfirstheadline] = useState('');
    const [secondheadline, setsecondheadline] = useState('');
    const [addescription, setaddescription] = useState('');
    const [sitelinkone, setsitelinkone] = useState('');
    const [sitelinktwo, setsitelinktwo] = useState('');
    const [sitelinkthree, setsitelinkthree] = useState('');
    const [sitelinkfour, setsitelinkfour] = useState('');

    const handleToggleChange = () => {
        setIsMobileView(!isMobileView);
    };

    const handleInputChange = (event) => {
        setText(event.target.value);
    };

    const handleFirstNameChange = (newFirstName) => {
        setbusinessText(newFirstName)
    };

    const handleFirstemailChange = (newFirstName) => {
        setbusinessemail(newFirstName)
    };

    const handleFirstheadlineChange = (newFirstName) => {
        setfirstheadline(newFirstName)
    };

    const handleSecondheadlineChange = (newFirstName) => {
        setsecondheadline(newFirstName)
    };

    const handleSiteFirstChange = (newFirstName) => {
        setsitelinkone(newFirstName + "")
    };

    const handleSiteSecondChange = (newFirstName) => {
        setsitelinktwo("" +newFirstName)
    };

    const handleSiteThirdChange = (newFirstName) => {
        setsitelinkthree("" +newFirstName)
    };

    const handleSiteFourthChange = (newFirstName) => {
        setsitelinkfour("" +newFirstName)
    };

    useEffect(()=>{
        if(sitelinktwo?.length  <= 0){
          setsitelinktwo(null)
       }else if(sitelinkthree?.length <= 0){
          setsitelinkthree(null)
       }else if(sitelinkfour?.length <= 0){
          setsitelinkfour(null)
       }
    },[sitelinktwo , sitelinkthree,sitelinkfour])

    const handleDescriptionChange = (newFirstName) => {
        setaddescription(newFirstName)
    };
     
    const [businesstext2, setbusinessText2] = useState('Business Competitor');
    const [businessemail2, setbusinessemail2] = useState('http://www.domain.com');
    const [firstheadline2, setfirstheadline2] = useState('website.co Project Management');
    const [secondheadline2, setsecondheadline2] = useState('Get a Free Estimate');
    const [addescription2, setaddescription2] = useState('Crafting the Extraordinary: Our services are designed to inspire and achieve results');
    const [sitelinkone2, setsitelinkone2] = useState('');
    const [sitelinktwo2, setsitelinktwo2] = useState('');
    const [sitelinkthree2, setsitelinkthree2] = useState('');
    const [sitelinkfour2, setsitelinkfour2] = useState('');
    const [name, setname] = useState('');
    const [email,setemail] = useState('')
    const [firsthead,setFirsthead] = useState('')
    const [secondhead,setSecondhead] = useState('')
    const [descriptionhead,setDescriptionhead] = useState('')
    useEffect (()=>{
        console.log("businesstext2.length",businesstext2?.length)
        if(businesstext2?.length < 1){
            setname('inputfieldtitle')
        }
        else{
            setname(businesstext2)  
        }
    },[businesstext2])

    useEffect (()=>{
        if(businessemail2?.length < 1){
            setemail('inputfieldtitle')
        }
        else{
            setemail(businessemail2)  
        }
    },[businessemail2])

    useEffect (()=>{
        if(firstheadline2?.length < 1){
            setFirsthead('inputfieldtitle')
        }
        else{
            setFirsthead(firstheadline2)  
        }
    },[firstheadline2])

    useEffect (()=>{
        if(secondheadline2?.length < 1){
            setSecondhead('inputfieldtitle')
        }
        else{
            setSecondhead(secondheadline2)  
        }
    },[secondheadline2])

    useEffect (()=>{
        if(addescription2?.length < 1){
            setDescriptionhead('inputfieldtitle')
        }
        else{
            setDescriptionhead(addescription2)  
        }
    },[addescription2])

    const handleFirstNameChange2 = (newFirstName) => {
        setbusinessText2(newFirstName)
    };

    const handleFirstemailChange2 = (newFirstName) => {
        setbusinessemail2(newFirstName)
    };

    const handleFirstheadlineChange2 = (newFirstName) => {
        setfirstheadline2(newFirstName)
    };

    const handleSecondheadlineChange2 = (newFirstName) => {
        setsecondheadline2(newFirstName)
    };

    const handleSiteFirstChange2 = (newFirstName) => {
        setsitelinkone2(newFirstName + '  ')
    };

    const handleSiteSecondChange2 = (newFirstName) => {
         setsitelinktwo2(""+ newFirstName)
    };

    const handleSiteThirdChange2 = (newFirstName) => {
        setsitelinkthree2(""+ newFirstName)
    };

    const handleSiteFourthChange2 = (newFirstName) => {
        setsitelinkfour2( ""+ newFirstName)
    };

    const handleDescriptionChange2 = (newFirstName) => {
        setaddescription2(newFirstName)
    };

    useEffect(()=>{
        if(sitelinktwo2?.length  <= 0){
          setsitelinktwo2(null)
       }else if(sitelinkthree2?.length <= 0){
          setsitelinkthree2(null)
       }else if(sitelinkfour2?.length <= 0){
          setsitelinkfour2(null)
       }

  },[sitelinktwo2 , sitelinkthree2,sitelinkfour2])

    const [businesstext3, setbusinessText3] = useState('Acme');
    const [businessemail3, setbusinessemail3] = useState('http://www.website.com');
    const [firstheadline3, setfirstheadline3] = useState('We Are Better Than Everyone');
    const [secondheadline3, setsecondheadline3] = useState('Compare And See For Yourself');
    const [addescription3, setaddescription3] = useState('Elevate your success with our innovative solutions- Experience excellence today!');
    const [sitelinkone3, setsitelinkone3] = useState('About Us');
    const [sitelinktwo3, setsitelinktwo3] = useState('How It Works');
    const [sitelinkthree3, setsitelinkthree3] = useState('Free Trial');
    const [sitelinkfour3, setsitelinkfour3] = useState('');
    const [name3, setname3] = useState('');
    const [email3,setemail3] = useState('');
    const [firsthead3,setFirsthead3] = useState('')
    const [secondhead3,setSecondhead3] = useState('')
    const [descriptionhead3,setDescriptionhead3] = useState('')
    const [firstsite3,setfirstsite3] = useState('')
    const [secondsite3,setsecondsite3] = useState('')
    const [threesite3,setthreesite3] = useState('')
    const [foursite3,setfoursite3] = useState('')

    useEffect (()=>{
        if(businesstext3?.length < 1){
            setname3('inputfieldtitle')
        }
        else{
            setname3(businesstext3)  
        }
    },[businesstext3])

    useEffect (()=>{
        if(businessemail3?.length < 1){
            setemail3('inputfieldtitle')
        }
        else{
            setemail3(businessemail3)  
        }
    },[businessemail3])

    useEffect (()=>{
        if(firstheadline3?.length < 1){
            setFirsthead3('inputfieldtitle')
        }
        else{
            setFirsthead3(firstheadline3)  
        }
    },[firstheadline3])

    useEffect (()=>{
        if(secondheadline3?.length < 1){
            setSecondhead3('inputfieldtitle')
        }
        else{
            setSecondhead3(secondheadline3)  
        }
    },[secondheadline3])

    useEffect (()=>{
        if(addescription3?.length < 1){
            setDescriptionhead3('inputfieldtitle')
        }
        else{
            setDescriptionhead3(addescription3)  
        }
    },[addescription3])

    useEffect (()=>{
        if(sitelinkone3?.length < 1){
            setfirstsite3('inputfieldtitle')
        }
        else{
            setfirstsite3(sitelinkone3)  
        }
    },[sitelinkone3])

    useEffect (()=>{
        if(sitelinktwo3?.length < 1){
            setsecondsite3('inputfieldtitle')
        }
        else{
            setsecondsite3(sitelinktwo3)  
        }
    },[sitelinktwo3])

    useEffect (()=>{
        if(sitelinkthree3?.length < 1){
            setthreesite3('inputfieldtitle')
        }
        else{
            setthreesite3(sitelinkthree3)  
        }
    },[sitelinkthree3])

    useEffect (()=>{
        if(sitelinkfour3?.length < 1){
            setfoursite3('inputfieldtitle')
        }
        else{
            setfoursite3(sitelinkfour3)  
        }
    },[sitelinkfour3])

    const handleFirstNameChange3 = (newFirstName) => {
        setbusinessText3(newFirstName)
    };

    const handleFirstemailChange3 = (newFirstName) => {
        setbusinessemail3(newFirstName)
    };

    const handleFirstheadlineChange3 = (newFirstName) => {
        setfirstheadline3(newFirstName)
    };

    const handleSecondheadlineChange3 = (newFirstName) => {
        setsecondheadline3(newFirstName)
    };

    const handleSiteFirstChange3 = (newFirstName) => {
        setsitelinkone3(newFirstName)
    };

    const handleSiteSecondChange3 = (newFirstName) => {
        setsitelinktwo3( newFirstName)
    };

    const handleSiteThirdChange3 = (newFirstName) => {
        setsitelinkthree3(newFirstName)
    };

    const handleSiteFourthChange3 = (newFirstName) => {
        setsitelinkfour3(newFirstName)
    };

    const handleDescriptionChange3 = (newFirstName) => {
        setaddescription3(newFirstName)
    };

    const SearchAdPreview = {
        title: 'Search Ad Preview Tool',
        description: (
            <>
                {/* <strong>Why it matters: </strong> */}
                Search ads need to stand out in a competitive environment. Previewing your ad in the context of actual search results helps you optimize for clarity, relevance, and click-through potential.
            </>
        )
    };
    return (
        <>
            <Box className="mainContent">
                <HeroSectionInner
                    title={SearchAdPreview.title}
                    description={SearchAdPreview.description}
                />
                <Box className={`${styles.firstSection}`}>
                    <Container className={styles.wrapper}>
                        <Grid>
                            <Grid container className={`${styles.workingArea} csMargin`}>
                                <Grid item sm={12}>
                                    <Box className={`${styles.alertBoxWrapper}`}>
                                        <Box className={styles.alertBox} display="flex" alignItems="center" gap={1.25}>
                                            <Alert />
                                            <Typography variant="subtitle2" lineHeight={1.34}>
                                            This is a preview, not a promise. Hopefully, you will get a good feel for how your messages will appear.
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item className={`${styles.leftArea}`} sm={5}>
                                    <Tabs className={`${styles.tab_list} search_ads_tabs`} value={value} onChange={handleChange} aria-label="basic tabs example">
                                        <Tab className={`${styles.single_tab_item} `} disableRipple label="Ad 1" {...a11yProps(0)} />
                                        <Tab className={`${styles.single_tab_item} `} disableRipple label="Ad 2" {...a11yProps(1)} />
                                        <Tab className={`${styles.single_tab_item} `} disableRipple label="Ad 3" {...a11yProps(2)} />
                                    </Tabs>

                                    <CustomTabPanel className={`${styles.tab__content}`} value={value} index={0}>
                                        <BusinessForm onFirstNameChange={handleFirstNameChange} onFirstEmailChange={handleFirstemailChange} onFirstHeadlineChange={handleFirstheadlineChange} onSecondHeadlineChange={handleSecondheadlineChange} onDescriptionChange={handleDescriptionChange} onFirstSitelinkChange={handleSiteFirstChange}  onSecondSitelinkChange={handleSiteSecondChange} onThirdSitelinkChange={handleSiteThirdChange} onfourthSitelinkChange={handleSiteFourthChange}  businesstextpas={businesstext} businessemailpass={businessemail} firstheadlinepass={firstheadline} secondheadlinepass={secondheadline} addescriptionpass={addescription} sitelinkonepass={sitelinkone} sitelinktwopass={sitelinktwo} sitelinkthreepass={sitelinkthree} sitelinkfourpass={sitelinkfour} />
                                    </CustomTabPanel>
                                    <CustomTabPanel className={`${styles.tab__content}`} value={value} index={1}>
                                        <Box className={`${styles.tab_Content_Item}`}>
                                        <BusinessForm2 onFirstNameChange2={handleFirstNameChange2} onFirstEmailChange2={handleFirstemailChange2} onFirstHeadlineChange2={handleFirstheadlineChange2} onSecondHeadlineChange2={handleSecondheadlineChange2} onDescriptionChange2={handleDescriptionChange2} onFirstSitelinkChange2={handleSiteFirstChange2}  onSecondSitelinkChange2={handleSiteSecondChange2} onThirdSitelinkChange2={handleSiteThirdChange2} onfourthSitelinkChange2={handleSiteFourthChange2}  hidename={name} hideemail={email} hidefirsthead={firsthead} hidesecondhead={secondhead} hidedescription={descriptionhead} sitelinkonepass2={sitelinkone2} sitelinktwopass2={sitelinktwo2} sitelinkthreepass2={sitelinkthree2} sitelinkfourpass2={sitelinkfour2} />
                                        </Box>
                                    </CustomTabPanel>
                                    <CustomTabPanel className={`${styles.tab__content}`} value={value} index={2}>
                                        <Box className={`${styles.tab_Content_Item}`}>
                                        <BusinessForm3 onFirstNameChange3={handleFirstNameChange3} onFirstEmailChange3={handleFirstemailChange3} onFirstHeadlineChange3={handleFirstheadlineChange3} onSecondHeadlineChange3={handleSecondheadlineChange3} onDescriptionChange3={handleDescriptionChange3} onFirstSitelinkChange3={handleSiteFirstChange3}  onSecondSitelinkChange3={handleSiteSecondChange3} onThirdSitelinkChange3={handleSiteThirdChange3} onfourthSitelinkChange3={handleSiteFourthChange3} hidename3={name3} hideemail3={email3} hidefirsthead3={firsthead3} hidesecondhead3={secondhead3} hidedescription3={descriptionhead3} hidesiteone3={firstsite3} hidesitetwo3={secondsite3} hidesitethree3={threesite3} hidesitefour3={foursite3}/>
                                        </Box>
                                    </CustomTabPanel>

                                </Grid>
                                <Grid item className={`${styles.rightArea}`} sm={7}>
                                    <Box className={`${styles.viewmode}`}>
                                        <span className={`${styles.spandesktop} ${styles.view__btns}`}>Desktop View</span>
                                        <Switch
                                            checked={isMobileView}
                                            onChange={handleToggleChange}
                                            name="viewToggle"
                                            inputProps={{ 'aria-label': 'toggle between desktop and mobile view' }}
                                            className="switch_method"
                                        />
                                        <span className={`${styles.view__btns}`}>Mobile View</span>
                                    </Box>
                                    <Box className={`${styles.demo_previews}`}>
                                        {!isMobileView && (
                                            <>
                                                <Box className={`${styles.maindesktopview}`}>
                                                    <Box className={`${styles.display_preview}`}> {/* LOOP THIS DIV */}
                                                        <Typography variant="subtitle1" className={`${styles.sponsored}`} fontWeight={700}>
                                                            Sponsored Ad 1
                                                        </Typography>
                                                        <Box className={`${styles.web_info_full}`}>
                                                            <Box className={`${styles.web_info_left}`}>
                                                                <Typography className={`${styles.website_logo}`}>.</Typography>
                                                            </Box>
                                                            <Box className={`${styles.web_info_right}`}>
                                                                <Typography variant="body1" className={`${styles.business_name}`}>{businesstext ||"< add Business name>"}</Typography>
                                                                <Typography variant="subtitle1" className={`${styles.site_name}`}>{businessemail || "<add website>"}</Typography>
                                                            </Box>
                                                        </Box>
                                                        <Typography variant="h5" className={`${styles.blue} ${styles.total_headings}`}><span className={`${styles.heading_1}`}>{firstheadline || "<headline1>"}</span><span className={`${styles.heading_2}`}>{secondheadline || "<headline2>"}</span></Typography>
                                                        <Typography variant="body1" className={`${styles.description}`}>{addescription ||"<add description>"}</Typography>
                                                        {true &&(
                                                            <Typography variant="body1" className={`${styles.site_links} ${styles.blue}`}> 
                                                            {sitelinkone && (
                                                                <span>
                                                                    {sitelinkone}
                                                                </span>
                                                            )}
                                                            {sitelinktwo && (
                                                                <span>
                                                                    {sitelinktwo}
                                                                </span>
                                                            )}
                                                            {sitelinkthree && (
                                                                <span>
                                                                    {sitelinkthree}
                                                                </span>
                                                            )}
                                                            {sitelinkfour && (
                                                                <span>
                                                                    {sitelinkfour}
                                                                </span>
                                                            )}
                                                        </Typography>
                                                        )}
                                                    </Box>
                                                    <Box className={`${styles.display_preview}`}> {/* LOOP THIS DIV */}
                                                        <Typography variant="subtitle1" className={`${styles.sponsored}`} fontWeight={700}>
                                                            Sponsored Ad 2
                                                        </Typography>
                                                        <Box className={`${styles.web_info_full}`}>
                                                            <Box className={`${styles.web_info_left}`}>
                                                                <Typography className={`${styles.website_logo}`}>.</Typography>
                                                            </Box>
                                                            
                                                            <Box className={`${styles.web_info_right}`}>
                                                            {businesstext2.length > 0 &&
                                                                <Typography variant="body1" className={`${styles.business_name}`}>{businesstext2}</Typography>
                                                            }
                                                            {businessemail2.length > 0 &&
                                                                <Typography variant="subtitle1" className={`${styles.site_name}`}>{businessemail2}</Typography>
                                                            }
                                                            </Box>
                                                             
                                                        </Box>
                        
                                                        <Typography variant="h5" className={`${styles.blue} ${styles.total_headings}`}>
                                                            {firstheadline2.length > 0 &&
                                                              <span className={`${styles.heading_1}`}>{firstheadline2}</span> 
                                                            }
                                                            {secondheadline2.length > 0 &&
                                                              <span className={`${styles.heading_2}`}>{secondheadline2}</span>
                                                             }
                                                        </Typography>
                                                        {addescription2.length > 0 && 
                                                        <Typography variant="body1" className={`${styles.description}`}>{addescription2}</Typography>
                                                        }
                                                        {true &&(
                                                            <Typography variant="body1" className={`${styles.site_links} ${styles.blue}`}> 
                                                            {sitelinkone2 && (
                                                                <span>
                                                                    {sitelinkone2}
                                                                </span>
                                                            )}
                                                            {sitelinktwo2 && (
                                                                <span>
                                                                    {sitelinktwo2}
                                                                </span>
                                                            )}
                                                            {sitelinkthree2 && (
                                                                <span>
                                                                    {sitelinkthree2}
                                                                </span>
                                                            )}
                                                            {sitelinkfour2 && (
                                                                <span>
                                                                    {sitelinkfour2}
                                                                </span>
                                                            )}
                                                        </Typography>
                                                        )}
                                                    </Box>
                                                    <Box className={`${styles.display_preview}`}> {/* LOOP THIS DIV */}
                                                        <Typography variant="subtitle1" className={`${styles.sponsored}`} fontWeight={700}>
                                                            Sponsored Ad 3
                                                        </Typography>
                                                        <Box className={`${styles.web_info_full}`}>
                                                            <Box className={`${styles.web_info_left}`}>
                                                                <Typography className={`${styles.website_logo}`}>.</Typography>
                                                            </Box>
                                                            <Box className={`${styles.web_info_right}`}>
                                                            {businesstext3.length > 0 &&
                                                                <Typography variant="body1" className={`${styles.business_name}`}>{businesstext3}</Typography>
                                                            }
                                                            {businessemail3.length > 0 &&
                                                                <Typography variant="subtitle1" className={`${styles.site_name}`}>{businessemail3}</Typography>
                                                            }
                                                            </Box>
                                                        </Box>
                                                        <Typography variant="h5" className={`${styles.blue} ${styles.total_headings}`}>
                                                            {firstheadline3.length > 0 &&
                                                               <span className={`${styles.heading_1}`}>{firstheadline3}</span> 
                                                            }
                                                            {secondheadline3.length > 0 &&
                                                               <span className={`${styles.heading_2}`}>{secondheadline3}</span>
                                                            }
                                                        </Typography>
                                                        
                                                        {addescription3.length > 0 &&
                                                        <Typography variant="body1" className={`${styles.description}`}>{addescription3}</Typography>
                                                        }
                                                        {true &&(
                                                            <Typography variant="body1" className={`${styles.site_links} ${styles.blue}`}> 
                                                            {sitelinkone3.length > 0 &&
                                                               <span>{sitelinkone3}</span>
                                                            }
                                                            {sitelinktwo3.length > 0 &&
                                                               <span>{sitelinktwo3}</span>
                                                            }
                                                            {sitelinkthree3.length > 0 &&
                                                               <span>{sitelinkthree3}</span>
                                                            }
                                                            {sitelinkfour3.length > 0 &&
                                                               <span>{sitelinkfour3}</span>
                                                            }
                                                        </Typography>
                                                        )}
                                                    </Box>
                                                </Box>
                                                <Box className={`${styles.notice_no_preview}`}>
                                                    <Typography variant="h2" className={`${styles.title} fontGruffy`} fontWeight={700}>That don’t make sense.</Typography>
                                                    <Typography variant="h6" className={`${styles.sub_content}`} fontWeight={400}>It looks like your attempting desktop feats on a mobile screen resolution? Bold move! But this screen’s too thin for that kind of stretch. So we’re going to play to our strengths and stay mobile.</Typography>
                                                    <Typography variant="subtitle1" className={`${styles.toggle_text}`} fontWeight={700}>Use the toggle above to go back.</Typography>
                                                </Box>
                                            </>
                                        )}
                                        {isMobileView && (
                                            <Box className={`${styles.innerRight} ${styles.phone_side}`}>
                                                {text ? (
                                                    text
                                                ) : (
                                                    <Box className={`${styles.mainmobileview}`}>
                                                        <Box className={`${styles.mobileview}`}>
                                                            <Box className={`${styles.display_preview}`}>
                                                                <Typography variant="subtitle1" className={`${styles.sponsored}`} fontWeight={700}>
                                                                    Sponsored Ad 1
                                                                </Typography>
                                                                <Box className={`${styles.web_info_full}`}>
                                                                    <Box className={`${styles.web_info_left}`}>
                                                                        <Typography className={`${styles.website_logo}`}>.</Typography>
                                                                    </Box>
                                                                    <Box className={`${styles.web_info_right}`}>
                                                                        <Typography variant="body1" className={`${styles.business_name}`}>{businesstext ||"< add Business name>"}</Typography>
                                                                        <Typography variant="subtitle1" className={`${styles.site_name}`}>{businessemail || "<add website>"}</Typography>
                                                                    </Box>
                                                                </Box>
                                                                <Typography variant="h5" className={`${styles.blue} ${styles.total_headings}`}><span className={`${styles.heading_1}`}>{firstheadline || "<headline1>"}</span></Typography>
                                                                <Typography variant="body1" className={`${styles.description}`}>{addescription ||"<add description>"}</Typography>
                                                                <Typography variant="body1" className={`${styles.site_links} ${styles.blue}`}> 
                                                                {sitelinkone && (
                                                                    <span>
                                                                        {sitelinkone}
                                                                    </span>
                                                                )}
                                                                {sitelinktwo && (
                                                                    <span>
                                                                        {sitelinktwo}
                                                                    </span>
                                                                )}
                                                                {sitelinkthree && (
                                                                    <span>
                                                                        {sitelinkthree}
                                                                    </span>
                                                                )}
                                                                {sitelinkfour && (
                                                                    <span>
                                                                        {sitelinkfour}
                                                                    </span>
                                                                )}
                                                                </Typography>
                                                                
                                                            </Box>
                                                            <Box className={`${styles.display_preview}`}>
                                                                <Typography variant="subtitle1" className={`${styles.sponsored}`} fontWeight={700}>
                                                                    Sponsored Ad 2
                                                                </Typography>
                                                                <Box className={`${styles.web_info_full}`}>
                                                                    <Box className={`${styles.web_info_left}`}>
                                                                        <Typography className={`${styles.website_logo}`}>.</Typography>
                                                                    </Box>
                                                                    <Box className={`${styles.web_info_right}`}>
                                                                    {businesstext2.length > 0 &&
                                                                        <Typography variant="body1" className={`${styles.business_name}`}>{businesstext2}</Typography>
                                                                    }
                                                                    {businessemail2.length > 0 &&
                                                                        <Typography variant="subtitle1" className={`${styles.site_name}`}>{businessemail2}</Typography>
                                                                    }
                                                                    </Box>
                                                                </Box>
                                                                {firstheadline2.length > 0 &&
                                                                    <Typography variant="h5" className={`${styles.blue} ${styles.total_headings}`}><span className={`${styles.heading_1}`}>{firstheadline2}</span></Typography>
                                                                }
                                                                {addescription2.length > 0 &&
                                                                   <Typography variant="body1" className={`${styles.description}`}>{addescription2 ||"Crafting the Extraordinary: Our services are designed to inspire and achieve results"}</Typography>
                                                                }
                                                                {true &&(
                                                                    <Typography variant="body1" className={`${styles.site_links} ${styles.blue}`}> 
                                                                        {sitelinkone2 && (
                                                                            <span>
                                                                                {sitelinkone2}
                                                                            </span>
                                                                        )}
                                                                        {sitelinktwo2 && (
                                                                            <span>
                                                                                {sitelinktwo2}
                                                                            </span>
                                                                        )}
                                                                        {sitelinkthree2 && (
                                                                            <span>
                                                                                {sitelinkthree2}
                                                                            </span>
                                                                        )}
                                                                        {sitelinkfour2 && (
                                                                            <span>
                                                                                {sitelinkfour2}
                                                                            </span>
                                                                        )}
                                                                   </Typography>
                                                                 )}
                                                            </Box>
                                                            <Box className={`${styles.display_preview}`}>
                                                                <Typography variant="subtitle1" className={`${styles.sponsored}`} fontWeight={700}>
                                                                    Sponsored Ad 3
                                                                </Typography>
                                                                <Box className={`${styles.web_info_full}`}>
                                                                    <Box className={`${styles.web_info_left}`}>
                                                                        <Typography className={`${styles.website_logo}`}>.</Typography>
                                                                    </Box>
                                                                    <Box className={`${styles.web_info_right}`}>
                                                                    {businesstext3.length > 0 &&
                                                                        <Typography variant="body1" className={`${styles.business_name}`}>{businesstext3}</Typography>
                                                                    }
                                                                    {businessemail3.length > 0 &&
                                                                        <Typography variant="subtitle1" className={`${styles.site_name}`}>{businessemail3}</Typography>
                                                                    }
                                                                    </Box>
                                                                </Box>
                                                                {firstheadline3.length > 0 &&
                                                                   <Typography variant="h5" className={`${styles.blue} ${styles.total_headings}`}><span className={`${styles.heading_1}`}>{firstheadline3}</span></Typography>
                                                                }
                                                                {addescription3.length > 0 &&
                                                                   <Typography variant="body1" className={`${styles.description}`}>{addescription3}</Typography>
                                                                }
                                                                   <Typography variant="body1" className={`${styles.site_links} ${styles.blue}`}> 
                                                                        {sitelinkone3.length > 0 &&
                                                                          <span>{sitelinkone3}</span>
                                                                        }
                                                                        {sitelinktwo3.length > 0 &&
                                                                          <span>{sitelinktwo3}</span>
                                                                        }
                                                                        {sitelinkthree3.length > 0 &&
                                                                          <span>{sitelinkthree3}</span>
                                                                        }
                                                                        {sitelinkfour3.length > 0 &&
                                                                          <span>{sitelinkfour3}</span>
                                                                        }
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                )}
                                            </Box>
                                        )}
                                    </Box>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>
        </>
    );
}

export default SearchAdPreview;




  

