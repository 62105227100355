import React from "react"

export default function SearchPreviewVideoMedia() {
    return (
        <svg width="149" height="149" viewBox="0 0 149 149" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M74.5 9.3125C91.7888 9.3125 108.369 16.1804 120.595 28.4055C132.82 40.6305 139.688 57.2112 139.688 74.5C139.688 91.7888 132.82 108.369 120.595 120.595C108.369 132.82 91.7888 139.688 74.5 139.688C57.2112 139.688 40.6305 132.82 28.4055 120.595C16.1804 108.369 9.3125 91.7888 9.3125 74.5C9.3125 57.2112 16.1804 40.6305 28.4055 28.4055C40.6305 16.1804 57.2112 9.3125 74.5 9.3125ZM74.5 149C94.2586 149 113.208 141.151 127.179 127.179C141.151 113.208 149 94.2586 149 74.5C149 54.7414 141.151 35.792 127.179 21.8205C113.208 7.84909 94.2586 0 74.5 0C54.7414 0 35.792 7.84909 21.8205 21.8205C7.84909 35.792 0 54.7414 0 74.5C0 94.2586 7.84909 113.208 21.8205 127.179C35.792 141.151 54.7414 149 74.5 149ZM95.6568 74.5L60.5312 95.9479V53.0522L95.6568 74.5ZM61.8408 42.9248C59.6873 41.6152 56.9809 41.557 54.7691 42.7793C52.5574 44.0016 51.1896 46.3588 51.1896 48.8615V100.109C51.1896 102.641 52.5574 104.969 54.7691 106.192C56.9809 107.414 59.6582 107.385 61.8408 106.046L103.747 80.4367C105.813 79.1562 107.094 76.9154 107.094 74.4709C107.094 72.0264 105.813 69.7855 103.747 68.5051L61.8408 42.8957V42.9248Z" fill="white" />
        </svg>

    )
}
