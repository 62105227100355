import React from 'react'

function ArrowLeft() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="9" viewBox="0 0 15 9" fill="none">
            <path d="M4.35645 8.86746L0.137695 4.81759C0.045791 4.73041 0 4.6151 0 4.49979C0 4.38448 0.0457617 4.26951 0.137344 4.1817L4.35609 0.131832C4.5392 -0.0439439 4.83598 -0.0439439 5.01879 0.131832C5.2016 0.307608 5.20189 0.592505 5.01879 0.767999L1.60049 4.0498H14.5312C14.7891 4.0498 15 4.2523 15 4.49979C15 4.74728 14.7904 4.94977 14.5312 4.94977H1.60049L5.01943 8.23186C5.20254 8.40764 5.20254 8.69253 5.01943 8.86803C4.83633 9.04352 4.53809 9.04465 4.35645 8.86746Z" fill="#F07561" />
        </svg>
    )
}

export default ArrowLeft