// App.js

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import './App.css';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import EmailPreviewTool from "./pages/emailPreviewTool/EmailPreviewTool";
import SmsPreview from "./pages/smsPreview/SmsPreview";
/* import HomePage from "./pages/homepage/Homepage" */
import HomePage from "./pages/homepage/Homepage.jsx"
import EmailPreviewToolOutlook from "./pages/emailPreviewTool/EmailPreviewToolOutlook.jsx";
import SearchAdPreview from "./pages/searchAdPreview/SearchAdPreview.jsx"
import SocialAdPreview from "./pages/socialAdPreview/SocialAdPreview.jsx"


function App() {
  return (
    <Router>
      <div>
        <Header />
        <div id="page-content-wrapper">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/sms-preview" element={<SmsPreview />} />
            <Route path="/email-preview-gmail" element={<EmailPreviewTool />} />
            <Route path="/email-preview-outlook" element={<EmailPreviewToolOutlook />} />
            <Route path="/search-ad-preview" element={<SearchAdPreview />} />
            <Route path="/social-ad-preview" element={<SocialAdPreview />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
