import React from 'react'
import { Box, Button, Container, FormControl, Grid, Typography, TextareaAutosize, capitalize } from "@mui/material";
import ArrowLeft from "../../components/icons/ArrowLeft";
import styles from './HeroSectionInner.module.scss'
import { Link } from 'react-router-dom';

function HeroSectionInner({ title, description }) {
   return (
      <>
         <Box className={`${styles.heroSectionInner} heroSectionInner`} pt={5}>
            <Container className={styles.wrapper}>
               <Grid>
                  <Link className="textJBM" to="/" lineHeight="1.313">
                     <ArrowLeft /> Back to tools
                  </Link>
                  <Grid container mt={3} mb={6} spacing={4} sx={{ justifyContent: "space-between" }} alignItems={'center'} className={`${styles.upperGrid} csMargin`}>
                     <Grid item className={`${styles.leftArea}`} md={5}>
                        <Typography variant="h1" lineHeight="1.2" className="fontGruffy noWordBreak inner_Banner_Title" color={"#fff"} fontWeight={700} mb={3.1} textTransform={"capitalize"}>
                           {title}
                        </Typography>
                     </Grid>
                     <Grid item className={`${styles.rightArea}`} display={"flex"} justifyContent={"flex-end"} spacing={4} md={7}>
                        <Typography fontSize={"18px"} variant="body1" color={"#fff"} maxWidth={"700px"} className="noWordBreak">
                           {description}
                        </Typography>
                        {/* 
                                    <Button variant="contained" className={`${styles.btnFilled} bgLightGreen`}>
                                        Sign up, it’s free
                                    </Button>
                                    <Button variant="outlined" spacing={2} className={`${styles.btnOutlined} textCoral`}>
                                        <VideoCam /> View a brief demo
                                    </Button> */}
                     </Grid>
                  </Grid>
               </Grid>
            </Container>
         </Box>
      </>
   )
}

export default HeroSectionInner