import React, { useState } from "react";
import { Box, Button, Container, FormControl, Grid, Link, Typography, TextareaAutosize, TextField } from "@mui/material";
import styles from "./Email.module.scss";
import ArrowLeft from "../../components/icons/ArrowLeft";
import Alert from "../../components/icons/Alert"
import VideoCam from "../../components/icons/VideoCam";
import EmailTool from "../../components/table/EmailTool";
import arrowLeftIcon from "../../images/arrowLeft.svg";
import deleteIcon from "../../images/delete.svg";
import dustbinIcon from "../../images/dustbin.svg";

import HeroSectionInner from "../../components/Hero Section/HeroSectionInner";
import EmailPreviewContent from "../../components/Email Preview Content/EmailPreviewContent";

function EmailPreviewTool() {
    const [firstNameText, setFirstName] = useState('');
    const [emailText, setEmail] = useState('');
    const [subjectText, setSubject] = useState('');
    const [preheaderText, setPreheader] = useState('');
    // const [emailBodyText, setEmailBody] = useState('');
    const [text, setText] = useState('');
    const [optText, setOptText] = useState('');
    const [hasOptText, setHasOptText] = useState(false);
    const [emailBodyText, setEmailBodyText] = useState('');
    const [lengthSubjectLine, setlengthSubjectLine] = useState(0);
    const [lengthPreheaderChange, setlengthPreheaderChange] = useState(0);
    const [errorMessageSubjectLine, setErrorMessageSubjectLine] = useState('');
    const [errorMessagePreheader, setErrorMessagePreheader] = useState('');

    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleSubjectChange = (event) => {
        var lengthSubjectLine = event.target.value.length;
        setlengthSubjectLine(lengthSubjectLine);
        setSubject(event.target.value);

        if (lengthSubjectLine >= 60) {
            setErrorMessageSubjectLine('The character limit exceeds 60.');
            return;
        } else {
            setErrorMessageSubjectLine('');
        }
    };
    const subjectLinelengthColor = () => (
        lengthSubjectLine >= 60 && '#990000'
    )

    const handlePreheaderChange = (event) => {
        var lengthPreheaderChange = event.target.value.length;
        setlengthPreheaderChange(lengthPreheaderChange);
        setPreheader(event.target.value);

        if (lengthPreheaderChange >= 40) {
            setErrorMessagePreheader('The character limit exceeds 40.');
            return;
        } else {
            setErrorMessagePreheader('');
        }

    };
    const preheaderlengthColor = () => (
        lengthPreheaderChange >= 40 && '#990000'
    )

    const handleEmailBodyChange = (event) => {
        setEmailBodyText(event.target.value);
    };

    const smsPreviewContent = {
        title: 'Email preview tool (for Gmail)',
        description: (
            <>
                {/* <strong>Why it matters: </strong> */}
                Gmail is the most widely used email client. Previewing your email ensures that your message appears exactly as intended, avoiding formatting issues that could affect readability and engagement.
            </>
        )
    };


    return (
        <>
            <Box className={` ${styles.emailpreviewWrap} mainContent `}>
                <HeroSectionInner
                    title={smsPreviewContent.title}
                    description={smsPreviewContent.description}
                />
                <Box className={`${styles.emailToolWrap}`}>
                    <Container className={styles.wrapper}>
                        <Grid>
                            <Grid container className={`${styles.workingArea} csMargin`} spacing={6}>
                                <Grid item className={`${styles.leftArea}`} sm={12}>
                                    <Box className={`${styles.alertBoxWrapper}`}>
                                        <Box className={styles.alertBox} display="flex" alignItems="center" gap={1.25} mb={4}>
                                            <Alert />
                                            <Typography variant="subtitle2" lineHeight={1.34}>
                                            This is a preview, not a promise. Hopefully, you will get a good feel for how your messages will appear.
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Typography variant="subtitle1" fontWeight={700} lineHeight={3} mb={1}>
                                        Email Content
                                    </Typography>
                                </Grid>
                                <Grid item className={`${styles.leftArea}`} sm={5}>
                                    <FormControl className={`${styles.messageBoxes}`} fullWidth>
                                        <form>
                                            <Box className={styles.inputFields}>
                                                <label for="text-area">From Name</label>
                                                <TextField id="" onChange={handleFirstNameChange} label="" variant="outlined" InputLabelProps={{
                                                    shrink: true
                                                }} inputProps={{
                                                    placeholder: 'Name'
                                                }} />
                                            </Box>
                                            <Box className={styles.inputFields}>
                                                <label for="text-area">From email</label>
                                                <TextField id="" onChange={handleEmailChange} label="" variant="outlined" InputLabelProps={{
                                                    shrink: true
                                                }} inputProps={{
                                                    placeholder: 'email@email.com'
                                                    
                                                }} />
                                            </Box>
                                            <Box className={styles.inputFields}>
                                                <label for="text-area">Subject line</label>
                                                <TextField id="subjectLine-text-area" onChange={handleSubjectChange} label="" variant="outlined" InputLabelProps={{
                                                    shrink: true
                                                }} inputProps={{
                                                    placeholder: 'Subject line',
                                                    maxLength: 60,
                                                }} />
                                                <span className={styles.numberFieldText} style={{ color: subjectLinelengthColor() }}>{lengthSubjectLine}/60</span>
                                                {errorMessageSubjectLine && <span className={styles.errorMessageSubjectLine} style={{ color: subjectLinelengthColor() }}>{errorMessageSubjectLine}</span>}
                                            </Box>
                                            <Box className={styles.inputFields}>
                                                <label for="text-area">Preview text</label>
                                                <TextField id="preheader-text-area" onChange={handlePreheaderChange} label="" variant="outlined" InputLabelProps={{
                                                    shrink: true
                                                }} inputProps={{
                                                    placeholder: 'Add some text',
                                                    maxLength: 40,
                                                }} />
                                                <span className={styles.numberFieldText} style={{ color: preheaderlengthColor() }}>{lengthPreheaderChange}/40</span>
                                                {errorMessagePreheader && <span className={styles.errorMessagePreheader} style={{ color:  preheaderlengthColor() }}>{errorMessagePreheader}</span>}
                                            </Box>
                                        </form>
                                    </FormControl>
                                </Grid>
                                <Grid item className={`${styles.rightArea}`} sm={7}>
                                    <Box className={styles.innerRight}>
                                        <label htmlFor="text-area">Email body</label>
                                        <TextareaAutosize
                                            id="text-area"
                                            name="text-area"
                                            aria-label="empty textarea"
                                            minRows={3}
                                            value={emailBodyText}
                                            onChange={handleEmailBodyChange}
                                            placeholder="Add your message"
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
                {/* Table section */}
                <Box className={` ${styles.emailTableSection} emailTableSection`}>
                    <Container className={styles.wrapper}>
                        {/* <Grid container maxWidth={ 1200 } className={ `${ styles.workingArea } csMargin` } > */}
                        <Box className={`${styles.tableSecContent}`} >
                            <EmailTool
                                firstNameText={firstNameText}
                                emailText={emailText}
                                subjectText={subjectText}
                                preheaderText={preheaderText}
                            />
                        </Box>
                        {/* </Grid> */}
                    </Container>
                </Box>
                {/* table section closed */}
                {/* email Letter section */}
                <Box className={styles.emailLetterSec}>
                    <Container className={styles.wrapper}>
                        <Box className={`${styles.emailLetterContent}`} >
                            <Box className={styles.iconWrap}>
                                <img src={arrowLeftIcon} alt='arrow Left icon' />
                                <img src={deleteIcon} alt='delete icon' />
                                <img src={dustbinIcon} alt='dustbin icon' />
                            </Box>
                            <Box className={styles.secHeadingTitle}>
                                <h5 className={styles.secTitle}>{subjectText || '<add subject line>'}</h5>
                            </Box>
                            <Box className={styles.emailContent}>
                                <Box className={styles.emailThumb}>
                                    <span className={styles.thumbBox}></span>
                                </Box>
                                <Box className={styles.emailwriteContent}>
                                    <EmailPreviewContent
                                        firstNameText={firstNameText}
                                        emailText={emailText}
                                        subjectText={subjectText}
                                        preheaderText={preheaderText}
                                    />
                                    <Box className={styles.letterText}>
                                        <p>
                                            {emailBodyText ? (
                                                emailBodyText
                                            ) : (
                                                "<add your email body>"
                                            )}
                                        </p>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Container>
                </Box>
                {/* email Letter section closed */}


            </Box>
        </>
    );
}

export default EmailPreviewTool;
