import React from 'react'
import styles from "./Footer.module.scss"
import { Box, Container, Grid, Typography } from '@mui/material'
import LogoWhite from "../icons/LogoWhite"
import ArrowRight from '../icons/ArrowRight'
import LogoGreen from '../icons/LogoGreen'
import { Link } from 'react-router-dom'


function Footer() {
  const yearUpdate = () =>{
    const date = new Date(); 
    const year = date.getFullYear(); 
    return year; 
  }
  return (
    <>
      <Box id="footer">
        <Box className={styles.footerMain}>
          <Box className={styles.footerTop}>
            <Container fixed>
              <Grid container className={styles.inner}>
                <Grid item md={6}>
                  <Box className={`${styles.footerContentBox}`}>
                    <Typography className='textJBM' mb={4}>ABOUT US</Typography>
                    <Typography variant='h6' className={``} fontWeight={400} lineHeight={1.33} color="#fff">
                    We're at the forefront of digital innovation, spearheading a revolution in marketing leadership and practical management tools. We're not just creating software; we're crafting the future we envision. If this sounds like something worth following, then let's stay in touch.
                    </Typography>
                    {/* <Typography variant='h6' className={``} fontWeight={400} lineHeight={1.33} color="#fff">
                      Sound cool?
                    </Typography>
                    <Typography variant='h6' className={``} fontWeight={400} lineHeight={1.33} color="#fff">
                      <Link className={`${styles.footerLink}`} href={"#"}>Let’s keep in touch <ArrowRight /></Link>
                    </Typography> */}
                  </Box>
                </Grid>
                <Grid item md={6} className={styles.secondColm}>
                  <Box className={`${styles.footerContentBox}`}>
                    <Typography className='textJBM' mb={4}>WORK WITH US</Typography>
                    <Typography variant='h6' className={``} fontWeight={400} lineHeight={1.33} color="#fff">
                      Love our work?
                      Jimbo is our tiny little branding and growth agency, which partners with developing businesses to create digital products that drive growth.
                      We offer a design, marketing, and software team for small to medium-sized businesses
                      that will protect your bottom line while boosting your earning potential.
                    </Typography>
                    <Typography variant='h6' className={``} fontWeight={400} lineHeight={1.33} color="#fff">
                      <Link className={`${styles.footerLink}`} href="#">Check us out <ArrowRight /></Link>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
          <Box className={styles.footerBottom}>
            <Container fixed>
              <Grid className={styles.inner} container justifyContent={'space-between'} alignItems={'center'} >
                <Grid item>
                  <Typography variant='h6' lineHeight={1.55} color={'#fff'} fontWeight={400}>
                    © {yearUpdate()} Marketing Preview Tools. All Rights Reserved.
                  </Typography>
                </Grid>
                <Grid item display={'flex'}>
                  <Link to={'/'}><LogoGreen /></Link>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default Footer