import React from 'react'

export default function Chat_Message_Icon() {
   return (
      <>
         <svg xmlns="http://www.w3.org/2000/svg" width="42" height="37" viewBox="0 0 42 37" fill="none">
            <path d="M21.0011 0C9.36154 0 0.00215744 7.67612 0.00215744 17.1441C0.00215744 21.0691 1.64108 24.6653 4.35752 27.5542C3.13347 30.81 0.581665 33.5613 0.540507 33.5918C-0.00467482 34.1687 -0.148729 35.0136 0.159958 35.7348C0.406825 36.5302 1.11178 37 1.90367 37C6.9653 37 10.8844 34.8776 13.3539 33.1879C15.667 33.9297 18.306 34.3624 21.0011 34.3624C32.6406 34.3624 42 26.6863 42 17.2925C42 7.89866 32.6406 0 21.0011 0ZM21.0011 31.7249C18.6756 31.7249 16.3716 31.3746 14.1491 30.674C13.3657 30.4211 12.5093 30.5536 11.8384 31.0315C10.0192 32.3709 7.01963 33.9403 3.38454 34.3005C4.29825 33.0542 5.83345 30.9623 6.73811 28.5613L6.74872 28.5333C7.10128 27.6044 6.89615 26.6293 6.21943 25.8281C3.83893 23.2435 2.56714 20.3174 2.56714 17.2183C2.56714 9.22321 10.8399 2.71174 20.9319 2.71174C31.098 2.71174 39.2967 9.22321 39.2967 17.2183C39.2967 25.2134 31.1672 31.7249 21.0011 31.7249ZM22.3181 19.8559H11.7816C11.0572 19.8559 10.4646 20.4492 10.4646 21.1005C10.3905 21.9 11.0573 22.4934 11.7816 22.4934H22.3181C23.0426 22.4934 23.5611 21.8996 23.5611 21.1746C23.5611 20.4493 23.0425 19.8559 22.3181 19.8559ZM30.2205 11.9432H11.7816C11.0572 11.9432 10.4646 12.5366 10.4646 13.1878C10.3905 13.9873 11.0573 14.5808 11.7816 14.5808H30.2205C30.945 14.5808 31.4635 13.987 31.4635 13.262C31.4635 12.5366 30.9449 11.9432 30.2205 11.9432Z" fill="#342354" />
         </svg>
      </>
   )
}
