import React, { useEffect, useState } from "react";
import { Box, Container, FormControl, Grid, Typography, TextField, Switch, TextareaAutosize } from "@mui/material";
import { Link } from 'react-router-dom'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import styles from "../pages/searchAdPreview/SearchAdPreview.module.scss";

export default function BusinessForm(props) {
   const [firstNameText3, setFirstName3] = useState('Acme');
   const [firstEmailText3, setFirstEmail3] = useState('http://www.website.com');
   const [firstheadlineText3, setFirstheadline3] = useState('We Are Better Than Everyone');
   const [secondheadlineText3, setSecondheadline3] = useState('Compare And See For Yourself');
   const [description3, setDescription3] = useState('Elevate your success with our innovative solutions- Experience excellence today!');
   const [firstsitelink3, setFirstSitelink3] = useState('About Us');
   const [secondsitelink3, setSecondSitelink3] = useState('How It Works');
   const [thirdsitelink3, setThirdSitelink3] = useState('Free Trial');
   const [fourthsitelink3, setfourthSitelink3] = useState('Free Trial');
   const [errorMessageName3, setErrorMessageName3] = useState('');
   const [lengthName3, setlengthName3] = useState(0);
   const [errorMessageEmail3, setErrorMessageEmail3] = useState('');
   const [lengthEmail3, setlengthEmail3] = useState(0);
   const [errorMessageHeadlineone3, setErrorMessageHeadlineone3] = useState('');
   const [lengthHeadlineOne3, setlengthHeadlineOne3] = useState(0);
   const [errorMessageHeadlinetwo3, setErrorMessageHeadlinetwo3] = useState('');
   const [lengthHeadlineTwo3, setlengthHeadlineTwo3] = useState(0);
   const [errorMessageDescription3, setErrorMessageDescription3] = useState('');
   const [lengthDescription3, setlengthDescription3] = useState(0);
   const [errorMessageFirstSite3, setErrorMessageFirstSite3] = useState('');
   const [lengthFirstSite3, setlengthFirstSite3] = useState(0);
   const [errorMessageSecondSite3, setErrorMessageSecondSite3] = useState('');
   const [lengthSecondSite3, setlengthSecondSite3] = useState(0);
   const [errorMessageThirdSite3, setErrorMessageThirdSite3] = useState('');
   const [lengthThirdSite3, setlengthThirdSite3] = useState(0);
   const [errorMessageFourthSite3, setErrorMessageFourthSite3] = useState('');
   const [lengthFourthSite3, setlengthFourthSite3] = useState(0);

   useEffect(()=>{
      if(props.hidename3 == 'inputfieldtitle'){
         setFirstName3('')
      }
      else{
        setFirstName3(props.hidename3)
      }
   },[props.hidename3])

   useEffect(()=>{
      if(props.hideemail3 == 'inputfieldtitle'){
         setFirstEmail3('')
      }
      else{
        setFirstEmail3(props.hideemail3)
      }
   },[props.hideemail3])

   useEffect(()=>{
      if(props.hidefirsthead3 == 'inputfieldtitle'){
         setFirstheadline3('')
      }
      else{
         setFirstheadline3(props.hidefirsthead3)
      }
   },[props.hidefirsthead3])

   useEffect(()=>{
      if(props.hidesecondhead3 == 'inputfieldtitle'){
         setSecondheadline3('')
      }
      else{
         setSecondheadline3(props.hidesecondhead3)
      }
   },[props.hidesecondhead3])

   useEffect(()=>{
      if(props.hidedescription3 == 'inputfieldtitle'){
         setDescription3('')
      }
      else{
         setDescription3(props.hidedescription3)
      }
   },[props.hidedescription3])

   useEffect(()=>{
      if(props.hidesiteone3 == 'inputfieldtitle'){
         setFirstSitelink3('')
      }
      else{
         setFirstSitelink3(props.hidesiteone3)
      }
   },[props.hidesiteone3])

   useEffect(()=>{
      if(props.hidesitetwo3 == 'inputfieldtitle'){
         setSecondSitelink3('')
      }
      else{
         setSecondSitelink3(props.hidesitetwo3)
      }
   },[props.hidesitetwo3])

   useEffect(()=>{
      if(props.hidesitethree3 == 'inputfieldtitle'){
         setThirdSitelink3('')
      }
      else{
         setThirdSitelink3(props.hidesitethree3)
      }
   },[props.hidesitethree3])

   useEffect(()=>{
      if(props.hidesitefour3 == 'inputfieldtitle'){
         setfourthSitelink3('')
      }
      else{
         setfourthSitelink3(props.hidesitefour3)
      }
   },[props.hidesitefour3])

   const handleFirstNameChange3 = (event) => {
      setFirstName3(event.target.value);
      props.onFirstNameChange3(event.target.value);
      var lengthName3 = event.target.value.length;
      setlengthName3(lengthName3);
      setFirstName3(event.target.value)

      if (lengthName3 >= 25) {
         setErrorMessageName3('The character limit exceeds 25.');
         return;
      } else {
         setErrorMessageName3('');
      }
   };
   const NamelengthColor3 = () => (
      lengthName3 >= 25 && '#990000' 
   )

   const handleFirstEmailChange3 = (event) => {
      setFirstEmail3(event.target.value);
      props.onFirstEmailChange3(event.target.value);
      var lengthEmail3 = event.target.value.length;
      setlengthEmail3(lengthEmail3);
      setFirstEmail3(event.target.value)

      if (lengthEmail3 >= 30) {
         setErrorMessageEmail3('The character limit exceeds 30.');
         return;
      } else {
         setErrorMessageEmail3('');
      }
   };
   const EmaillengthColor3 = () => (
      lengthEmail3 >= 30 && '#990000'
   )

   const handleFirstHeadlineChange3 = (event) => {
      setFirstheadline3(event.target.value);
      props.onFirstHeadlineChange3(event.target.value);
      var lengthHeadlineOne3 = event.target.value.length;
      setlengthHeadlineOne3(lengthHeadlineOne3);
      setFirstheadline3(event.target.value)

      if (lengthHeadlineOne3 >= 30) {
         setErrorMessageHeadlineone3('The character limit exceeds 30.');
         return;
      } else {
         setErrorMessageHeadlineone3('');
      }
   };
   const HeadlineOnelengthColor3 = () => (
      lengthHeadlineOne3 >= 30 && '#990000'
   )
 
   const handleSecondHeadlineChange3 = (event) => {
      setSecondheadline3(event.target.value);
      props.onSecondHeadlineChange3(event.target.value);
      var lengthHeadlineTwo3 = event.target.value.length;
      setlengthHeadlineTwo3(lengthHeadlineTwo3);
      setSecondheadline3(event.target.value)

      if (lengthHeadlineTwo3 >= 30) {
         setErrorMessageHeadlinetwo3('The character limit exceeds 30.');
         return;
      } else {
         setErrorMessageHeadlinetwo3('');
      }
   };
   const SecondHeadlinelengthColor3 = () => (
      lengthHeadlineTwo3 >= 30 && '#990000'
   )

   const handleDescriptionChange3 = (event) => {
      setDescription3(event.target.value);
      props.onDescriptionChange3(event.target.value);
      var lengthDescription3 = event.target.value.length;
      setlengthDescription3(lengthDescription3);
      setDescription3(event.target.value)

      if (lengthDescription3 >= 90) {
         setErrorMessageDescription3('The character limit exceeds 90.');
         return;
      } else {
         setErrorMessageDescription3('');
      }
   };
   const DescriptionlengthColor3 = () => (
      lengthDescription3 >= 90 && '#990000'
   )

   const handleFirstSitelinkChange3 = (event) => {
      setFirstSitelink3(event.target.value);
      props.onFirstSitelinkChange3(event.target.value);
      var lengthFirstSite3 = event.target.value.length;
      setlengthFirstSite3(lengthFirstSite3);
      setFirstSitelink3(event.target.value)

      if (lengthFirstSite3 >= 25) {
         setErrorMessageFirstSite3('The character limit exceeds 25.');
         return;
      } else {
         setErrorMessageFirstSite3('');
      }
   };
   const FirstSitelinklengthColor3 = () => (
      lengthFirstSite3 >= 25 && '#990000'
   )

   const handleSecondSitelinkChange3 = (event) => {
      setSecondSitelink3(event.target.value);
      props.onSecondSitelinkChange3(event.target.value);
      var lengthSecondSite3 = event.target.value.length;
      setlengthSecondSite3(lengthSecondSite3);
      setSecondSitelink3(event.target.value)

      if (lengthSecondSite3 >= 25) {
         setErrorMessageSecondSite3('The character limit exceeds 25.');
         return;
      } else {
         setErrorMessageSecondSite3('');
      }
   };
   const SecondSitelinklengthColor3 = () => (
      lengthSecondSite3 >= 25 && '#990000'
   )

   const handleThirdSitelinkChange3 = (event) => {
      setThirdSitelink3(event.target.value);
      props.onThirdSitelinkChange3(event.target.value);
      var lengthThirdSite3 = event.target.value.length;
      setlengthThirdSite3(lengthThirdSite3);
      setThirdSitelink3(event.target.value)

      if (lengthThirdSite3 >= 25) {
         setErrorMessageThirdSite3('The character limit exceeds 25.');
         return;
      } else {
         setErrorMessageThirdSite3('');
      }
   };
   const ThirdSitelinklengthColor3 = () => (
      lengthThirdSite3 >= 25 && '#990000'
   )

   const handlefourthSitelinkChange3 = (event) => {
      setfourthSitelink3(event.target.value);
      props.onfourthSitelinkChange3(event.target.value);
      var lengthFourthSite3 = event.target.value.length;
      setlengthFourthSite3(lengthFourthSite3);
      setfourthSitelink3(event.target.value)

      if (lengthFourthSite3 >= 25) {
         setErrorMessageFourthSite3('The character limit exceeds 25.');
         return;
      } else {
         setErrorMessageFourthSite3('');
      }
   };
   const fourthSitelinklengthColor3 = () => (
      lengthFourthSite3 >= 25 && '#990000'
   )

   const [minRows, setMinRows] = useState(window.innerWidth < 767 ? 3 : 2);
   useEffect(() => {
      const handleResize = () => {
         setMinRows(window.innerWidth < 1300 ? 3 : 2);
      };

      window.addEventListener('resize', handleResize);
      // Clean up the event listener on component unmount
      return () => {
         window.removeEventListener('resize', handleResize);
      };
   }, []);


   return (
      <FormControl className={`${styles.messageBoxes}`} fullWidth>
         <form>
            <Box className={`${styles.textSMS_wrapper}`}>
               <Typography variant="subtitle1" className={`${styles.textSMS}`} fontWeight={700} lineHeight={3} mb={1}>
                  Business Info
               </Typography>
            </Box>
            <Box className={styles.inputFields}>
                    <label htmlFor="text-area">Business Name</label>
                    <TextField 
                        id=""
                        value={firstNameText3}
                        onChange={handleFirstNameChange3}
                        label=""
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ placeholder: 'Business Name' ,
                        maxLength: 25,
                        }}
                        
                    />
                    <span className={styles.numberFieldText} style={{ color: NamelengthColor3()}}>{lengthName3}/25</span>
                    {errorMessageName3 && <span className={styles.errorMessageName} style={{ color: NamelengthColor3() }}>{errorMessageName3}</span>}
            </Box>
            <Box className={styles.inputFields}>
               <label for="text-area">Business Website</label>
               <TextField 
                  id="" 
                  value={firstEmailText3} 
                  onChange={handleFirstEmailChange3} 
                  label="" variant="outlined" 
                  InputLabelProps={{
                  shrink: true
               }} inputProps={{
                  placeholder: 'www.website.com',
                  maxLength: 30,
               }} />
               <span className={styles.numberFieldText} style={{ color: EmaillengthColor3() }}>{lengthEmail3}/30</span>
               {errorMessageEmail3 && <span className={styles.errorMessageEmail} style={{ color: EmaillengthColor3() }}>{errorMessageEmail3}</span>}
            </Box>
            <Box className={`${styles.textSMS_wrapper}`}>
               <Typography variant="subtitle1" className={`${styles.textSMS}`} fontWeight={700} lineHeight={3} mb={1}>
                  Ad Headlines
               </Typography>
            </Box>

            <Box className={styles.inputFields}>
               <label for="text-area">Headline 1</label>
               <TextField 
                  id="subjectLine-text-area" 
                  label="" 
                  value={firstheadlineText3} 
                  onChange={handleFirstHeadlineChange3} 
                  variant="outlined" 
                  InputLabelProps={{
                  shrink: true
               }} inputProps={{
                  placeholder: '',
                  maxLength: 30,
               }} />
               <span className={styles.numberFieldText} style={{ color: HeadlineOnelengthColor3() }}>{lengthHeadlineOne3}/30</span>
               {errorMessageHeadlineone3 && <span className={styles.errorMessageHeadlineone} style={{ color: HeadlineOnelengthColor3() }}>{errorMessageHeadlineone3}</span>}
            </Box>
            <Box className={styles.inputFields}>
               <label for="text-area">Headline 2</label>
               <TextField 
                  id="preheader-text-area" 
                  label="" 
                  value={secondheadlineText3} 
                  onChange={handleSecondHeadlineChange3} 
                  variant="outlined" 
                  InputLabelProps={{
                  shrink: true
               }} inputProps={{
                  placeholder: '',
                  maxLength: 30,
               }} />
               <span className={styles.numberFieldText} style={{ color: SecondHeadlinelengthColor3() }}>{lengthHeadlineTwo3}/30</span>
               {errorMessageHeadlinetwo3 && <span className={styles.errorMessageHeadlinetwo} style={{ color: SecondHeadlinelengthColor3() }}>{errorMessageHeadlinetwo3}</span>}
            </Box>
            <Box className={`${styles.textSMS_wrapper}`}>
               <Typography variant="subtitle1" className={`${styles.textSMS}`} fontWeight={700} lineHeight={3} mb={1}>
                  Ad Description
               </Typography>
            </Box>

            <Box className={styles.inputFields}>
               <label for="text-area">Description 1</label>
               <TextareaAutosize
                  id="text-area-description-1"
                  value={description3} 
                  onChange={handleDescriptionChange3} 
                  name="text-area"
                  aria-label="empty textarea"
                  minRows={minRows}
                  placeholder="Add a description"
                  maxLength={90}
               />
               <span className={styles.numberFieldText} style={{ color: DescriptionlengthColor3() }}>{lengthDescription3}/90</span>
               {errorMessageDescription3 && <span className={styles.errorMessageDescription} style={{ color: DescriptionlengthColor3() }}>{errorMessageDescription3}</span>}
            </Box>
            <Box className={`${styles.textSMS_wrapper}`}>
               <Typography variant="subtitle1" className={`${styles.textSMS}`} fontWeight={700} lineHeight={3} mb={1} display="inline-flex">
                  Sitelinks
               </Typography>
            </Box>
            <Box className={`${styles.inputFieldsContainer}`}>
               <Box className={styles.inputFields}>
                  <label for="text-area">Sitelink 1</label>
                  <TextField 
                     id="preheader-text-area" 
                     label="" variant="outlined" 
                     value={firstsitelink3} 
                     onChange={handleFirstSitelinkChange3}
                     InputLabelProps={{
                     shrink: true
                  }} inputProps={{
                     placeholder: '',
                     maxLength: 25,
                  }} />
                  <span className={styles.numberFieldText} style={{ color: FirstSitelinklengthColor3() }}>{lengthFirstSite3}/25</span>
                  {errorMessageFirstSite3 && <span className={styles.errorMessageFirstSite} style={{ color: FirstSitelinklengthColor3() }}>{errorMessageFirstSite3}</span>}
               </Box>
               <Box className={styles.inputFields}>
                  <label for="text-area">Sitelink 2</label>
                  <TextField 
                     id="preheader-text-area" 
                     label="" 
                     value={secondsitelink3} 
                     onChange={handleSecondSitelinkChange3}
                     variant="outlined" 
                     InputLabelProps={{
                     shrink: true
                  }} inputProps={{
                     placeholder: '',
                     maxLength: 25,
                  }} />
                  <span className={styles.numberFieldText} style={{ color: SecondSitelinklengthColor3() }}>{lengthSecondSite3}/25</span>
                  {errorMessageSecondSite3 && <span className={styles.errorMessageSecondSite} style={{ color: SecondSitelinklengthColor3() }}>{errorMessageSecondSite3}</span>}
               </Box>
               <Box className={styles.inputFields}>
                  <label for="text-area">Sitelink 3</label>
                  <TextField 
                     id="preheader-text-area" 
                     label="" 
                     value={thirdsitelink3} 
                     onChange={handleThirdSitelinkChange3}
                     variant="outlined" 
                     InputLabelProps={{
                     shrink: true
                  }} inputProps={{
                     placeholder: '',
                     maxLength: 25,
                  }} />
                  <span className={styles.numberFieldText} style={{ color: ThirdSitelinklengthColor3() }}>{lengthThirdSite3}/25</span>
                  {errorMessageThirdSite3 && <span className={styles.errorMessageThirdSite} style={{ color: ThirdSitelinklengthColor3() }}>{errorMessageThirdSite3}</span>}
               </Box>
               <Box className={styles.inputFields}>
                  <label for="text-area">Sitelink 4</label>
                  <TextField 
                     id="preheader-text-area" 
                     label=""
                     value={fourthsitelink3} 
                     onChange={handlefourthSitelinkChange3} 
                     variant="outlined" 
                     InputLabelProps={{
                     shrink: true
                  }} inputProps={{
                     placeholder: '',
                     maxLength: 25,
                  }} />
                  <span className={styles.numberFieldText} style={{ color: fourthSitelinklengthColor3() }}>{lengthFourthSite3}/25</span>
                  {errorMessageFourthSite3 && <span className={styles.errorMessageFourthSite} style={{ color: fourthSitelinklengthColor3() }}>{errorMessageFourthSite3}</span>}
               </Box>
            </Box>
         </form>
      </FormControl>
   )
}





