import React, { useEffect, useState } from "react";
import { Box, Container, FormControl, Grid, Typography, TextField, Switch, TextareaAutosize } from "@mui/material";
import { Link } from 'react-router-dom'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import styles from "../pages/searchAdPreview/SearchAdPreview.module.scss";

export default function BusinessForm(props) {
   
   const [firstNameText, setFirstName] = useState(props.businesstextpas);
   const [firstEmailText, setFirstEmail] = useState(props.businessemailpass);
   const [firstheadlineText, setFirstheadline] = useState(props.firstheadlinepass);
   const [secondheadlineText, setSecondheadline] = useState(props.secondheadlinepass);
   const [description, setDescription] = useState(props.addescriptionpass);
   const [firstsitelink, setFirstSitelink] = useState(props.sitelinkonepass ? props.sitelinkonepass.replace('.', '') : '');
   const [secondsitelink, setSecondSitelink] = useState(props.sitelinktwopass ? props.sitelinktwopass.replace('.', '') : '');
   const [thirdsitelink, setThirdSitelink] = useState(props.sitelinkthreepass ? props.sitelinkthreepass.replace('.', '') : '');
   const [fourthsitelink, setfourthSitelink] = useState(props.sitelinkfourpass ? props.sitelinkfourpass.replace('.', '') : '');
   const [errorMessageName, setErrorMessageName] = useState('');
   const [lengthName, setlengthName] = useState(0);
   const [errorMessageEmail, setErrorMessageEmail] = useState('');
   const [lengthEmail, setlengthEmail] = useState(0);
   const [errorMessageHeadlineone, setErrorMessageHeadlineone] = useState('');
   const [lengthHeadlineOne, setlengthHeadlineOne] = useState(0);
   const [errorMessageHeadlinetwo, setErrorMessageHeadlinetwo] = useState('');
   const [lengthHeadlineTwo, setlengthHeadlineTwo] = useState(0);
   const [errorMessageDescription, setErrorMessageDescription] = useState('');
   const [lengthDescription, setlengthDescription] = useState(0);
   const [errorMessageFirstSite, setErrorMessageFirstSite] = useState('');
   const [lengthFirstSite, setlengthFirstSite] = useState(0);
   const [errorMessageSecondSite, setErrorMessageSecondSite] = useState('');
   const [lengthSecondSite, setlengthSecondSite] = useState(0);
   const [errorMessageThirdSite, setErrorMessageThirdSite] = useState('');
   const [lengthThirdSite, setlengthThirdSite] = useState(0);
   const [errorMessageFourthSite, setErrorMessageFourthSite] = useState('');
   const [lengthFourthSite, setlengthFourthSite] = useState(0);

   const handleFirstNameChange = (event) => {
      setFirstName(event.target.value);
      props.onFirstNameChange(event.target.value);
      var lengthName = event.target.value.length;
      setlengthName(lengthName);
      setFirstName(event.target.value)

      if (lengthName >= 25) {
         setErrorMessageName('The character limit exceeds 25.');
         return;
      } else {
         setErrorMessageName('');
      }
   };
   const NamelengthColor = () => (
      lengthName >= 25 && '#990000' 
   )

   const handleFirstEmailChange = (event) => {
      setFirstEmail(event.target.value);
      props.onFirstEmailChange(event.target.value);
      var lengthEmail = event.target.value.length;
      setlengthEmail(lengthEmail);
      setFirstEmail(event.target.value)

      if (lengthEmail >= 30) {
         setErrorMessageEmail('The character limit exceeds 30.');
         return;
      } else {
         setErrorMessageEmail('');
      }
   };
   const EmaillengthColor = () => (
      lengthEmail >= 30 && '#990000'
   )

   const handleFirstHeadlineChange = (event) => {
      setFirstheadline(event.target.value);
      props.onFirstHeadlineChange(event.target.value);
      var lengthHeadlineOne = event.target.value.length;
      setlengthHeadlineOne(lengthHeadlineOne);
      setFirstheadline(event.target.value)

      if (lengthHeadlineOne >= 30) {
         setErrorMessageHeadlineone('The character limit exceeds 30.');
         return;
      } else {
         setErrorMessageHeadlineone('');
      }
   };
   const HeadlineOnelengthColor = () => (
      lengthHeadlineOne >= 30 && '#990000'
   )
 
   const handleSecondHeadlineChange = (event) => {
      setSecondheadline(event.target.value);
      props.onSecondHeadlineChange(event.target.value);
      var lengthHeadlineTwo = event.target.value.length;
      setlengthHeadlineTwo(lengthHeadlineTwo);
      setSecondheadline(event.target.value)

      if (lengthHeadlineTwo >= 30) {
         setErrorMessageHeadlinetwo('The character limit exceeds 30.');
         return;
      } else {
         setErrorMessageHeadlinetwo('');
      }
   };
   const SecondHeadlinelengthColor = () => (
      lengthHeadlineTwo >= 30 && '#990000'
   )

   const handleDescriptionChange = (event) => {
      setDescription(event.target.value);
      props.onDescriptionChange(event.target.value);
      var lengthDescription = event.target.value.length;
      setlengthDescription(lengthDescription);
      setDescription(event.target.value)

      if (lengthDescription >= 90) {
         setErrorMessageDescription('The character limit exceeds 90.');
         return;
      } else {
         setErrorMessageDescription('');
      }
   };
   const DescriptionlengthColor = () => (
      lengthDescription >= 90 && '#990000'
   )

   const handleFirstSitelinkChange = (event) => {
      setFirstSitelink(event.target.value);
      props.onFirstSitelinkChange(event.target.value);
      var lengthFirstSite = event.target.value.length;
      setlengthFirstSite(lengthFirstSite);
      setFirstSitelink(event.target.value)

      if (lengthFirstSite >= 25) {
         setErrorMessageFirstSite('The character limit exceeds 25.');
         return;
      } else {
         setErrorMessageFirstSite('');
      }
   };
   const FirstSitelinklengthColor = () => (
      lengthFirstSite >= 25 && '#990000'
   )

   const handleSecondSitelinkChange = (event) => {
      setSecondSitelink(event.target.value);
      props.onSecondSitelinkChange(event.target.value);
      var lengthSecondSite = event.target.value.length;
      setlengthSecondSite(lengthSecondSite);
      setSecondSitelink(event.target.value)

      if (lengthSecondSite >= 25) {
         setErrorMessageSecondSite('The character limit exceeds 25.');
         return;
      } else {
         setErrorMessageSecondSite('');
      }
   };
   const SecondSitelinklengthColor = () => (
      lengthSecondSite >= 25 && '#990000'
   )

   const handleThirdSitelinkChange = (event) => {
      setThirdSitelink(event.target.value);
      props.onThirdSitelinkChange(event.target.value);
      var lengthThirdSite = event.target.value.length;
      setlengthThirdSite(lengthThirdSite);
      setThirdSitelink(event.target.value)

      if (lengthThirdSite >= 25) {
         setErrorMessageThirdSite('The character limit exceeds 25.');
         return;
      } else {
         setErrorMessageThirdSite('');
      }
   };
   const ThirdSitelinklengthColor = () => (
      lengthThirdSite >= 25 && '#990000'
   )

   const handlefourthSitelinkChange = (event) => {
      setfourthSitelink(event.target.value);
      props.onfourthSitelinkChange(event.target.value);
      var lengthFourthSite = event.target.value.length;
      setlengthFourthSite(lengthFourthSite);
      setfourthSitelink(event.target.value)

      if (lengthFourthSite >= 25) {
         setErrorMessageFourthSite('The character limit exceeds 25.');
         return;
      } else {
         setErrorMessageFourthSite('');
      }
   };
   const fourthSitelinklengthColor = () => (
      lengthFourthSite >= 25 && '#990000'
   )

   const [minRows, setMinRows] = useState(window.innerWidth < 992 ? 3 : 2);
   useEffect(() => {
      const handleResize = () => {
         setMinRows(window.innerWidth < 1300 ? 3 : 2);
      };

      window.addEventListener('resize', handleResize);
      // Clean up the event listener on component unmount
      return () => {
         window.removeEventListener('resize', handleResize);
      };
   }, []);

   return (
      <FormControl className={`${styles.messageBoxes}`} fullWidth>
         <form>
            <Box className={`${styles.textSMS_wrapper}`}>
               <Typography variant="subtitle1" className={`${styles.textSMS}`} fontWeight={700} lineHeight={3} mb={1}>
                  Business Info
               </Typography>
            </Box>
            <Box className={styles.inputFields}>
                    <label htmlFor="text-area">Business Name</label>
                    <TextField 
                        id=""
                        value={firstNameText}
                        onChange={handleFirstNameChange}
                        label=""
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ placeholder: 'Business Name' ,
                        maxLength: 25,
                        }}
                        
                    />
                    <span className={styles.numberFieldText} style={{ color: NamelengthColor()}}>{lengthName}/25</span>
                    {errorMessageName && <span className={styles.errorMessageName} style={{ color: NamelengthColor() }}>{errorMessageName}</span>}
            </Box>
            <Box className={styles.inputFields}>
               <label for="text-area">Business Website</label>
               <TextField 
                  id="" 
                  value={firstEmailText} 
                  onChange={handleFirstEmailChange} 
                  label="" variant="outlined" 
                  InputLabelProps={{
                  shrink: true
               }} inputProps={{
                  placeholder: 'www.website.com',
                  maxLength: 30,
               }} />
               <span className={styles.numberFieldText} style={{ color: EmaillengthColor() }}>{lengthEmail}/30</span>
               {errorMessageEmail && <span className={styles.errorMessageEmail} style={{ color: EmaillengthColor() }}>{errorMessageEmail}</span>}
            </Box>
            <Box className={`${styles.textSMS_wrapper}`}>
               <Typography variant="subtitle1" className={`${styles.textSMS}`} fontWeight={700} lineHeight={3} mb={1}>
                  Ad Headlines
               </Typography>
            </Box>

            <Box className={styles.inputFields}>
               <label for="text-area">Headline 1</label>
               <TextField 
                  id="subjectLine-text-area" 
                  label="" value={firstheadlineText} 
                  onChange={handleFirstHeadlineChange} 
                  variant="outlined" 
                  InputLabelProps={{
                  shrink: true
               }} inputProps={{
                  placeholder: '',
                  maxLength: 30,
               }} />
               <span className={styles.numberFieldText} style={{ color: HeadlineOnelengthColor() }}>{lengthHeadlineOne}/30</span>
               {errorMessageHeadlineone && <span className={styles.errorMessageHeadlineone} style={{ color: HeadlineOnelengthColor() }}>{errorMessageHeadlineone}</span>}
            </Box>
            <Box className={styles.inputFields}>
               <label for="text-area">Headline 2</label>
               <TextField 
                  id="preheader-text-area" 
                  label="" 
                  value={secondheadlineText} 
                  onChange={handleSecondHeadlineChange} 
                  variant="outlined" 
                  InputLabelProps={{
                  shrink: true
               }} inputProps={{
                  placeholder: '',
                  maxLength: 30,
               }} />
               <span className={styles.numberFieldText} style={{ color: SecondHeadlinelengthColor() }}>{lengthHeadlineTwo}/30</span>
               {errorMessageHeadlinetwo && <span className={styles.errorMessageHeadlinetwo} style={{ color: SecondHeadlinelengthColor() }}>{errorMessageHeadlinetwo}</span>}
            </Box>
            <Box className={`${styles.textSMS_wrapper}`}>
               <Typography variant="subtitle1" className={`${styles.textSMS}`} fontWeight={700} lineHeight={3} mb={1}>
                  Ad Description
               </Typography>
            </Box>

            <Box className={styles.inputFields}>
               <label for="text-area">Description 1</label>
               <TextareaAutosize
                  id="text-area-description-1"
                  value={description} 
                  onChange={handleDescriptionChange} 
                  name="text-area"
                  aria-label="empty textarea"
                  minRows={minRows}
                  placeholder="Add a description"
                  maxLength={90}
               />
               <span className={styles.numberFieldText} style={{ color: DescriptionlengthColor() }}>{lengthDescription}/90</span>
               {errorMessageDescription && <span className={styles.errorMessageDescription} style={{ color: DescriptionlengthColor() }}>{errorMessageDescription}</span>}
            </Box>
            <Box className={`${styles.textSMS_wrapper}`}>
               <Typography variant="subtitle1" className={`${styles.textSMS}`} fontWeight={700} lineHeight={3} mb={1} display="inline-flex">
                  Sitelinks
               </Typography>
            </Box>
            <Box className={`${styles.inputFieldsContainer}`}>
               <Box className={styles.inputFields}>
                  <label for="text-area">Sitelink 1</label>
                  <TextField 
                     id="preheader-text-area" 
                     label="" variant="outlined" 
                     value={firstsitelink} 
                     onChange={handleFirstSitelinkChange}
                     InputLabelProps={{
                     shrink: true
                  }} inputProps={{
                     placeholder: '',
                     maxLength: 25,
                  }} />
                  <span className={styles.numberFieldText} style={{ color: FirstSitelinklengthColor() }}>{lengthFirstSite}/25</span>
                  {errorMessageFirstSite && <span className={styles.errorMessageFirstSite} style={{ color: FirstSitelinklengthColor() }}>{errorMessageFirstSite}</span>}
               </Box>
               <Box className={styles.inputFields}>
                  <label for="text-area">Sitelink 2</label>
                  <TextField 
                     id="preheader-text-area" 
                     label="" 
                     value={secondsitelink} 
                     onChange={handleSecondSitelinkChange}
                     variant="outlined" 
                     InputLabelProps={{
                     shrink: true
                  }} inputProps={{
                     placeholder: '',
                     maxLength: 25,
                  }} />
                  <span className={styles.numberFieldText} style={{ color: SecondSitelinklengthColor() }}>{lengthSecondSite}/25</span>
                  {errorMessageSecondSite && <span className={styles.errorMessageSecondSite} style={{ color: SecondSitelinklengthColor() }}>{errorMessageSecondSite}</span>}
               </Box>
               <Box className={styles.inputFields}>
                  <label for="text-area">Sitelink 3</label>
                  <TextField 
                     id="preheader-text-area" 
                     label="" 
                     value={thirdsitelink} 
                     onChange={handleThirdSitelinkChange}
                     variant="outlined" 
                     InputLabelProps={{
                     shrink: true
                  }} inputProps={{
                     placeholder: '',
                     maxLength: 25,
                  }} />
                  <span className={styles.numberFieldText} style={{ color: ThirdSitelinklengthColor() }}>{lengthThirdSite}/25</span>
                  {errorMessageThirdSite && <span className={styles.errorMessageThirdSite} style={{ color: ThirdSitelinklengthColor() }}>{errorMessageThirdSite}</span>}
               </Box>
               <Box className={styles.inputFields}>
                  <label for="text-area">Sitelink 4</label>
                  <TextField 
                     id="preheader-text-area" 
                     label=""
                     value={fourthsitelink} 
                     onChange={handlefourthSitelinkChange} 
                     variant="outlined" 
                     InputLabelProps={{
                     shrink: true
                  }} inputProps={{
                     placeholder: '',
                     maxLength: 25,
                  }} />
                  <span className={styles.numberFieldText} style={{ color: fourthSitelinklengthColor() }}>{lengthFourthSite}/25</span>
                  {errorMessageFourthSite && <span className={styles.errorMessageFourthSite} style={{ color: fourthSitelinklengthColor() }}>{errorMessageFourthSite}</span>}
               </Box>
            </Box>
         </form>
      </FormControl>
   )
}



