import React from 'react'

export default function Envelope_Icon() {
   return (
      <>
         <svg xmlns="http://www.w3.org/2000/svg" width="41" height="31" viewBox="0 0 41 31" fill="none">
            <path d="M0 5.16667C0 2.31289 2.29424 0 5.125 0H35.875C38.7018 0 41 2.31289 41 5.16667V25.8333C41 28.6831 38.7018 31 35.875 31H5.125C2.29424 31 0 28.6831 0 25.8333V5.16667ZM2.5625 5.16667V8.38776L18.2258 19.9643C19.5791 20.9654 21.4209 20.9654 22.7742 19.9643L38.4375 8.38776V5.16667C38.4375 3.73776 37.2924 2.58333 35.875 2.58333H5.05293C3.71002 2.58333 2.49043 3.73776 2.49043 5.16667H2.5625ZM2.5625 11.5927V25.8333C2.5625 27.2622 3.71002 28.4167 5.125 28.4167H35.875C37.2924 28.4167 38.4375 27.2622 38.4375 25.8333V11.5927L24.2877 22.0471C22.0295 23.7182 18.9705 23.7182 16.7123 22.0471L2.5625 11.5927Z" fill="#342354" />
         </svg>
      </>
   )
}
