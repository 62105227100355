import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styles from "./Email.module.scss"
import { Box } from '@mui/material';
import checkIcon from "../../images/check.svg";
import starIcon from "../../images/star.svg";
import rectangleIcon from "../../images/rectangle.svg";

function createData(name, subject, Preheader, time) {
    return { name, subject, Preheader, time };
}

/* const rows = [
    createData('<add name>', '<add subject line> ', '<add preview text>', '4:05 PM'),
    createData('Frozen yoghurt', `Preview next week's menu`, 'This message was sent from an email address that cannot accept email..', '4:05 PM'),
    createData('Frozen yoghurt', 'A new device has logged in to your Shopify account ', 'Hi Michael - I just wanted to quickly bring this..', '4:05 PM'),
]; */

export default function EmailTool({ firstNameText, emailText, subjectText, preheaderText }) {


    const defaultName = '<add name>';
    const defaultSubject = '<add subject line>';
    const defaultPreheader = '<add preview text>';

    const rows = [
        {
            name: firstNameText ? firstNameText : defaultName,
            subject: subjectText ? subjectText : defaultSubject,
            Preheader: preheaderText ? preheaderText : defaultPreheader,
            time: '4:05 PM'
        },
        { name: "Your Competitor", subject: "Preview next week's schedule", Preheader: "We have so much planned that you are going to love it.", time: '4:05 PM' },
        { name: "Another Competitor", subject: "A new device has logged in to your Faceblam account", Preheader: "Hi Chris - I just wanted to quickly bring this to your...", time: '4:05 PM' },
    ];


    return (
        <>
            <Box className={styles.emailTableSection}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={12}>Inbox Preview</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, index) => (
                                <TableRow
                                    key={row.name}
                                    className={`${styles.customTableRow} ${index === rows.length - 1 ? styles.readTableRow : ''}`}
                                >
                                    <TableCell align="left">

                                        <span className={` ${styles.iconsWrap} ${styles.showDesk} `}>
                                            <img src={checkIcon} alt='check icon' />
                                            <img src={starIcon} alt='check icon' />
                                            <img src={rectangleIcon} alt='check icon' />
                                        </span>
                                        <p className={`${styles.mobflex} ${styles.emailNameTitle}`}>
                                            <span className={styles.nameTitle}> {row.name}</span>
                                            <b className={styles.showMobile}>{row.time}</b>
                                        </p>

                                        <p className={styles.subjectLineContent}>
                                            <span className={styles.contentSpace}> <b>{row.subject}</b> </span>
                                            <span className="gray-b-text">{row.Preheader} </span>
                                        </p>  <b className={styles.showDesk}>{row.time}</b>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </>
    )
}
