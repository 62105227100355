import React from 'react'

export default function Phone_Icon() {
   return (
      <>
         <svg xmlns="http://www.w3.org/2000/svg" width="27" height="45" viewBox="0 0 27 45" fill="none">
            <path d="M21.6 0H5.4C2.41734 0 0 2.51807 0 5.625V39.375C0 42.4819 2.41734 45 5.4 45H21.6C24.5827 45 27 42.4819 27 39.375V5.625C27 2.51807 24.5784 0 21.6 0ZM10.8 2.8125H16.2V4.21875H10.8V2.8125ZM24.3 39.375C24.3 40.9254 23.0884 42.1875 21.6 42.1875H5.4C3.91163 42.1875 2.7 40.9254 2.7 39.375V5.625C2.7 4.07461 3.91163 2.8125 5.4 2.8125H8.1V4.21875C8.1 5.76914 9.31163 7.03125 10.8 7.03125H16.2C17.6884 7.03125 18.9 5.76914 18.9 4.21875V2.8125H21.6C23.0884 2.8125 24.3 4.07461 24.3 5.625V39.375ZM16.2 35.1562H10.8C10.0545 35.1562 9.45 35.7857 9.45 36.5625C9.45 37.3391 10.0545 37.9688 10.8 37.9688H16.2C16.9455 37.9688 17.55 37.3391 17.55 36.5625C17.55 35.7891 16.9425 35.1562 16.2 35.1562Z" fill="#342354" />
         </svg>
      </>
   )
}
