import React from 'react'

function LogoGreen() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="142" height="93" viewBox="0 0 142 93" fill="none">
            <mask id="path-1-outside-1_3_2" maskUnits="userSpaceOnUse" x="15" y="12" width="113" height="69" fill="black">
                <rect fill="white" x="15" y="12" width="113" height="69" />
                <path d="M16.0286 31.3647V14.4803C16.0286 12.8116 18.0832 12.444 18.7396 13.9712L21.6218 20.9003C22.2211 22.2579 22.6776 22.371 23.2769 20.8438L26.0735 14.056C26.7869 12.3874 28.6418 12.8965 28.813 14.1975C28.813 14.2823 28.813 14.3671 28.813 14.4803V31.3647C28.813 33.3162 26.045 33.2879 26.0735 31.4213V23.5306C26.1306 22.569 25.4172 22.767 25.2745 23.1912C24.7323 24.4922 24.3328 25.4538 23.7906 26.7265C23.2484 28.0557 21.6503 28.1123 21.0796 26.783L19.6242 23.276C19.3103 22.5407 18.7681 23.0781 18.7967 23.3892C18.7967 26.3022 18.7967 28.5082 18.7967 31.4213C18.7967 33.2879 16.0286 33.3162 16.0286 31.3647Z" />
                <path d="M39.6453 14.8197L42.0709 25.0012L43.5548 31.3647C43.8973 32.9768 41.2148 33.599 40.9009 31.7041L40.6441 30.3749C40.53 29.7809 40.1304 29.2153 39.36 29.2436H36.5063C35.9356 29.2153 35.2792 29.385 35.0224 30.29L34.5087 31.9869C34.1092 33.4576 31.6265 33.0334 31.9975 31.2233L35.907 14.8479C36.6204 12.3308 39.2458 12.6137 39.6453 14.8197ZM37.1912 20.7306C36.9058 22.2296 36.6204 23.7003 36.3351 25.1709C36.2209 25.68 36.3921 26.1891 37.077 26.1891H38.7036C39.3885 26.1891 39.5312 25.6517 39.417 25.1709L38.4468 20.7306C38.3041 19.9953 37.448 19.7973 37.1912 20.7306Z" />
                <path d="M46.6818 31.3647L46.6533 22.9649V14.7914C46.6533 13.7449 47.6521 12.953 48.394 13.0379H52.5604C55.1572 13.0379 56.6982 16.0641 56.7838 19.0903C56.8408 21.2397 56.3843 22.3144 55.2428 24.0396C54.8147 24.7184 54.3296 25.6235 55.043 26.8396L56.8123 30.8557C57.6399 32.5243 55.1286 33.7404 54.3011 31.9869L51.7043 26.585C51.1335 25.3689 49.4213 25.6234 49.4213 27.1224V31.4213C49.4499 33.2879 46.6818 33.3162 46.6818 31.3647ZM49.4213 21.1266C49.4213 21.8336 50.0491 22.4841 50.8482 22.4276H52.0182C53.445 22.4276 54.1869 20.7024 54.1299 19.0054C54.0728 17.3651 53.2738 15.753 51.6757 15.753H50.7911C50.1347 15.753 49.4499 16.2338 49.4213 17.1105V21.1266Z" />
                <path d="M60.1147 31.393V14.4803C60.1147 12.5288 62.9113 12.5854 62.8828 14.452V19.6559C62.8828 20.165 63.3965 20.5327 63.7674 19.7973L67.0491 13.8298C68.0765 12.1611 70.2167 13.6035 69.5033 14.9045L66.9065 19.7408C66.3928 20.7306 66.2216 21.3246 66.7067 22.4276L70.3879 30.8839C71.0443 32.4677 68.8184 33.7122 67.8767 32.0718L64.8233 25.1709C64.2526 23.9265 62.8543 25.0295 62.9399 27.2638L62.8828 31.4213C62.9113 33.3162 60.1147 33.3445 60.1147 31.393Z" />
                <path d="M75.2857 13.0096H80.6791C82.3343 12.9813 82.3057 15.6964 80.6791 15.753C79.6518 15.753 78.7101 15.753 77.6828 15.753C76.9979 15.753 76.3131 16.1206 76.3131 16.9974C76.3131 18.1004 76.3131 19.1186 76.3131 20.1933C76.2845 20.9286 76.627 21.4943 77.3404 21.466H79.6233C81.307 21.4377 81.2784 24.1528 79.6518 24.1811C78.8528 24.1811 78.0823 24.1811 77.2833 24.1811C76.7696 24.1811 76.2845 24.5204 76.3131 25.3689C76.3701 26.4154 76.3131 27.4618 76.3131 28.5365C76.3131 29.2436 76.4843 30.0638 77.5972 30.0638H80.6791C82.3343 30.0355 82.3057 32.7506 80.6506 32.8071C78.8528 32.8071 77.055 32.8071 75.2572 32.8071C73.6592 32.8071 73.545 31.8172 73.545 30.8274V22.2579C73.545 19.7125 73.545 17.1105 73.5735 14.5651C73.5735 13.2924 74.3155 12.9813 75.2857 13.0096Z" />
                <path d="M88.4139 31.393V22.173L88.3854 17.2519C88.3568 16.2338 87.7861 16.0641 87.0156 16.0924H85.7315C83.8195 16.1206 83.7624 13.0662 85.7315 13.0662H93.8644C95.8334 13.0662 95.8049 16.0924 93.8929 16.0641L92.2663 16.0924C91.4388 16.0641 91.1534 16.3186 91.1534 17.2519V31.4213C91.182 33.2879 88.4139 33.3445 88.4139 31.393Z" />
                <path d="M98.2929 31.3647V14.452C98.2929 12.5005 101.061 12.5571 101.061 14.4237V31.393C101.061 33.2879 98.2929 33.3162 98.2929 31.3647Z" />
                <path d="M114.243 31.4496C114.272 33.2314 112.36 33.2879 111.732 31.8455L107.851 23.3609C107.594 22.7104 107.109 23.0781 107.137 23.4174C107.137 26.3022 107.137 28.5648 107.137 31.4496C107.166 33.3445 104.369 33.3728 104.369 31.4213V14.5086C104.369 12.8682 106.453 12.4722 107.08 14.0278C108.165 16.7146 109.706 20.5327 110.762 23.2195C111.047 23.7285 111.504 23.3892 111.475 23.1629C111.475 20.6175 111.475 17.1105 111.475 14.5368C111.475 12.6137 114.272 12.6419 114.243 14.5086V31.4496Z" />
                <path d="M120.236 22.8235L120.207 27.6315C120.207 28.282 120.293 28.8476 120.521 29.2719C120.778 29.7244 121.292 30.0072 122.205 29.9506C123.175 29.8941 124.117 29.583 124.146 28.084L124.174 26.8396C124.174 26.3305 123.632 26.076 123.404 26.076H123.004C121.349 26.0477 121.349 23.276 123.004 23.3043C123.775 23.3043 124.688 23.3043 125.458 23.3043C126.742 23.3326 126.999 23.9831 126.999 24.8881C126.999 25.878 126.999 27.2073 126.999 28.1406C126.999 29.0456 126.799 29.9789 126.286 30.7708C125.487 32.0718 123.832 32.7788 122.176 32.8637C120.607 32.9202 119.009 32.3829 118.181 31.1668C117.525 30.2617 117.354 28.8476 117.382 27.7446V18.1852C117.382 17.0822 117.525 15.6681 118.181 14.7631C118.98 13.6035 120.464 13.0662 122.005 13.0662C123.689 13.0945 125.458 13.8015 126.286 15.159C126.799 15.951 126.999 16.8843 126.999 17.7893V19.2317C127.056 20.872 124.203 21.1832 124.174 19.26L124.146 17.8459C124.117 16.6297 123.175 16.0075 122.205 15.9792C121.206 15.9227 120.207 16.46 120.236 17.5913V22.8235Z" />
                <path d="M16.0001 54.9615V38.3881C16.0001 37.3417 16.9989 36.5498 17.7408 36.6064L21.9072 36.6346C27.1293 36.6629 28.0425 48.8243 21.5076 48.8808L20.0808 48.9091C19.5672 48.9091 18.7681 49.5879 18.7681 50.4363V54.9898C18.7967 56.8847 16.0001 56.913 16.0001 54.9615ZM18.7681 44.6951C18.7396 45.4021 19.3959 46.0809 20.195 46.0243H21.365C24.1901 46.0243 24.3042 39.3497 21.0225 39.3497H20.1379C19.4815 39.3214 18.7967 39.8022 18.7681 40.7073V44.6951Z" />
                <path d="M29.0694 54.9332L29.0409 46.5334V38.3599C29.0409 37.3134 30.0396 36.5215 30.7816 36.6064H34.9479C37.5448 36.6064 39.0857 39.6325 39.1713 42.6587C39.2284 44.8082 38.7718 45.8829 37.6304 47.6081C37.2023 48.2869 36.7172 49.1919 37.4306 50.4081L39.1999 54.4241C40.0274 56.0928 37.5162 57.3089 36.6887 55.5554L34.0918 50.1535C33.5211 48.9374 31.8089 49.1919 31.8089 50.6909V54.9898C31.8374 56.8564 29.0694 56.8847 29.0694 54.9332ZM31.8089 44.6951C31.8089 45.4021 32.4367 46.0526 33.2357 45.996H34.4057C35.8326 45.996 36.5745 44.2708 36.5174 42.5739C36.4604 40.9335 35.6613 39.3214 34.0633 39.3214H33.1787C32.5223 39.3214 31.8374 39.8022 31.8089 40.679V44.6951Z" />
                <path d="M44.1574 36.5781H49.5509C51.206 36.5498 51.1774 39.2649 49.5509 39.3214C48.5235 39.3214 47.5818 39.3214 46.5545 39.3214C45.8696 39.3214 45.1848 39.6891 45.1848 40.5659C45.1848 41.6689 45.1848 42.687 45.1848 43.7617C45.1562 44.4971 45.4987 45.0627 46.2121 45.0344H48.495C50.1787 45.0062 50.1501 47.7212 48.5235 47.7495C47.7245 47.7495 46.954 47.7495 46.155 47.7495C45.6413 47.7495 45.1562 48.0889 45.1848 48.9374C45.2418 49.9838 45.1848 51.0303 45.1848 52.105C45.1848 52.812 45.356 53.6322 46.4689 53.6322H49.5509C51.206 53.6039 51.1774 56.319 49.5223 56.3756C47.7245 56.3756 45.9267 56.3756 44.1289 56.3756C42.5309 56.3756 42.4167 55.3857 42.4167 54.3958V45.8263C42.4167 43.2809 42.4167 40.679 42.4452 38.1336C42.4452 36.8609 43.1872 36.5498 44.1574 36.5781Z" />
                <path d="M57.5928 55.3009L53.4836 38.4447C52.9984 36.5498 55.7094 35.9276 56.1375 37.7376L58.3062 46.2789C58.6487 47.5516 59.5048 48.1455 59.9614 46.2223C60.6462 43.3941 61.3597 40.5376 62.0445 37.7094C62.4726 35.8993 65.2121 36.5215 64.727 38.4164L60.3323 55.3009C60.104 56.1493 59.4477 56.4887 58.8199 56.4039C58.2777 56.3756 57.764 56.0362 57.5928 55.3009Z" />
                <path d="M67.8892 54.9332V38.0205C67.8892 36.069 70.6572 36.1256 70.6572 37.9922V54.9615C70.6572 56.8564 67.8892 56.8847 67.8892 54.9332Z" />
                <path d="M75.5923 36.5781H80.9857C82.6408 36.5498 82.6123 39.2649 80.9857 39.3214C79.9584 39.3214 79.0167 39.3214 77.9894 39.3214C77.3045 39.3214 76.6196 39.6891 76.6196 40.5659C76.6196 41.6689 76.6196 42.687 76.6196 43.7617C76.5911 44.4971 76.9335 45.0627 77.6469 45.0344H79.9298C81.6135 45.0062 81.585 47.7212 79.9584 47.7495C79.1594 47.7495 78.3889 47.7495 77.5898 47.7495C77.0762 47.7495 76.5911 48.0889 76.6196 48.9374C76.6767 49.9838 76.6196 51.0303 76.6196 52.105C76.6196 52.812 76.7908 53.6322 77.9037 53.6322H80.9857C82.6408 53.6039 82.6123 56.319 80.9572 56.3756C79.1594 56.3756 77.3616 56.3756 75.5637 56.3756C73.9657 56.3756 73.8515 55.3857 73.8515 54.3958V45.8263C73.8515 43.2809 73.8515 40.679 73.8801 38.1336C73.8801 36.8609 74.622 36.5498 75.5923 36.5781Z" />
                <path d="M94.9347 55.2726L93.9074 49.3333C93.7362 48.4 92.9657 48.4283 92.766 49.3333L91.6816 55.2726C91.5389 56.1493 90.7969 56.4604 90.1691 56.4039C89.6269 56.3473 89.1133 56.0079 88.9421 55.2726L84.8328 38.4164C84.3762 36.5215 87.0586 35.8993 87.4867 37.7094L89.684 46.2506C89.9979 47.3819 90.854 47.9192 91.3106 46.194C91.5389 45.2607 92.0811 40.7073 92.3094 39.774C92.5662 38.3599 94.4781 38.3033 94.7064 39.774L95.6482 46.2789C95.8764 47.5516 96.8467 47.9192 97.3033 46.2223C97.9882 43.3941 98.7016 40.5376 99.3864 37.7094C99.8145 35.8993 102.554 36.5215 102.069 38.4164L97.6742 55.2726C97.446 56.1493 96.7896 56.4604 96.1618 56.4039C95.6196 56.3473 95.0489 56.0079 94.9347 55.2726Z" />
                <path d="M20.1379 78.53V69.31L20.1093 64.3889C20.0808 63.3707 19.5101 63.201 18.7396 63.2293H17.4554C15.5435 63.2576 15.4864 60.2031 17.4554 60.2031H25.5884C27.5574 60.2031 27.5289 63.2293 25.6169 63.201L23.9903 63.2293C23.1628 63.201 22.8774 63.4556 22.8774 64.3889V78.5582C22.9059 80.4249 20.1379 80.4814 20.1379 78.53Z" />
                <path d="M38.7094 75.0512C38.6809 81.5279 29.4065 81.641 29.3209 75.1361C29.2923 73.4674 29.2923 71.7988 29.2923 70.1019C29.2923 68.3767 29.2923 66.6797 29.3209 64.9545C29.4065 58.4779 38.6809 58.591 38.7094 65.0676C38.7094 66.7363 38.7094 68.4049 38.7094 70.0736C38.7094 71.7422 38.7094 73.3826 38.7094 75.0512ZM35.8843 75.3058V64.8131C35.8843 62.2677 31.9748 62.1546 32.0604 65.0959C32.0604 66.7363 32.0604 68.3767 32.0604 70.0453C32.0604 71.714 32.0604 73.3543 32.0604 75.023C31.9748 77.9643 35.8843 77.8512 35.8843 75.3058Z" />
                <path d="M51.2499 75.0512C51.2214 81.5279 41.947 81.641 41.8614 75.1361C41.8328 73.4674 41.8328 71.7988 41.8328 70.1019C41.8328 68.3767 41.8328 66.6797 41.8614 64.9545C41.947 58.4779 51.2214 58.591 51.2499 65.0676C51.2499 66.7363 51.2499 68.4049 51.2499 70.0736C51.2499 71.7422 51.2499 73.3826 51.2499 75.0512ZM48.4248 75.3058V64.8131C48.4248 62.2677 44.5153 62.1546 44.6009 65.0959C44.6009 66.7363 44.6009 68.3767 44.6009 70.0453C44.6009 71.714 44.6009 73.3543 44.6009 75.023C44.5153 77.9643 48.4248 77.8512 48.4248 75.3058Z" />
                <path d="M57.1128 75.6735C57.1128 76.3805 57.2841 77.2007 58.4255 77.2007H61.4789C63.1626 77.1724 63.1341 79.8875 61.4789 79.9441C59.6811 79.9441 57.8548 79.9441 56.057 79.9441C54.4589 79.9158 54.3448 78.9259 54.3733 77.9643V61.4192C54.3733 59.4678 57.1414 59.4961 57.1128 61.3627V75.6735Z" />
                <path d="M75.4605 63.4838C76.1169 65.6333 73.2061 66.3969 72.8066 64.6151C72.293 62.5505 68.412 62.5223 68.84 65.9444C69.1825 67.9524 74.1478 69.5645 75.2037 72.3644C75.8886 74.2876 75.3749 76.6633 74.0337 78.134C71.2086 81.2168 65.7581 80.4531 64.7308 76.0411C64.103 73.8351 67.0708 73.0432 67.4703 74.8533C68.0125 77.229 70.4666 77.7663 71.8364 76.0411C72.4071 75.3058 72.5213 74.2876 72.293 73.4109C71.6366 70.9786 66.1005 70.4978 65.8437 65.9444C65.5013 59.4961 73.8625 58.3082 75.4605 63.4838Z" />
            </mask>
            <path d="M16.0286 31.3647V14.4803C16.0286 12.8116 18.0832 12.444 18.7396 13.9712L21.6218 20.9003C22.2211 22.2579 22.6776 22.371 23.2769 20.8438L26.0735 14.056C26.7869 12.3874 28.6418 12.8965 28.813 14.1975C28.813 14.2823 28.813 14.3671 28.813 14.4803V31.3647C28.813 33.3162 26.045 33.2879 26.0735 31.4213V23.5306C26.1306 22.569 25.4172 22.767 25.2745 23.1912C24.7323 24.4922 24.3328 25.4538 23.7906 26.7265C23.2484 28.0557 21.6503 28.1123 21.0796 26.783L19.6242 23.276C19.3103 22.5407 18.7681 23.0781 18.7967 23.3892C18.7967 26.3022 18.7967 28.5082 18.7967 31.4213C18.7967 33.2879 16.0286 33.3162 16.0286 31.3647Z" fill="#FCF4ED" />
            <path d="M39.6453 14.8197L42.0709 25.0012L43.5548 31.3647C43.8973 32.9768 41.2148 33.599 40.9009 31.7041L40.6441 30.3749C40.53 29.7809 40.1304 29.2153 39.36 29.2436H36.5063C35.9356 29.2153 35.2792 29.385 35.0224 30.29L34.5087 31.9869C34.1092 33.4576 31.6265 33.0334 31.9975 31.2233L35.907 14.8479C36.6204 12.3308 39.2458 12.6137 39.6453 14.8197ZM37.1912 20.7306C36.9058 22.2296 36.6204 23.7003 36.3351 25.1709C36.2209 25.68 36.3921 26.1891 37.077 26.1891H38.7036C39.3885 26.1891 39.5312 25.6517 39.417 25.1709L38.4468 20.7306C38.3041 19.9953 37.448 19.7973 37.1912 20.7306Z" fill="#FCF4ED" />
            <path d="M46.6818 31.3647L46.6533 22.9649V14.7914C46.6533 13.7449 47.6521 12.953 48.394 13.0379H52.5604C55.1572 13.0379 56.6982 16.0641 56.7838 19.0903C56.8408 21.2397 56.3843 22.3144 55.2428 24.0396C54.8147 24.7184 54.3296 25.6235 55.043 26.8396L56.8123 30.8557C57.6399 32.5243 55.1286 33.7404 54.3011 31.9869L51.7043 26.585C51.1335 25.3689 49.4213 25.6234 49.4213 27.1224V31.4213C49.4499 33.2879 46.6818 33.3162 46.6818 31.3647ZM49.4213 21.1266C49.4213 21.8336 50.0491 22.4841 50.8482 22.4276H52.0182C53.445 22.4276 54.1869 20.7024 54.1299 19.0054C54.0728 17.3651 53.2738 15.753 51.6757 15.753H50.7911C50.1347 15.753 49.4499 16.2338 49.4213 17.1105V21.1266Z" fill="#FCF4ED" />
            <path d="M60.1147 31.393V14.4803C60.1147 12.5288 62.9113 12.5854 62.8828 14.452V19.6559C62.8828 20.165 63.3965 20.5327 63.7674 19.7973L67.0491 13.8298C68.0765 12.1611 70.2167 13.6035 69.5033 14.9045L66.9065 19.7408C66.3928 20.7306 66.2216 21.3246 66.7067 22.4276L70.3879 30.8839C71.0443 32.4677 68.8184 33.7122 67.8767 32.0718L64.8233 25.1709C64.2526 23.9265 62.8543 25.0295 62.9399 27.2638L62.8828 31.4213C62.9113 33.3162 60.1147 33.3445 60.1147 31.393Z" fill="#FCF4ED" />
            <path d="M75.2857 13.0096H80.6791C82.3343 12.9813 82.3057 15.6964 80.6791 15.753C79.6518 15.753 78.7101 15.753 77.6828 15.753C76.9979 15.753 76.3131 16.1206 76.3131 16.9974C76.3131 18.1004 76.3131 19.1186 76.3131 20.1933C76.2845 20.9286 76.627 21.4943 77.3404 21.466H79.6233C81.307 21.4377 81.2784 24.1528 79.6518 24.1811C78.8528 24.1811 78.0823 24.1811 77.2833 24.1811C76.7696 24.1811 76.2845 24.5204 76.3131 25.3689C76.3701 26.4154 76.3131 27.4618 76.3131 28.5365C76.3131 29.2436 76.4843 30.0638 77.5972 30.0638H80.6791C82.3343 30.0355 82.3057 32.7506 80.6506 32.8071C78.8528 32.8071 77.055 32.8071 75.2572 32.8071C73.6592 32.8071 73.545 31.8172 73.545 30.8274V22.2579C73.545 19.7125 73.545 17.1105 73.5735 14.5651C73.5735 13.2924 74.3155 12.9813 75.2857 13.0096Z" fill="#FCF4ED" />
            <path d="M88.4139 31.393V22.173L88.3854 17.2519C88.3568 16.2338 87.7861 16.0641 87.0156 16.0924H85.7315C83.8195 16.1206 83.7624 13.0662 85.7315 13.0662H93.8644C95.8334 13.0662 95.8049 16.0924 93.8929 16.0641L92.2663 16.0924C91.4388 16.0641 91.1534 16.3186 91.1534 17.2519V31.4213C91.182 33.2879 88.4139 33.3445 88.4139 31.393Z" fill="#FCF4ED" />
            <path d="M98.2929 31.3647V14.452C98.2929 12.5005 101.061 12.5571 101.061 14.4237V31.393C101.061 33.2879 98.2929 33.3162 98.2929 31.3647Z" fill="#FCF4ED" />
            <path d="M114.243 31.4496C114.272 33.2314 112.36 33.2879 111.732 31.8455L107.851 23.3609C107.594 22.7104 107.109 23.0781 107.137 23.4174C107.137 26.3022 107.137 28.5648 107.137 31.4496C107.166 33.3445 104.369 33.3728 104.369 31.4213V14.5086C104.369 12.8682 106.453 12.4722 107.08 14.0278C108.165 16.7146 109.706 20.5327 110.762 23.2195C111.047 23.7285 111.504 23.3892 111.475 23.1629C111.475 20.6175 111.475 17.1105 111.475 14.5368C111.475 12.6137 114.272 12.6419 114.243 14.5086V31.4496Z" fill="#FCF4ED" />
            <path d="M120.236 22.8235L120.207 27.6315C120.207 28.282 120.293 28.8476 120.521 29.2719C120.778 29.7244 121.292 30.0072 122.205 29.9506C123.175 29.8941 124.117 29.583 124.146 28.084L124.174 26.8396C124.174 26.3305 123.632 26.076 123.404 26.076H123.004C121.349 26.0477 121.349 23.276 123.004 23.3043C123.775 23.3043 124.688 23.3043 125.458 23.3043C126.742 23.3326 126.999 23.9831 126.999 24.8881C126.999 25.878 126.999 27.2073 126.999 28.1406C126.999 29.0456 126.799 29.9789 126.286 30.7708C125.487 32.0718 123.832 32.7788 122.176 32.8637C120.607 32.9202 119.009 32.3829 118.181 31.1668C117.525 30.2617 117.354 28.8476 117.382 27.7446V18.1852C117.382 17.0822 117.525 15.6681 118.181 14.7631C118.98 13.6035 120.464 13.0662 122.005 13.0662C123.689 13.0945 125.458 13.8015 126.286 15.159C126.799 15.951 126.999 16.8843 126.999 17.7893V19.2317C127.056 20.872 124.203 21.1832 124.174 19.26L124.146 17.8459C124.117 16.6297 123.175 16.0075 122.205 15.9792C121.206 15.9227 120.207 16.46 120.236 17.5913V22.8235Z" fill="#FCF4ED" />
            <path d="M16.0001 54.9615V38.3881C16.0001 37.3417 16.9989 36.5498 17.7408 36.6064L21.9072 36.6346C27.1293 36.6629 28.0425 48.8243 21.5076 48.8808L20.0808 48.9091C19.5672 48.9091 18.7681 49.5879 18.7681 50.4363V54.9898C18.7967 56.8847 16.0001 56.913 16.0001 54.9615ZM18.7681 44.6951C18.7396 45.4021 19.3959 46.0809 20.195 46.0243H21.365C24.1901 46.0243 24.3042 39.3497 21.0225 39.3497H20.1379C19.4815 39.3214 18.7967 39.8022 18.7681 40.7073V44.6951Z" fill="#FCF4ED" />
            <path d="M29.0694 54.9332L29.0409 46.5334V38.3599C29.0409 37.3134 30.0396 36.5215 30.7816 36.6064H34.9479C37.5448 36.6064 39.0857 39.6325 39.1713 42.6587C39.2284 44.8082 38.7718 45.8829 37.6304 47.6081C37.2023 48.2869 36.7172 49.1919 37.4306 50.4081L39.1999 54.4241C40.0274 56.0928 37.5162 57.3089 36.6887 55.5554L34.0918 50.1535C33.5211 48.9374 31.8089 49.1919 31.8089 50.6909V54.9898C31.8374 56.8564 29.0694 56.8847 29.0694 54.9332ZM31.8089 44.6951C31.8089 45.4021 32.4367 46.0526 33.2357 45.996H34.4057C35.8326 45.996 36.5745 44.2708 36.5174 42.5739C36.4604 40.9335 35.6613 39.3214 34.0633 39.3214H33.1787C32.5223 39.3214 31.8374 39.8022 31.8089 40.679V44.6951Z" fill="#FCF4ED" />
            <path d="M44.1574 36.5781H49.5509C51.206 36.5498 51.1774 39.2649 49.5509 39.3214C48.5235 39.3214 47.5818 39.3214 46.5545 39.3214C45.8696 39.3214 45.1848 39.6891 45.1848 40.5659C45.1848 41.6689 45.1848 42.687 45.1848 43.7617C45.1562 44.4971 45.4987 45.0627 46.2121 45.0344H48.495C50.1787 45.0062 50.1501 47.7212 48.5235 47.7495C47.7245 47.7495 46.954 47.7495 46.155 47.7495C45.6413 47.7495 45.1562 48.0889 45.1848 48.9374C45.2418 49.9838 45.1848 51.0303 45.1848 52.105C45.1848 52.812 45.356 53.6322 46.4689 53.6322H49.5509C51.206 53.6039 51.1774 56.319 49.5223 56.3756C47.7245 56.3756 45.9267 56.3756 44.1289 56.3756C42.5309 56.3756 42.4167 55.3857 42.4167 54.3958V45.8263C42.4167 43.2809 42.4167 40.679 42.4452 38.1336C42.4452 36.8609 43.1872 36.5498 44.1574 36.5781Z" fill="#FCF4ED" />
            <path d="M57.5928 55.3009L53.4836 38.4447C52.9984 36.5498 55.7094 35.9276 56.1375 37.7376L58.3062 46.2789C58.6487 47.5516 59.5048 48.1455 59.9614 46.2223C60.6462 43.3941 61.3597 40.5376 62.0445 37.7094C62.4726 35.8993 65.2121 36.5215 64.727 38.4164L60.3323 55.3009C60.104 56.1493 59.4477 56.4887 58.8199 56.4039C58.2777 56.3756 57.764 56.0362 57.5928 55.3009Z" fill="#FCF4ED" />
            <path d="M67.8892 54.9332V38.0205C67.8892 36.069 70.6572 36.1256 70.6572 37.9922V54.9615C70.6572 56.8564 67.8892 56.8847 67.8892 54.9332Z" fill="#FCF4ED" />
            <path d="M75.5923 36.5781H80.9857C82.6408 36.5498 82.6123 39.2649 80.9857 39.3214C79.9584 39.3214 79.0167 39.3214 77.9894 39.3214C77.3045 39.3214 76.6196 39.6891 76.6196 40.5659C76.6196 41.6689 76.6196 42.687 76.6196 43.7617C76.5911 44.4971 76.9335 45.0627 77.6469 45.0344H79.9298C81.6135 45.0062 81.585 47.7212 79.9584 47.7495C79.1594 47.7495 78.3889 47.7495 77.5898 47.7495C77.0762 47.7495 76.5911 48.0889 76.6196 48.9374C76.6767 49.9838 76.6196 51.0303 76.6196 52.105C76.6196 52.812 76.7908 53.6322 77.9037 53.6322H80.9857C82.6408 53.6039 82.6123 56.319 80.9572 56.3756C79.1594 56.3756 77.3616 56.3756 75.5637 56.3756C73.9657 56.3756 73.8515 55.3857 73.8515 54.3958V45.8263C73.8515 43.2809 73.8515 40.679 73.8801 38.1336C73.8801 36.8609 74.622 36.5498 75.5923 36.5781Z" fill="#FCF4ED" />
            <path d="M94.9347 55.2726L93.9074 49.3333C93.7362 48.4 92.9657 48.4283 92.766 49.3333L91.6816 55.2726C91.5389 56.1493 90.7969 56.4604 90.1691 56.4039C89.6269 56.3473 89.1133 56.0079 88.9421 55.2726L84.8328 38.4164C84.3762 36.5215 87.0586 35.8993 87.4867 37.7094L89.684 46.2506C89.9979 47.3819 90.854 47.9192 91.3106 46.194C91.5389 45.2607 92.0811 40.7073 92.3094 39.774C92.5662 38.3599 94.4781 38.3033 94.7064 39.774L95.6482 46.2789C95.8764 47.5516 96.8467 47.9192 97.3033 46.2223C97.9882 43.3941 98.7016 40.5376 99.3864 37.7094C99.8145 35.8993 102.554 36.5215 102.069 38.4164L97.6742 55.2726C97.446 56.1493 96.7896 56.4604 96.1618 56.4039C95.6196 56.3473 95.0489 56.0079 94.9347 55.2726Z" fill="#FCF4ED" />
            <path d="M20.1379 78.53V69.31L20.1093 64.3889C20.0808 63.3707 19.5101 63.201 18.7396 63.2293H17.4554C15.5435 63.2576 15.4864 60.2031 17.4554 60.2031H25.5884C27.5574 60.2031 27.5289 63.2293 25.6169 63.201L23.9903 63.2293C23.1628 63.201 22.8774 63.4556 22.8774 64.3889V78.5582C22.9059 80.4249 20.1379 80.4814 20.1379 78.53Z" fill="#FCF4ED" />
            <path d="M38.7094 75.0512C38.6809 81.5279 29.4065 81.641 29.3209 75.1361C29.2923 73.4674 29.2923 71.7988 29.2923 70.1019C29.2923 68.3767 29.2923 66.6797 29.3209 64.9545C29.4065 58.4779 38.6809 58.591 38.7094 65.0676C38.7094 66.7363 38.7094 68.4049 38.7094 70.0736C38.7094 71.7422 38.7094 73.3826 38.7094 75.0512ZM35.8843 75.3058V64.8131C35.8843 62.2677 31.9748 62.1546 32.0604 65.0959C32.0604 66.7363 32.0604 68.3767 32.0604 70.0453C32.0604 71.714 32.0604 73.3543 32.0604 75.023C31.9748 77.9643 35.8843 77.8512 35.8843 75.3058Z" fill="#FCF4ED" />
            <path d="M51.2499 75.0512C51.2214 81.5279 41.947 81.641 41.8614 75.1361C41.8328 73.4674 41.8328 71.7988 41.8328 70.1019C41.8328 68.3767 41.8328 66.6797 41.8614 64.9545C41.947 58.4779 51.2214 58.591 51.2499 65.0676C51.2499 66.7363 51.2499 68.4049 51.2499 70.0736C51.2499 71.7422 51.2499 73.3826 51.2499 75.0512ZM48.4248 75.3058V64.8131C48.4248 62.2677 44.5153 62.1546 44.6009 65.0959C44.6009 66.7363 44.6009 68.3767 44.6009 70.0453C44.6009 71.714 44.6009 73.3543 44.6009 75.023C44.5153 77.9643 48.4248 77.8512 48.4248 75.3058Z" fill="#FCF4ED" />
            <path d="M57.1128 75.6735C57.1128 76.3805 57.2841 77.2007 58.4255 77.2007H61.4789C63.1626 77.1724 63.1341 79.8875 61.4789 79.9441C59.6811 79.9441 57.8548 79.9441 56.057 79.9441C54.4589 79.9158 54.3448 78.9259 54.3733 77.9643V61.4192C54.3733 59.4678 57.1414 59.4961 57.1128 61.3627V75.6735Z" fill="#FCF4ED" />
            <path d="M75.4605 63.4838C76.1169 65.6333 73.2061 66.3969 72.8066 64.6151C72.293 62.5505 68.412 62.5223 68.84 65.9444C69.1825 67.9524 74.1478 69.5645 75.2037 72.3644C75.8886 74.2876 75.3749 76.6633 74.0337 78.134C71.2086 81.2168 65.7581 80.4531 64.7308 76.0411C64.103 73.8351 67.0708 73.0432 67.4703 74.8533C68.0125 77.229 70.4666 77.7663 71.8364 76.0411C72.4071 75.3058 72.5213 74.2876 72.293 73.4109C71.6366 70.9786 66.1005 70.4978 65.8437 65.9444C65.5013 59.4961 73.8625 58.3082 75.4605 63.4838Z" fill="#FCF4ED" />
            <path d="M16.0286 31.3647V14.4803C16.0286 12.8116 18.0832 12.444 18.7396 13.9712L21.6218 20.9003C22.2211 22.2579 22.6776 22.371 23.2769 20.8438L26.0735 14.056C26.7869 12.3874 28.6418 12.8965 28.813 14.1975C28.813 14.2823 28.813 14.3671 28.813 14.4803V31.3647C28.813 33.3162 26.045 33.2879 26.0735 31.4213V23.5306C26.1306 22.569 25.4172 22.767 25.2745 23.1912C24.7323 24.4922 24.3328 25.4538 23.7906 26.7265C23.2484 28.0557 21.6503 28.1123 21.0796 26.783L19.6242 23.276C19.3103 22.5407 18.7681 23.0781 18.7967 23.3892C18.7967 26.3022 18.7967 28.5082 18.7967 31.4213C18.7967 33.2879 16.0286 33.3162 16.0286 31.3647Z" stroke="#7C6195" stroke-width="0.8" mask="url(#path-1-outside-1_3_2)" />
            <path d="M39.6453 14.8197L42.0709 25.0012L43.5548 31.3647C43.8973 32.9768 41.2148 33.599 40.9009 31.7041L40.6441 30.3749C40.53 29.7809 40.1304 29.2153 39.36 29.2436H36.5063C35.9356 29.2153 35.2792 29.385 35.0224 30.29L34.5087 31.9869C34.1092 33.4576 31.6265 33.0334 31.9975 31.2233L35.907 14.8479C36.6204 12.3308 39.2458 12.6137 39.6453 14.8197ZM37.1912 20.7306C36.9058 22.2296 36.6204 23.7003 36.3351 25.1709C36.2209 25.68 36.3921 26.1891 37.077 26.1891H38.7036C39.3885 26.1891 39.5312 25.6517 39.417 25.1709L38.4468 20.7306C38.3041 19.9953 37.448 19.7973 37.1912 20.7306Z" stroke="#7C6195" stroke-width="0.8" mask="url(#path-1-outside-1_3_2)" />
            <path d="M46.6818 31.3647L46.6533 22.9649V14.7914C46.6533 13.7449 47.6521 12.953 48.394 13.0379H52.5604C55.1572 13.0379 56.6982 16.0641 56.7838 19.0903C56.8408 21.2397 56.3843 22.3144 55.2428 24.0396C54.8147 24.7184 54.3296 25.6235 55.043 26.8396L56.8123 30.8557C57.6399 32.5243 55.1286 33.7404 54.3011 31.9869L51.7043 26.585C51.1335 25.3689 49.4213 25.6234 49.4213 27.1224V31.4213C49.4499 33.2879 46.6818 33.3162 46.6818 31.3647ZM49.4213 21.1266C49.4213 21.8336 50.0491 22.4841 50.8482 22.4276H52.0182C53.445 22.4276 54.1869 20.7024 54.1299 19.0054C54.0728 17.3651 53.2738 15.753 51.6757 15.753H50.7911C50.1347 15.753 49.4499 16.2338 49.4213 17.1105V21.1266Z" stroke="#7C6195" stroke-width="0.8" mask="url(#path-1-outside-1_3_2)" />
            <path d="M60.1147 31.393V14.4803C60.1147 12.5288 62.9113 12.5854 62.8828 14.452V19.6559C62.8828 20.165 63.3965 20.5327 63.7674 19.7973L67.0491 13.8298C68.0765 12.1611 70.2167 13.6035 69.5033 14.9045L66.9065 19.7408C66.3928 20.7306 66.2216 21.3246 66.7067 22.4276L70.3879 30.8839C71.0443 32.4677 68.8184 33.7122 67.8767 32.0718L64.8233 25.1709C64.2526 23.9265 62.8543 25.0295 62.9399 27.2638L62.8828 31.4213C62.9113 33.3162 60.1147 33.3445 60.1147 31.393Z" stroke="#7C6195" stroke-width="0.8" mask="url(#path-1-outside-1_3_2)" />
            <path d="M75.2857 13.0096H80.6791C82.3343 12.9813 82.3057 15.6964 80.6791 15.753C79.6518 15.753 78.7101 15.753 77.6828 15.753C76.9979 15.753 76.3131 16.1206 76.3131 16.9974C76.3131 18.1004 76.3131 19.1186 76.3131 20.1933C76.2845 20.9286 76.627 21.4943 77.3404 21.466H79.6233C81.307 21.4377 81.2784 24.1528 79.6518 24.1811C78.8528 24.1811 78.0823 24.1811 77.2833 24.1811C76.7696 24.1811 76.2845 24.5204 76.3131 25.3689C76.3701 26.4154 76.3131 27.4618 76.3131 28.5365C76.3131 29.2436 76.4843 30.0638 77.5972 30.0638H80.6791C82.3343 30.0355 82.3057 32.7506 80.6506 32.8071C78.8528 32.8071 77.055 32.8071 75.2572 32.8071C73.6592 32.8071 73.545 31.8172 73.545 30.8274V22.2579C73.545 19.7125 73.545 17.1105 73.5735 14.5651C73.5735 13.2924 74.3155 12.9813 75.2857 13.0096Z" stroke="#7C6195" stroke-width="0.8" mask="url(#path-1-outside-1_3_2)" />
            <path d="M88.4139 31.393V22.173L88.3854 17.2519C88.3568 16.2338 87.7861 16.0641 87.0156 16.0924H85.7315C83.8195 16.1206 83.7624 13.0662 85.7315 13.0662H93.8644C95.8334 13.0662 95.8049 16.0924 93.8929 16.0641L92.2663 16.0924C91.4388 16.0641 91.1534 16.3186 91.1534 17.2519V31.4213C91.182 33.2879 88.4139 33.3445 88.4139 31.393Z" stroke="#7C6195" stroke-width="0.8" mask="url(#path-1-outside-1_3_2)" />
            <path d="M98.2929 31.3647V14.452C98.2929 12.5005 101.061 12.5571 101.061 14.4237V31.393C101.061 33.2879 98.2929 33.3162 98.2929 31.3647Z" stroke="#7C6195" stroke-width="0.8" mask="url(#path-1-outside-1_3_2)" />
            <path d="M114.243 31.4496C114.272 33.2314 112.36 33.2879 111.732 31.8455L107.851 23.3609C107.594 22.7104 107.109 23.0781 107.137 23.4174C107.137 26.3022 107.137 28.5648 107.137 31.4496C107.166 33.3445 104.369 33.3728 104.369 31.4213V14.5086C104.369 12.8682 106.453 12.4722 107.08 14.0278C108.165 16.7146 109.706 20.5327 110.762 23.2195C111.047 23.7285 111.504 23.3892 111.475 23.1629C111.475 20.6175 111.475 17.1105 111.475 14.5368C111.475 12.6137 114.272 12.6419 114.243 14.5086V31.4496Z" stroke="#7C6195" stroke-width="0.8" mask="url(#path-1-outside-1_3_2)" />
            <path d="M120.236 22.8235L120.207 27.6315C120.207 28.282 120.293 28.8476 120.521 29.2719C120.778 29.7244 121.292 30.0072 122.205 29.9506C123.175 29.8941 124.117 29.583 124.146 28.084L124.174 26.8396C124.174 26.3305 123.632 26.076 123.404 26.076H123.004C121.349 26.0477 121.349 23.276 123.004 23.3043C123.775 23.3043 124.688 23.3043 125.458 23.3043C126.742 23.3326 126.999 23.9831 126.999 24.8881C126.999 25.878 126.999 27.2073 126.999 28.1406C126.999 29.0456 126.799 29.9789 126.286 30.7708C125.487 32.0718 123.832 32.7788 122.176 32.8637C120.607 32.9202 119.009 32.3829 118.181 31.1668C117.525 30.2617 117.354 28.8476 117.382 27.7446V18.1852C117.382 17.0822 117.525 15.6681 118.181 14.7631C118.98 13.6035 120.464 13.0662 122.005 13.0662C123.689 13.0945 125.458 13.8015 126.286 15.159C126.799 15.951 126.999 16.8843 126.999 17.7893V19.2317C127.056 20.872 124.203 21.1832 124.174 19.26L124.146 17.8459C124.117 16.6297 123.175 16.0075 122.205 15.9792C121.206 15.9227 120.207 16.46 120.236 17.5913V22.8235Z" stroke="#7C6195" stroke-width="0.8" mask="url(#path-1-outside-1_3_2)" />
            <path d="M16.0001 54.9615V38.3881C16.0001 37.3417 16.9989 36.5498 17.7408 36.6064L21.9072 36.6346C27.1293 36.6629 28.0425 48.8243 21.5076 48.8808L20.0808 48.9091C19.5672 48.9091 18.7681 49.5879 18.7681 50.4363V54.9898C18.7967 56.8847 16.0001 56.913 16.0001 54.9615ZM18.7681 44.6951C18.7396 45.4021 19.3959 46.0809 20.195 46.0243H21.365C24.1901 46.0243 24.3042 39.3497 21.0225 39.3497H20.1379C19.4815 39.3214 18.7967 39.8022 18.7681 40.7073V44.6951Z" stroke="#7C6195" stroke-width="0.8" mask="url(#path-1-outside-1_3_2)" />
            <path d="M29.0694 54.9332L29.0409 46.5334V38.3599C29.0409 37.3134 30.0396 36.5215 30.7816 36.6064H34.9479C37.5448 36.6064 39.0857 39.6325 39.1713 42.6587C39.2284 44.8082 38.7718 45.8829 37.6304 47.6081C37.2023 48.2869 36.7172 49.1919 37.4306 50.4081L39.1999 54.4241C40.0274 56.0928 37.5162 57.3089 36.6887 55.5554L34.0918 50.1535C33.5211 48.9374 31.8089 49.1919 31.8089 50.6909V54.9898C31.8374 56.8564 29.0694 56.8847 29.0694 54.9332ZM31.8089 44.6951C31.8089 45.4021 32.4367 46.0526 33.2357 45.996H34.4057C35.8326 45.996 36.5745 44.2708 36.5174 42.5739C36.4604 40.9335 35.6613 39.3214 34.0633 39.3214H33.1787C32.5223 39.3214 31.8374 39.8022 31.8089 40.679V44.6951Z" stroke="#7C6195" stroke-width="0.8" mask="url(#path-1-outside-1_3_2)" />
            <path d="M44.1574 36.5781H49.5509C51.206 36.5498 51.1774 39.2649 49.5509 39.3214C48.5235 39.3214 47.5818 39.3214 46.5545 39.3214C45.8696 39.3214 45.1848 39.6891 45.1848 40.5659C45.1848 41.6689 45.1848 42.687 45.1848 43.7617C45.1562 44.4971 45.4987 45.0627 46.2121 45.0344H48.495C50.1787 45.0062 50.1501 47.7212 48.5235 47.7495C47.7245 47.7495 46.954 47.7495 46.155 47.7495C45.6413 47.7495 45.1562 48.0889 45.1848 48.9374C45.2418 49.9838 45.1848 51.0303 45.1848 52.105C45.1848 52.812 45.356 53.6322 46.4689 53.6322H49.5509C51.206 53.6039 51.1774 56.319 49.5223 56.3756C47.7245 56.3756 45.9267 56.3756 44.1289 56.3756C42.5309 56.3756 42.4167 55.3857 42.4167 54.3958V45.8263C42.4167 43.2809 42.4167 40.679 42.4452 38.1336C42.4452 36.8609 43.1872 36.5498 44.1574 36.5781Z" stroke="#7C6195" stroke-width="0.8" mask="url(#path-1-outside-1_3_2)" />
            <path d="M57.5928 55.3009L53.4836 38.4447C52.9984 36.5498 55.7094 35.9276 56.1375 37.7376L58.3062 46.2789C58.6487 47.5516 59.5048 48.1455 59.9614 46.2223C60.6462 43.3941 61.3597 40.5376 62.0445 37.7094C62.4726 35.8993 65.2121 36.5215 64.727 38.4164L60.3323 55.3009C60.104 56.1493 59.4477 56.4887 58.8199 56.4039C58.2777 56.3756 57.764 56.0362 57.5928 55.3009Z" stroke="#7C6195" stroke-width="0.8" mask="url(#path-1-outside-1_3_2)" />
            <path d="M67.8892 54.9332V38.0205C67.8892 36.069 70.6572 36.1256 70.6572 37.9922V54.9615C70.6572 56.8564 67.8892 56.8847 67.8892 54.9332Z" stroke="#7C6195" stroke-width="0.8" mask="url(#path-1-outside-1_3_2)" />
            <path d="M75.5923 36.5781H80.9857C82.6408 36.5498 82.6123 39.2649 80.9857 39.3214C79.9584 39.3214 79.0167 39.3214 77.9894 39.3214C77.3045 39.3214 76.6196 39.6891 76.6196 40.5659C76.6196 41.6689 76.6196 42.687 76.6196 43.7617C76.5911 44.4971 76.9335 45.0627 77.6469 45.0344H79.9298C81.6135 45.0062 81.585 47.7212 79.9584 47.7495C79.1594 47.7495 78.3889 47.7495 77.5898 47.7495C77.0762 47.7495 76.5911 48.0889 76.6196 48.9374C76.6767 49.9838 76.6196 51.0303 76.6196 52.105C76.6196 52.812 76.7908 53.6322 77.9037 53.6322H80.9857C82.6408 53.6039 82.6123 56.319 80.9572 56.3756C79.1594 56.3756 77.3616 56.3756 75.5637 56.3756C73.9657 56.3756 73.8515 55.3857 73.8515 54.3958V45.8263C73.8515 43.2809 73.8515 40.679 73.8801 38.1336C73.8801 36.8609 74.622 36.5498 75.5923 36.5781Z" stroke="#7C6195" stroke-width="0.8" mask="url(#path-1-outside-1_3_2)" />
            <path d="M94.9347 55.2726L93.9074 49.3333C93.7362 48.4 92.9657 48.4283 92.766 49.3333L91.6816 55.2726C91.5389 56.1493 90.7969 56.4604 90.1691 56.4039C89.6269 56.3473 89.1133 56.0079 88.9421 55.2726L84.8328 38.4164C84.3762 36.5215 87.0586 35.8993 87.4867 37.7094L89.684 46.2506C89.9979 47.3819 90.854 47.9192 91.3106 46.194C91.5389 45.2607 92.0811 40.7073 92.3094 39.774C92.5662 38.3599 94.4781 38.3033 94.7064 39.774L95.6482 46.2789C95.8764 47.5516 96.8467 47.9192 97.3033 46.2223C97.9882 43.3941 98.7016 40.5376 99.3864 37.7094C99.8145 35.8993 102.554 36.5215 102.069 38.4164L97.6742 55.2726C97.446 56.1493 96.7896 56.4604 96.1618 56.4039C95.6196 56.3473 95.0489 56.0079 94.9347 55.2726Z" stroke="#7C6195" stroke-width="0.8" mask="url(#path-1-outside-1_3_2)" />
            <path d="M20.1379 78.53V69.31L20.1093 64.3889C20.0808 63.3707 19.5101 63.201 18.7396 63.2293H17.4554C15.5435 63.2576 15.4864 60.2031 17.4554 60.2031H25.5884C27.5574 60.2031 27.5289 63.2293 25.6169 63.201L23.9903 63.2293C23.1628 63.201 22.8774 63.4556 22.8774 64.3889V78.5582C22.9059 80.4249 20.1379 80.4814 20.1379 78.53Z" stroke="#7C6195" stroke-width="0.8" mask="url(#path-1-outside-1_3_2)" />
            <path d="M38.7094 75.0512C38.6809 81.5279 29.4065 81.641 29.3209 75.1361C29.2923 73.4674 29.2923 71.7988 29.2923 70.1019C29.2923 68.3767 29.2923 66.6797 29.3209 64.9545C29.4065 58.4779 38.6809 58.591 38.7094 65.0676C38.7094 66.7363 38.7094 68.4049 38.7094 70.0736C38.7094 71.7422 38.7094 73.3826 38.7094 75.0512ZM35.8843 75.3058V64.8131C35.8843 62.2677 31.9748 62.1546 32.0604 65.0959C32.0604 66.7363 32.0604 68.3767 32.0604 70.0453C32.0604 71.714 32.0604 73.3543 32.0604 75.023C31.9748 77.9643 35.8843 77.8512 35.8843 75.3058Z" stroke="#7C6195" stroke-width="0.8" mask="url(#path-1-outside-1_3_2)" />
            <path d="M51.2499 75.0512C51.2214 81.5279 41.947 81.641 41.8614 75.1361C41.8328 73.4674 41.8328 71.7988 41.8328 70.1019C41.8328 68.3767 41.8328 66.6797 41.8614 64.9545C41.947 58.4779 51.2214 58.591 51.2499 65.0676C51.2499 66.7363 51.2499 68.4049 51.2499 70.0736C51.2499 71.7422 51.2499 73.3826 51.2499 75.0512ZM48.4248 75.3058V64.8131C48.4248 62.2677 44.5153 62.1546 44.6009 65.0959C44.6009 66.7363 44.6009 68.3767 44.6009 70.0453C44.6009 71.714 44.6009 73.3543 44.6009 75.023C44.5153 77.9643 48.4248 77.8512 48.4248 75.3058Z" stroke="#7C6195" stroke-width="0.8" mask="url(#path-1-outside-1_3_2)" />
            <path d="M57.1128 75.6735C57.1128 76.3805 57.2841 77.2007 58.4255 77.2007H61.4789C63.1626 77.1724 63.1341 79.8875 61.4789 79.9441C59.6811 79.9441 57.8548 79.9441 56.057 79.9441C54.4589 79.9158 54.3448 78.9259 54.3733 77.9643V61.4192C54.3733 59.4678 57.1414 59.4961 57.1128 61.3627V75.6735Z" stroke="#7C6195" stroke-width="0.8" mask="url(#path-1-outside-1_3_2)" />
            <path d="M75.4605 63.4838C76.1169 65.6333 73.2061 66.3969 72.8066 64.6151C72.293 62.5505 68.412 62.5223 68.84 65.9444C69.1825 67.9524 74.1478 69.5645 75.2037 72.3644C75.8886 74.2876 75.3749 76.6633 74.0337 78.134C71.2086 81.2168 65.7581 80.4531 64.7308 76.0411C64.103 73.8351 67.0708 73.0432 67.4703 74.8533C68.0125 77.229 70.4666 77.7663 71.8364 76.0411C72.4071 75.3058 72.5213 74.2876 72.293 73.4109C71.6366 70.9786 66.1005 70.4978 65.8437 65.9444C65.5013 59.4961 73.8625 58.3082 75.4605 63.4838Z" stroke="#7C6195" stroke-width="0.8" mask="url(#path-1-outside-1_3_2)" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M138.034 3.17949H3.96648C3.52835 3.17949 3.17318 3.53536 3.17318 3.97436V89.0256C3.17318 89.4646 3.52836 89.8205 3.96648 89.8205H138.034C138.472 89.8205 138.827 89.4646 138.827 89.0256V3.97436C138.827 3.53536 138.472 3.17949 138.034 3.17949ZM3.96648 0C1.77585 0 0 1.77938 0 3.97436V89.0256C0 91.2206 1.77585 93 3.96648 93H138.034C140.224 93 142 91.2206 142 89.0256V3.97436C142 1.77938 140.224 0 138.034 0H3.96648Z" fill="#FCF4ED" />
        </svg>
    )
}

export default LogoGreen