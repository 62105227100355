import React from 'react'

function alert() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M10 0C4.47656 0 0 4.47656 0 10C0 15.5234 4.47656 20 10 20C15.5234 20 20 15.5234 20 10C20 4.47656 15.5234 0 10 0ZM10 18.75C5.17578 18.75 1.25 14.8242 1.25 10C1.25 5.17578 5.17578 1.25 10 1.25C14.8242 1.25 18.75 5.17578 18.75 10C18.75 14.8242 14.8242 18.75 10 18.75ZM10 7.1875C10.5176 7.1875 10.9375 6.76797 10.9375 6.25C10.9375 5.73242 10.5176 5.3125 10 5.3125C9.48242 5.3125 9.0625 5.73047 9.0625 6.25C9.0625 6.76953 9.48047 7.1875 10 7.1875ZM11.875 13.75H10.625V9.375C10.625 9.03125 10.3438 8.75 10 8.75H8.75C8.40625 8.75 8.125 9.03125 8.125 9.375C8.125 9.71875 8.40625 10 8.75 10H9.375V13.75H8.125C7.78125 13.75 7.5 14.0312 7.5 14.375C7.5 14.7188 7.78125 15 8.125 15H11.875C12.2202 15 12.5 14.7202 12.5 14.375C12.5 14.0312 12.2188 13.75 11.875 13.75Z" fill="#333333" />
    </svg>
  )
}

export default alert