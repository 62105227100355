import React from 'react'

export default function Menu_Bars_Icon() {
   return (
      <>
         <svg xmlns="http://www.w3.org/2000/svg" width="42" height="34" viewBox="0 0 42 34" fill="none">
            <path d="M2.8 0C4.34612 0 5.6 1.2688 5.6 2.83333C5.6 4.40052 4.34612 5.66667 2.8 5.66667C1.25387 5.66667 0 4.40052 0 2.83333C0 1.2688 1.25387 0 2.8 0ZM40.6 1.41667C41.37 1.41667 42 2.05062 42 2.83333C42 3.6125 41.37 4.25 40.6 4.25H12.6C11.83 4.25 11.2 3.6125 11.2 2.83333C11.2 2.05062 11.83 1.41667 12.6 1.41667H40.6ZM40.6 15.5833C41.37 15.5833 42 16.2208 42 17C42 17.7792 41.37 18.4167 40.6 18.4167H12.6C11.83 18.4167 11.2 17.7792 11.2 17C11.2 16.2208 11.83 15.5833 12.6 15.5833H40.6ZM40.6 29.75C41.37 29.75 42 30.3875 42 31.1667C42 31.9458 41.37 32.5833 40.6 32.5833H12.6C11.83 32.5833 11.2 31.9458 11.2 31.1667C11.2 30.3875 11.83 29.75 12.6 29.75H40.6ZM2.8 19.8333C1.25387 19.8333 0 18.5672 0 17C0 15.4328 1.25387 14.1667 2.8 14.1667C4.34612 14.1667 5.6 15.4328 5.6 17C5.6 18.5672 4.34612 19.8333 2.8 19.8333ZM2.8 28.3333C4.34612 28.3333 5.6 29.5995 5.6 31.1667C5.6 32.7339 4.34612 34 2.8 34C1.25387 34 0 32.7339 0 31.1667C0 29.5995 1.25387 28.3333 2.8 28.3333Z" fill="#342354" />
         </svg>
      </>
   )
}
