import React, { useEffect, useState } from "react";
import { Box, Container, FormControl, Grid, Typography, TextField, Switch, TextareaAutosize } from "@mui/material";
import { Link } from 'react-router-dom'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import styles from "../pages/searchAdPreview/SearchAdPreview.module.scss";

export default function BusinessForm(props) {

   const [firstNameText2, setFirstName2] = useState('Business Competitor');
   const [firstEmailText2, setFirstEmail2] = useState('http://www.domain.com')
   const [firstheadlineText2, setFirstheadline2] = useState('website.co Project Management');
   const [secondheadlineText2, setSecondheadline2] = useState('Get a Free Estimate');
   const [description2, setDescription2] = useState('Crafting the Extraordinary: Our services are designed to inspire and achieve results');
   const [firstsitelink2, setFirstSitelink2] = useState(props.sitelinkonepass2);
   const [secondsitelink2, setSecondSitelink2] = useState(props.sitelinktwopass2);
   const [thirdsitelink2, setThirdSitelink2] = useState(props.sitelinkthreepass2 );
   const [fourthsitelink2, setfourthSitelink2] = useState(props.sitelinkfourpass2);
   const [errorMessageName2, setErrorMessageName2] = useState('');
   const [lengthName2, setlengthName2] = useState(0);
   const [errorMessageEmail2, setErrorMessageEmail2] = useState('');
   const [lengthEmail2, setlengthEmail2] = useState(0);
   const [errorMessageHeadlineone2, setErrorMessageHeadlineone2] = useState('');
   const [lengthHeadlineOne2, setlengthHeadlineOne2] = useState(0);
   const [errorMessageHeadlinetwo2, setErrorMessageHeadlinetwo2] = useState('');
   const [lengthHeadlineTwo2, setlengthHeadlineTwo2] = useState(0);
   const [errorMessageDescription2, setErrorMessageDescription2] = useState('');
   const [lengthDescription2, setlengthDescription2] = useState(0);
   const [errorMessageFirstSite2, setErrorMessageFirstSite2] = useState('');
   const [lengthFirstSite2, setlengthFirstSite2] = useState(0);
   const [errorMessageSecondSite2, setErrorMessageSecondSite2] = useState('');
   const [lengthSecondSite2, setlengthSecondSite2] = useState(0);
   const [errorMessageThirdSite2, setErrorMessageThirdSite2] = useState('');
   const [lengthThirdSite2, setlengthThirdSite2] = useState(0);
   const [errorMessageFourthSite2, setErrorMessageFourthSite2] = useState('');
   const [lengthFourthSite2, setlengthFourthSite2] = useState(0);
   
   useEffect(()=>{
      if(props.hidename == 'inputfieldtitle'){
         setFirstName2('')
      }
      else{
        setFirstName2(props.hidename)
      }
  },[props.hidename])

   useEffect(()=>{
      if(props.hideemail == 'inputfieldtitle'){
         setFirstEmail2('')
      }
      else{
        setFirstEmail2(props.hideemail)
      }
   },[props.hideemail])

   useEffect(()=>{
      if(props.hidefirsthead == 'inputfieldtitle'){
         setFirstheadline2('')
      }
      else{
         setFirstheadline2(props.hidefirsthead)
      }
   },[props.hidefirsthead])

   useEffect(()=>{
      if(props.hidesecondhead == 'inputfieldtitle'){
         setSecondheadline2('')
      }
      else{
         setSecondheadline2(props.hidesecondhead)
      }
   },[props.hidesecondhead])

   useEffect(()=>{
      if(props.hidedescription == 'inputfieldtitle'){
         setDescription2('')
      }
      else{
         setDescription2(props.hidedescription)
      }
   },[props.hidedescription])

   const handleFirstNameChange2 = (event) => {
      setFirstName2(event.target.value);
      props.onFirstNameChange2(event.target.value);
      var lengthName2 = event.target.value.length;
      setlengthName2(lengthName2);
      setFirstName2(event.target.value)

      if (lengthName2 >= 25) {
         setErrorMessageName2('The character limit exceeds 25.');
         return;
      } else {
         setErrorMessageName2('');
      }
   };
   const NamelengthColor2 = () => (
      lengthName2 >= 25 && '#990000' 
   )

   const handleFirstEmailChange2 = (event) => {
      setFirstEmail2(event.target.value);
      props.onFirstEmailChange2(event.target.value);
      var lengthEmail2 = event.target.value.length;
      setlengthEmail2(lengthEmail2);
      setFirstEmail2(event.target.value)

      if (lengthEmail2 >= 30) {
         setErrorMessageEmail2('The character limit exceeds 30.');
         return;
      } else {
         setErrorMessageEmail2('');
      }
   };
   const EmaillengthColor2 = () => (
      lengthEmail2 >= 30 && '#990000'
   )

   const handleFirstHeadlineChange2 = (event) => {
      setFirstheadline2(event.target.value);
      props.onFirstHeadlineChange2(event.target.value);
      var lengthHeadlineOne2 = event.target.value.length;
      setlengthHeadlineOne2(lengthHeadlineOne2);
      setFirstheadline2(event.target.value)

      if (lengthHeadlineOne2 >= 30) {
         setErrorMessageHeadlineone2('The character limit exceeds 30.');
         return;
      } else {
         setErrorMessageHeadlineone2('');
      }
   };
   const HeadlineOnelengthColor2 = () => (
      lengthHeadlineOne2 >= 30 && '#990000'
   )
 
   const handleSecondHeadlineChange2 = (event) => {
      setSecondheadline2(event.target.value);
      props.onSecondHeadlineChange2(event.target.value);
      var lengthHeadlineTwo2 = event.target.value.length;
      setlengthHeadlineTwo2(lengthHeadlineTwo2);
      setSecondheadline2(event.target.value)

      if (lengthHeadlineTwo2 >= 30) {
         setErrorMessageHeadlinetwo2('The character limit exceeds 30.');
         return;
      } else {
         setErrorMessageHeadlinetwo2('');
      }
   };
   const SecondHeadlinelengthColor2 = () => (
      lengthHeadlineTwo2 >= 30 && '#990000'
   )

   const handleDescriptionChange2 = (event) => {
      setDescription2(event.target.value);
      props.onDescriptionChange2(event.target.value);
      var lengthDescription2 = event.target.value.length;
      setlengthDescription2(lengthDescription2);
      setDescription2(event.target.value)

      if (lengthDescription2 >= 90) {
         setErrorMessageDescription2('The character limit exceeds 90.');
         return;
      } else {
         setErrorMessageDescription2('');
      }
   };
   const DescriptionlengthColor2 = () => (
      lengthDescription2 >= 90 && '#990000'
   )

   const handleFirstSitelinkChange2 = (event) => {
      setFirstSitelink2(event.target.value);
      props.onFirstSitelinkChange2(event.target.value);
      var lengthFirstSite2 = event.target.value.length;
      setlengthFirstSite2(lengthFirstSite2);
      setFirstSitelink2(event.target.value)

      if (lengthFirstSite2 >= 25) {
         setErrorMessageFirstSite2('The character limit exceeds 25.');
         return;
      } else {
         setErrorMessageFirstSite2('');
      }
   };
   const FirstSitelinklengthColor2 = () => (
      lengthFirstSite2 >= 25 && '#990000'
   )

   const handleSecondSitelinkChange2 = (event) => {
      setSecondSitelink2(event.target.value);
      props.onSecondSitelinkChange2(event.target.value);
      var lengthSecondSite2 = event.target.value.length;
      setlengthSecondSite2(lengthSecondSite2);
      setSecondSitelink2(event.target.value)

      if (lengthSecondSite2 >= 25) {
         setErrorMessageSecondSite2('The character limit exceeds 25.');
         return;
      } else {
         setErrorMessageSecondSite2('');
      }
   };
   const SecondSitelinklengthColor2 = () => (
      lengthSecondSite2 >= 25 && '#990000'
   )

   const handleThirdSitelinkChange2 = (event) => {
      setThirdSitelink2(event.target.value);
      props.onThirdSitelinkChange2(event.target.value);
      var lengthThirdSite2 = event.target.value.length;
      setlengthThirdSite2(lengthThirdSite2);
      setThirdSitelink2(event.target.value)

      if (lengthThirdSite2 >= 25) {
         setErrorMessageThirdSite2('The character limit exceeds 25.');
         return;
      } else {
         setErrorMessageThirdSite2('');
      }
   };
   const ThirdSitelinklengthColor2 = () => (
      lengthThirdSite2 >= 25 && '#990000'
   )

   const handlefourthSitelinkChange2 = (event) => {
      setfourthSitelink2(event.target.value);
      props.onfourthSitelinkChange2(event.target.value);
      var lengthFourthSite2 = event.target.value.length;
      setlengthFourthSite2(lengthFourthSite2);
      setfourthSitelink2(event.target.value)

      if (lengthFourthSite2 >= 25) {
         setErrorMessageFourthSite2('The character limit exceeds 25.');
         return;
      } else {
         setErrorMessageFourthSite2('');
      }
   };
   const fourthSitelinklengthColor2 = () => (
      lengthFourthSite2 >= 25 && '#990000'
   )

   const [minRows, setMinRows] = useState(window.innerWidth < 767 ? 3 : 2);
   useEffect(() => {
      const handleResize = () => {
         setMinRows(window.innerWidth < 1300 ? 3 : 2);
      };

      window.addEventListener('resize', handleResize);
      // Clean up the event listener on component unmount
      return () => {
         window.removeEventListener('resize', handleResize);
      };
   }, []);


   return (
      <FormControl className={`${styles.messageBoxes}`} fullWidth>
         <form>
            <Box className={`${styles.textSMS_wrapper}`}>
               <Typography variant="subtitle1" className={`${styles.textSMS}`} fontWeight={700} lineHeight={3} mb={1}>
                  Business Info
               </Typography>
            </Box>
            <Box className={styles.inputFields}>
                    <label htmlFor="text-area">Business Name</label>
                    <TextField 
                        id=""
                        value={firstNameText2}
                        onChange={handleFirstNameChange2}
                        label=""
                        variant="outlined"
                        InputLabelProps={{ shrink: true  }}
                        inputProps={{ placeholder: 'Business Name',
                        maxLength: 25, 
                        }}
                        
                    />
                    <span className={styles.numberFieldText} style={{ color: NamelengthColor2()}}>{lengthName2}/25</span>
                    {errorMessageName2 && <span className={styles.errorMessageName} style={{ color: NamelengthColor2() }}>{errorMessageName2}</span>}
            </Box>
            <Box className={styles.inputFields}>
               <label for="text-area">Business Website</label>
               <TextField 
                  id="" 
                  value={firstEmailText2} 
                  onChange={handleFirstEmailChange2} 
                  label="" variant="outlined" 
                  InputLabelProps={{
                  shrink: true
               }} inputProps={{
                  placeholder: 'www.website.com',
                  maxLength: 30,
               }} />
               <span className={styles.numberFieldText} style={{ color: EmaillengthColor2() }}>{lengthEmail2}/30</span>
               {errorMessageEmail2 && <span className={styles.errorMessageEmail} style={{ color: EmaillengthColor2() }}>{errorMessageEmail2}</span>}
            </Box>
            <Box className={`${styles.textSMS_wrapper}`}>
               <Typography variant="subtitle1" className={`${styles.textSMS}`} fontWeight={700} lineHeight={3} mb={1}>
                  Ad Headlines
               </Typography>
            </Box>

            <Box className={styles.inputFields}>
               <label for="text-area">Headline 1</label>
               <TextField 
                  id="subjectLine-text-area" 
                  label="" value={firstheadlineText2} 
                  onChange={handleFirstHeadlineChange2} 
                  variant="outlined" 
                  InputLabelProps={{
                  shrink: true
               }} inputProps={{
                  placeholder: '',
                  maxLength: 30,
               }} />
               <span className={styles.numberFieldText} style={{ color: HeadlineOnelengthColor2() }}>{lengthHeadlineOne2}/30</span>
               {errorMessageHeadlineone2 && <span className={styles.errorMessageHeadlineone} style={{ color: HeadlineOnelengthColor2() }}>{errorMessageHeadlineone2}</span>}
            </Box>
            <Box className={styles.inputFields}>
               <label for="text-area">Headline 2</label>
               <TextField 
                  id="preheader-text-area" 
                  label="" 
                  value={secondheadlineText2} 
                  onChange={handleSecondHeadlineChange2} 
                  variant="outlined" 
                  InputLabelProps={{
                  shrink: true
               }} inputProps={{
                  placeholder: '',
                  maxLength: 30,
               }} />
               <span className={styles.numberFieldText} style={{ color: SecondHeadlinelengthColor2() }}>{lengthHeadlineTwo2}/30</span>
               {errorMessageHeadlinetwo2 && <span className={styles.errorMessageHeadlinetwo} style={{ color: SecondHeadlinelengthColor2() }}>{errorMessageHeadlinetwo2}</span>}
            </Box>
            <Box className={`${styles.textSMS_wrapper}`}>
               <Typography variant="subtitle1" className={`${styles.textSMS}`} fontWeight={700} lineHeight={3} mb={1}>
                  Ad Description
               </Typography>
            </Box>

            <Box className={styles.inputFields}>
               <label for="text-area">Description 1</label>
               <TextareaAutosize
                  id="text-area-description-1"
                  value={description2} 
                  onChange={handleDescriptionChange2} 
                  name="text-area"
                  aria-label="empty textarea"
                  minRows={minRows}
                  placeholder="Add a description"
                  maxLength={90}
               />
               <span className={styles.numberFieldText} style={{ color: DescriptionlengthColor2() }}>{lengthDescription2}/90</span>
               {errorMessageDescription2 && <span className={styles.errorMessageDescription} style={{ color: DescriptionlengthColor2() }}>{errorMessageDescription2}</span>}
            </Box>
            <Box className={`${styles.textSMS_wrapper}`}>
               <Typography variant="subtitle1" className={`${styles.textSMS}`} fontWeight={700} lineHeight={3} mb={1} display="inline-flex">
                  Sitelinks
               </Typography>
            </Box>
            <Box className={`${styles.inputFieldsContainer}`}>
               <Box className={styles.inputFields}>
                  <label for="text-area">Sitelink 1</label>
                  <TextField 
                     id="preheader-text-area" 
                     label="" variant="outlined" 
                     value={firstsitelink2} 
                     onChange={handleFirstSitelinkChange2}
                     InputLabelProps={{
                     shrink: true
                  }} inputProps={{
                     placeholder: '',
                     maxLength: 25,
                  }} />
                  <span className={styles.numberFieldText} style={{ color: FirstSitelinklengthColor2() }}>{lengthFirstSite2}/25</span>
                  {errorMessageFirstSite2 && <span className={styles.errorMessageFirstSite} style={{ color: FirstSitelinklengthColor2() }}>{errorMessageFirstSite2}</span>}
               </Box>
               <Box className={styles.inputFields}>
                  <label for="text-area">Sitelink 2</label>
                  <TextField 
                     id="preheader-text-area" 
                     label="" 
                     value={secondsitelink2} 
                     onChange={handleSecondSitelinkChange2}
                     variant="outlined" 
                     InputLabelProps={{
                     shrink: true
                  }} inputProps={{
                     placeholder: '',
                     maxLength: 25,
                  }} />
                  <span className={styles.numberFieldText} style={{ color: SecondSitelinklengthColor2() }}>{lengthSecondSite2}/25</span>
                  {errorMessageSecondSite2 && <span className={styles.errorMessageSecondSite} style={{ color: SecondSitelinklengthColor2() }}>{errorMessageSecondSite2}</span>}
               </Box>
               <Box className={styles.inputFields}>
                  <label for="text-area">Sitelink 3</label>
                  <TextField 
                     id="preheader-text-area" 
                     label="" 
                     value={thirdsitelink2} 
                     onChange={handleThirdSitelinkChange2}
                     variant="outlined" 
                     InputLabelProps={{
                     shrink: true
                  }} inputProps={{
                     placeholder: '',
                     maxLength: 25,
                  }} />
                  <span className={styles.numberFieldText} style={{ color: ThirdSitelinklengthColor2() }}>{lengthThirdSite2}/25</span>
                  {errorMessageThirdSite2 && <span className={styles.errorMessageThirdSite} style={{ color: ThirdSitelinklengthColor2() }}>{errorMessageThirdSite2}</span>}
               </Box>
               <Box className={styles.inputFields}>
                  <label for="text-area">Sitelink 4</label>
                  <TextField 
                     id="preheader-text-area" 
                     label=""
                     value={fourthsitelink2} 
                     onChange={handlefourthSitelinkChange2} 
                     variant="outlined" 
                     InputLabelProps={{
                     shrink: true
                  }} inputProps={{
                     placeholder: '',
                     maxLength: 25,
                  }} />
                  <span className={styles.numberFieldText} style={{ color: fourthSitelinklengthColor2() }}>{lengthFourthSite2}/25</span>
                  {errorMessageFourthSite2 && <span className={styles.errorMessageFourthSite} style={{ color: fourthSitelinklengthColor2() }}>{errorMessageFourthSite2}</span>}
               </Box>
            </Box>
         </form>
      </FormControl>
   )
}



